/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState, useEffect } from "react";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce, isNil } from "lodash";
import { TranscriptContext } from "../../../services/TranscriptService";

const useStyles = makeStyles((theme) => ({
  search: {
    height: "38px",
    // width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchLabel: {
    color: "#ffffff",
  },
  searchResults: {
    width: "100%",
    maxWidth: 400,
    backgroundColor: "#ffffff",
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
    borderWidth: "1px",
    borderColor: "#999999",
  },
}));

const TranscriptSearch = ({ onScrollToView, width }) => {
  const classes = useStyles();
  const { data, setData } = React.useContext(TranscriptContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);

  function handleSearch(e) {
    setSearchQuery(e.target.value);
    if (e.target.value !== "") {
      setPopoverOpen(true);
      setAnchorEl(e.currentTarget);
    } else {
      setPopoverOpen(false);
      setAnchorEl(null);
    }
    debounceSearch(e);
  }

  const debounceSearch = React.useCallback(
    debounce((e) => {
      if (searchQuery.length < 1) {
        setData({ searchResults: [] });
        setSearchResults([]);
        return;
      }

      const results =
        e.target.value.length > 0
          ? data?.transcript?.filter((tr) => {
              return (
                tr.text.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                tr.text.indexOf(e.target.value) !== -1
              );
            })
          : [];
      if (isNil(results)) return;

      setData({ clear: "searchResults" });
      setData({ searchResults: results.map((r) => r.id) });
      setSearchResults(results);
    }, 300),
    [searchQuery]
  );

  const handleClear = (e) => {
    setSearchQuery("");
    setData({ clear: "searchResults" });
    setPopoverOpen(false);
  };

  const handleSearchSelected = (tr) => {
    // console.log("handleSearchSelected", tr);
    onScrollToView(tr.id, tr);
    setPopoverOpen(false);
    setSearchQuery("");
  };

  const convertToHtmlText = (text) => {
    // console.log("XXXX convertToHtmlText", text);
    let htmlText = text.split(" ").join("&nbsp;");
    htmlText = htmlText.replaceAll("\n", "<br/>");
    // htmlText = htmlText.trim();
    return htmlText;
  };

  return (
    <Box display="flex" flexDirection="row" className={classes.search}>
      <TextField
        placeholder="Search…"
        size="small"
        style={{ width: width }}
        value={searchQuery}
        onChange={handleSearch}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
      <Popper
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setPopoverOpen(false)}
        placement="bottom-end"
        style={{ zIndex: 1500 }}
      >
        <Box
          style={{
            height: "500px",
            width: "400px",
            backgroundColor: "white",
            border: "solid",
            borderWidth: "1px",
            borderColor: "#999999",
          }}
        >
          <Box
            display="flex"
            alignContent="center"
            justifyContent="space-between"
            p={1}
            style={{ backgroundColor: "#e4e4e4" }}
          >
            <Box display="flex" alignContent="center">
              <Typography>{searchResults.length} Matches</Typography>
            </Box>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Clear
            </Button>
          </Box>
          <List className={classes.searchResults}>
            {searchResults.map((tr) => (
              <ListItem key={tr.id} button value={tr.id} onClick={() => handleSearchSelected(tr)}>
                <ListItemText
                  primary={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertToHtmlText(tr.text),
                      }}
                    />
                  }
                  secondary={`Page: ${tr.pageNumber}, Line: ${tr.lineNumber}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popper>
    </Box>
    // </Box>
  );
};

export default TranscriptSearch;
