/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, withStyles } from "@mui/styles";

import { Box, CircularProgress, Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { Column, Table } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import moment from "moment";
import { get, isEmpty, isNil } from "lodash";
import { useMediaQuery } from "react-responsive";
import { useHotkeys } from "react-hotkeys-hook";
import { VOICETOTEXT_TRANSCRIPTS_ENABLED } from "../../model/Model";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { TranscriptContext } from "../../services/TranscriptService";
import TranscriptsViewerMenuBar from "./menuBar/TranscriptsViewerMenuBar";
import TranscriptActionButton from "./TranscriptActionButton";
import { toHex } from "./transcriptUtilities";
import { encodeBookmarks, COLOR_WHITE_DEC } from "./transcriptUtilities";

import "../../styles.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
  },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10px",
  },
  transcriptDatagrid: {
    backgroundColor: ({ backgroundColor }) => `${backgroundColor}`,
    color: ({ fontColor }) => `${fontColor}`,
    fontSize: ({ fontSize }) => `${fontSize}`,
  },
  container: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  tableContainer: {
    overflowY: "scroll",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    backgroundColor: "#343A40",
    color: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    color: "#71ee33",
  },
}));

const SCROLL_BUFFER = 10;

const TranscriptsViewer = ({ caseName, eventId, transcriptId, transcriptBookmarkId }) => {
  const tableRef = useRef(null);
  const classes = useStyles({ backgroundColor, fontColor, fontSize });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { auth, authenticate } = React.useContext(AuthContext);
  const {
    data: transcriptData,
    setData: setTranscriptData,
    saveTranscriptBookmarks,
    getTranscriptBookmarks,
    getArchivedTranscript,
  } = React.useContext(TranscriptContext);
  const [loading, setLoading] = useState(true);
  const [transcripts, setTranscripts] = useState([]);
  const [visibleRows, setVisibleRows] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [colors, setColors] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("Transcript View");
  const [activeRow, setActiveRow] = useState(null);
  // const [transcriptColumns, setTranscriptColumns] = React.useState([]);
  const [enableAutoscroll, setEnableAutoscroll] = React.useState(true);
  const [enableAutoscrollIndicator, setEnableAutoscrollIndicator] = React.useState(true);
  const [showPagesAndLines, setShowPagesAndLines] = React.useState(false);
  const [showTime, setShowTime] = React.useState(true);
  const [showSpeakers, setShowSpeakers] = React.useState(false);
  const [saveBookmarksConfirmationOpen, setSaveBookmarksConfirmationOpen] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [fontColor, setFontColor] = React.useState("black");
  const [fontSize, setFontSize] = React.useState("12pt");
  const [refs, setRefs] = useState([]);
  const [isFontSizeChanged, setIsFontSizeChanged] = React.useState(false);
  const [transcriptMap, setTranscriptMap] = React.useState([]);
  const [currentWidth, setCurrentWidth] = React.useState(1000);
  const [currentHeight, setCurrentHeight] = React.useState(600);
  const [textColumnWidth, setTextColumnWidth] = React.useState(650);
  const [lastScrollTop, setLastScrollTop] = React.useState(0);

  useHotkeys(
    "space",
    (e) => {
      if (activeRow !== null) {
        handleAddBookmark(e);
      }
    },
    [activeRow]
  );

  React.useEffect(() => {
    const resultIds = transcriptData.searchResults ? transcriptData.searchResults : [];
    // After a search we add {inSearchResult: true} to highlight the results
    const resultLines = [...transcriptData.transcript].map((line) => {
      if (resultIds.includes(line.id)) return { ...line, inSearchResult: true };
      return { ...line, inSearchResult: false };
    });
    // setTranscripts([...resultLines]);
  }, [transcriptData.searchResults?.length]);

  /**
   * Hook for initializing the TranscriptViewer when it is launched.
   */
  useEffect(() => {
    const startDate = moment().format("MM/DD/YYYY");
    const title = `Transcript View: ${caseName} - ${startDate}`;
    setDialogTitle(title);
    const fetchData = async () => {
      try {
        setLoading(true);
        await authenticate();
        await getTranscriptBookmarks(transcriptId);
        await getArchivedTranscript(transcriptId);

        // Show the speaker columns by default if this is voice to text
        if (transcriptData?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED) {
          setShowSpeakers(true);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    // Add resize listener for Table
    window.addEventListener("resize", handleOnResize);
    handleOnResize();
  }, [open, eventId]);

  /**
   * Event handler for handling when the font size is changed.
   */
  useEffect(() => {
    if (isFontSizeChanged) {
      setTimeout(() => {
        setIsFontSizeChanged(false);
      }, 1000);
    }
  }, [isFontSizeChanged]);

  /**
   * Update the transcripts displayed.
   */
  React.useEffect(() => {
    if (isNil(transcriptData.transcript)) return;

    // Look up transcript bookmark and update.
    transcriptData.transcript.forEach((tr) => {
      const bookmark = transcriptData.transcriptBookmarks[tr.id];
      if (!isNil(bookmark)) {
        tr.bookmark = bookmark;
      }
    });

    // Set the visible rows
    setVisibleRows(transcriptData.transcript);

    // If autoscroll is enabled, then scroll to last item.
    if (enableAutoscroll) {
      tableRef?.current?.scrollToRow(visibleRows.length - 1);
    }
  }, [transcriptData?.transcript?.length]);

  /**
   * Handles bookmark data loading from HusebyConnect.
   *
   * @param {*} transcript
   */
  React.useEffect(() => {
    if (!isNil(transcriptData.bookmarks)) setBookmarks(transcriptData.bookmarks);
    else setBookmarks([]);
  }, [transcriptData.bookmarks]);

  /**
   * QW-TODO: We may no longer need this.
   *
   * @param {*} row
   */
  const handleOnClick = (row) => {
    setActiveRow(row);
  };

  /**
   *
   */
  const handleOnResize = async () => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    // console.log("XXX handleOnResize", height, width);
    setCurrentWidth(width);
    setTextColumnWidth(width - 350);

    const headerHeight = isMobile ? 150 : 80;

    setCurrentHeight(height - headerHeight);
    // const _vis = transcriptData.transcript;
    // console.log("XXX _vis", transcriptData.transcript);
    // setVisibleRows([]);
    // setVisibleRows(_vis);
    await getArchivedTranscript(transcriptId);
  };

  /**
   * Handler for saving bookmarks.
   *
   * @param {*} transcript
   */
  const handleSaveBookmark = async (transcript) => {
    const { id: bookmarkId } = transcript.bookmark;

    const updatedBookmarks = [...bookmarks].filter((b) => b.id !== bookmarkId);
    updatedBookmarks.push({ ...transcript.bookmark });

    setBookmarks(updatedBookmarks);

    // Map bookmarks
    let bookmarkMap = [];
    setTranscriptData({ bookmarks: updatedBookmarks });
    for (let i = 0; i < updatedBookmarks.length; i++) {
      const index = updatedBookmarks[i].id;
      updatedBookmarks[i].id = index;
      bookmarkMap[index] = updatedBookmarks[i];
    }

    setTranscriptData({ transcriptBookmarks: bookmarkMap });

    // Automatically save the bookmarks.
    handleSaveAllBookmarksAndColors(updatedBookmarks);
  };

  /**
   * Handler for adding bookmarks.
   *
   */
  const handleAddBookmark = () => {
    const updatedBookmarks = [...bookmarks];
    const newBookmark = {
      color: COLOR_WHITE_DEC,
      id: activeRow.id,
      label: "",
      lineVO: {
        isAlternating: activeRow.isAlternating,
        lineNumber: activeRow.lineNumber,
        lineType: activeRow.lineType,
        pageNumber: activeRow.pageNumber,
        selectionEnd: activeRow.selectionEnd,
        selectionOn: activeRow.selectionOn,
        selectionStart: activeRow.selectionStart,
        text: activeRow.text,
        timeStamp: activeRow.timeStamp,
      },
      position: 0,
    };
    activeRow.bookmark = { ...newBookmark };
    setActiveRow(null);
    setActiveRow(activeRow);
    updatedBookmarks.push({ ...newBookmark });
    setBookmarks(updatedBookmarks);
  };

  /**
   * Handler for deleting bookmarks.
   *
   * @param {*} transcriptRow
   */
  const handleDeleteBookmark = async (transcriptRow) => {
    const transcriptBookmarks = { ...transcriptData.transcriptBookmarks };
    delete transcriptBookmarks[transcriptRow.id];

    const updatedBookmarks = [...bookmarks].filter((b) => b.id !== transcriptRow.id);
    setTranscriptData({ clear: "transcriptBookmarks" });
    setTranscriptData({
      ...transcriptData,
      bookmarks: updatedBookmarks,
      transcriptBookmarks,
    });
    setBookmarks(updatedBookmarks);

    // Automatically save the bookmarks.
    handleSaveAllBookmarksAndColors(updatedBookmarks);
  };

  /**
   * Handler for saving all bookmarks and colors.
   *
   * @param {*} bookmarks
   */
  const handleSaveAllBookmarksAndColors = async (bookmarks) => {
    const contactId = get(auth, ["identity", "contactId"]);
    const encodedBookmarks = encodeBookmarks(transcripts, bookmarks, colors, contactId);
    try {
      await saveTranscriptBookmarks(transcriptId, encodedBookmarks);
      setSaveBookmarksConfirmationOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Scroll to page and line number.
   *
   * @param {*} pageNumber
   * @param {*} lineNumber
   */
  const scrollToPageAndLine = (pageNumber, lineNumber) => {
    const index = transcriptData.transcript.findIndex(
      (row) => row.pageNumber === parseInt(pageNumber) && row.lineNumber === parseInt(lineNumber) // Scroll to the first line for this page.
    );
    if (index !== -1) tableRef.current.scrollToRow(index);
  };

  /**
   * Handler for scrolling to a page.
   *
   * @param {*} pageNumber
   * @param {*} lineNumber
   * @returns
   */
  const handleScrollToPage = (pageNumber, lineNumber) => {
    // setActiveRow(transcripts[ids[0].id]);
    scrollToPageAndLine(pageNumber, lineNumber);
  };

  /**
   * Handler for scrolling to line.
   *
   * @param {*} lineNumber
   * @param {*} pageNumber
   * @returns
   */
  const handleScrollToLine = (lineNumber, pageNumber) => {
    // setActiveRow(ids[0]);
    scrollToPageAndLine(pageNumber, lineNumber);
  };

  /**
   * Handler for scrolling to view.
   *
   * @param {*} id
   * @param {*} row
   * @returns
   */
  const handleScrollToView = (id, row) => {
    setActiveRow(row);

    scrollToPageAndLine(row.pageNumber, row.lineNumber);
  };

  /**
   * Handler for the onScroll event.  If the user scrolls upwards, then
   * disable autoscrolling.   This is done by tracking the lastScrollTop and
   * comparing against the current scrollTop.
   *
   * @param {*} e
   */
  const handleOnScroll = (e) => {
    if (enableAutoscroll) {
      if (e.scrollTop < lastScrollTop - SCROLL_BUFFER) {
        setEnableAutoscroll(false);
        setEnableAutoscrollIndicator(false);
      } else {
        setLastScrollTop(e.scrollTop);
      }
    }
  };

  /**
   * Convert text to HTML formatted text.
   *
   * @param {*} text
   * @returns
   */
  const convertToHtmlText = (text) => {
    let htmlText = text.split(" ").join("&nbsp;");
    htmlText = htmlText.replaceAll("\n", "<br/>");
    // htmlText = htmlText.trim();
    return htmlText;
  };

  /**
   * Get the row style based on the bookmark's settings.
   *
   * @param {*} index
   * @returns
   */
  const getRowStyle = (index) => {
    const transcript = visibleRows.at(index);
    if (!isNil(transcript?.bookmark)) {
      // console.log("XXXXX bookmark", transcript.bookmark);
      return { backgroundColor: toHex(transcript.bookmark.color) };
    } else {
      return { backgroundColor: "#ffffff" };
    }
  };

  /**
   * Header row renderer.  This generates a sticky table header.
   *
   * @param {*} param0
   * @returns
   */
  const headerRowRenderer = ({ className, columns, style }) => (
    <div
      className={className}
      role="row"
      style={{
        position: "sticky",
        height: isMobile ? "155px" : "100px",
        top: "0",
        width: "100%",
        backgroundColor: "#ffffff",
      }}
    >
      <Box display="flex" flexDirection="column" width={1}>
        <TranscriptsViewerMenuBar
          eventId={eventId}
          transcripts={visibleRows}
          enableAutoscrollIndicator={enableAutoscrollIndicator}
          setEnableAutoscrollIndicator={setEnableAutoscrollIndicator}
          onBackgroundColorChange={(bgColor) => {
            setBackgroundColor(bgColor);
          }}
          onFontColorChange={(fontColor) => setFontColor(fontColor)}
          onFontSizeChange={(fontSize) => {
            setFontSize((prevFontSize) => {
              if (prevFontSize !== fontSize) {
                setIsFontSizeChanged(true);
              }
              return fontSize;
            });
          }}
          onEnableAutoscrollChange={(enableAutoscroll) => setEnableAutoscroll(enableAutoscroll)}
          onShowPagesAndLinesChange={(showPagesAndLines) => setShowPagesAndLines(showPagesAndLines)}
          onShowSpeakersChange={(showSpeakers) => setShowSpeakers(showSpeakers)}
          onShowTimeChange={(showTime) => setShowTime(showTime)}
          onScrollToPageChange={handleScrollToPage}
          onScrollToLineChange={handleScrollToLine}
          onScrollToView={handleScrollToView}
        />
        <Box display="flex" flexDirection="row" width={1}>
          {columns}
        </Box>
      </Box>
    </div>
  );

  /**
   * No transcript row renderer.
   *
   * @returns
   */
  const noRowsRenderer = () => (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        width: "100vw",
      }}
    >
      There are no transcripts available.
    </Box>
  );

  /**
   * Get font color for the transcripts based on speaker mapping.
   *
   * @param {*} row
   * @returns
   */
  const getSpeakerFontColor = (row) => {
    const fontColor = transcriptData.transcriptSpeakerMapping.find(
      (m) => m.speakerIndex == +row.speaker
    )?.color;
    return fontColor;
  };

  /**
   * Get speaker name for a row.  If no mapping exists,
   * then just return a placeholder speaker name.
   *
   * @param {*} speakerIndex
   * @returns
   */
  const getSpeakerName = (props) => {
    const row = props.rowData;
    // If the speakerName has already been assigned, then just return it.
    if (row?.speakerName) return row.speakerName;
    // Otherwise, we do not have the speakerName populated, then check the mapping.
    const speakerIndex = row.speaker;

    // If speakerIndex is undefined, then return an empty string.  This may be
    // because we are using StreamText.
    if (isNil(row.speakerIndex)) {
      return "No Speaker ID";
    }

    let speakerName = data.transcriptSpeakerMapping.find(
      (m) => m.speakerIndex == speakerIndex
    )?.speakerName;

    if (speakerName === undefined || speakerName.length === 0)
      speakerName = `Speaker ${speakerIndex}`;
    return speakerName;
  };

  return (
    <>
      {!loading && (
        <>
          {/* TranscriptViewer Listing */}
          <Box style={{ backgroundColor: "#ffffff", width: currentWidth - 30, height: "100vh" }}>
            <Box
              style={{
                top: "0",
                width: "100%",
                height: isMobile ? "130px" : "65px",
                position: "sticky",
              }}
            >
              <div
                // className={className}
                role="row"
                style={{
                  position: "sticky",
                  height: isMobile ? "130px" : "100px",
                  top: "0",
                  width: "100%",
                  backgroundColor: "#ffffff",
                }}
              >
                <Box display="flex" flexDirection="column" width={1}>
                  <TranscriptsViewerMenuBar
                    eventId={eventId}
                    transcripts={visibleRows}
                    enableAutoscrollIndicator={enableAutoscrollIndicator}
                    setEnableAutoscrollIndicator={setEnableAutoscrollIndicator}
                    onBackgroundColorChange={(bgColor) => {
                      setBackgroundColor(bgColor);
                    }}
                    onFontColorChange={(fontColor) => setFontColor(fontColor)}
                    onFontSizeChange={(fontSize) => {
                      setFontSize((prevFontSize) => {
                        if (prevFontSize !== fontSize) {
                          setIsFontSizeChanged(true);
                        }
                        return fontSize;
                      });
                    }}
                    onEnableAutoscrollChange={(enableAutoscroll) =>
                      setEnableAutoscroll(enableAutoscroll)
                    }
                    onShowPagesAndLinesChange={(showPagesAndLines) =>
                      setShowPagesAndLines(showPagesAndLines)
                    }
                    onShowSpeakersChange={(showSpeakers) => setShowSpeakers(showSpeakers)}
                    onShowTimeChange={(showTime) => setShowTime(showTime)}
                    onScrollToPageChange={handleScrollToPage}
                    onScrollToLineChange={handleScrollToLine}
                    onScrollToView={handleScrollToView}
                  />
                  <Box display="flex" flexDirection="row" width={1}>
                    {[]}
                  </Box>
                </Box>
              </div>
            </Box>
            <Box
              style={{
                width: currentWidth,
                height: currentHeight,
                overflowX: "auto",
                overflowY: "scroll",
              }}
            >
              <Box style={{ width: currentWidth }}>
                <Table
                  ref={tableRef}
                  width={currentWidth}
                  height={currentHeight}
                  headerHeight={35}
                  rowHeight={35}
                  rowCount={visibleRows?.length}
                  rowGetter={({ index }) => visibleRows[index]}
                  rowStyle={({ index }) => {
                    return getRowStyle(index);
                  }}
                  scrollToRow={visibleRows?.length - 1}
                  scrollToAlignment="center"
                  // onRowClick={() => setEnableAutoscroll(false)}
                  onScroll={handleOnScroll}
                  // headerRowRenderer={headerRowRenderer}
                  noRowsRenderer={noRowsRenderer}
                >
                  <Column
                    label=""
                    dataKey="id"
                    width={25}
                    cellRenderer={(props) => (
                      <TranscriptActionButton
                        row={props.rowData}
                        onSaveBookmark={handleSaveBookmark}
                        onDeleteBookmark={handleDeleteBookmark}
                        defaultBackgroundColor={backgroundColor}
                      />
                    )}
                    // cellRenderer={() => <div>Test</div>}
                    style={{ fontSize: fontSize, paddingTop: 6 }}
                  />

                  {showPagesAndLines && (
                    <Column
                      label="Page"
                      dataKey="pageNumber"
                      width={50}
                      style={{ fontSize: fontSize }}
                      cellRenderer={(props) => {
                        return (
                          <span style={{ color: getSpeakerFontColor(props.rowData) }}>
                            {props.cellData}
                          </span>
                        );
                      }}
                    />
                  )}
                  {showPagesAndLines && (
                    <Column
                      label="Line"
                      dataKey="lineNumber"
                      width={50}
                      style={{ fontSize: fontSize }}
                      cellRenderer={(props) => {
                        return (
                          <span style={{ color: getSpeakerFontColor(props.rowData) }}>
                            {props.cellData}
                          </span>
                        );
                      }}
                    />
                  )}
                  {showSpeakers &&
                    transcriptData?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED && (
                      <Column
                        label="Speaker"
                        dataKey="speaker"
                        width={200}
                        style={{ fontSize: fontSize }}
                        cellRenderer={(props) => {
                          return (
                            <span style={{ color: getSpeakerFontColor(props.rowData) }}>
                              {getSpeakerName(props)}
                            </span>
                          );
                        }}
                      />
                    )}

                  <Column
                    label="Text"
                    dataKey="text"
                    width={500}
                    style={{
                      textAlign: "left",
                      fontSize: fontSize,
                      padding: 8,
                    }}
                    cellRenderer={(props) => {
                      return (
                        <div
                          style={{ color: getSpeakerFontColor(props.rowData) }}
                          dangerouslySetInnerHTML={{
                            __html: convertToHtmlText(props.cellData),
                          }}
                        />
                      );
                    }}
                  />
                  {showTime &&
                    transcriptData?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED && (
                      <Column
                        label="Time"
                        dataKey="timeStamp"
                        width={150}
                        style={{ fontSize: fontSize }}
                        cellRenderer={(props) => {
                          return (
                            <span style={{ color: getSpeakerFontColor(props.rowData) }}>
                              {moment(props.cellData).format("HH:mm:ss")}
                            </span>
                          );
                        }}
                      />
                    )}
                </Table>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {loading && (
        <Box className={classes.loading}>
          <CircularProgress size={50} className={classes.progress} />
          <br />
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={saveBookmarksConfirmationOpen}
        onClose={() => setSaveBookmarksConfirmationOpen(false)}
        autoHideDuration={4000}
        message="Bookmarks have been successfully saved."
      >
        <Alert onClose={() => setSaveBookmarksConfirmationOpen(false)} severity="success">
          Bookmarks have been successfully saved.
        </Alert>
      </Snackbar>
    </>
  );
};

const NoTranscriptsOverlay = () => {
  return (
    <div
      style={{
        textAlign: "center",
        height: "calc(100vh - 150px)",
        width: "100vw",
      }}
      colSpan="100%"
    >
      There are no transcripts available.
    </div>
  );
};

export default TranscriptsViewer;
