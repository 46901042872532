/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { forEach, merge } from "lodash";
import { instance as http } from "@cirrux888/huseby-client-auth";

export const ROLE_HOST = "host";
export const ROLE_COHOST = "co-host";
export const ROLE_ANNOTATOR = "annotator";
export const ROLE_PARTICIPANT = "participant";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  eventId: null,
  exhibit: null, // exhibit that is being edited.
  snackbar: {
    open: false,
    severity: "success", // error | warning | info | success
    message: ""
  },
  role: ROLE_PARTICIPANT // host | co-host | annotator | participant
};
const DataContext = React.createContext();

const DataProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);

  const setRole = (role) => {
    setData({ clear: "role" });
    setData({ role });
  };

  /**
   * List event participants.
   */
  const listEventParticipants = async (eventId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants`
    };

    try {
      setData({ loading: true });
      const { data: eventParticipants } = await http(config);
      setData({ eventParticipants });
      return eventParticipants;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Get an event participant.
   */
  const getEventParticipant = async (eventId, contactId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants/${contactId}`
    };

    try {
      setData({ loading: true });
      const { data: eventParticipant } = await http(config);
      return eventParticipant;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Get a file.
   */
  const getFile = async (fileId) => {
    const config = {
      method: "get",
      url: `/sf/files/${fileId}`
    };

    try {
      setData({ loading: true });
      const { data: file } = await http(config);
      return file;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * View a file.
   */
  const viewFile = async (fileId) => {
    const config = {
      method: "get",
      url: `/sf/files/${fileId}/view`,
      responseType: "blob"
    };

    try {
      setData({ loading: true });
      const response = await http(config);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Upload a file.
   */
  const uploadFile = async (fileId, onUploadProgress) => {
    console.log("uploadFile", fileId, fileToUpload);
    const formData = new FormData();
    formData.append("file", fileToUpload);

    const config = {
      method: "put",
      url: `/sf/files/${fileId}/upload`,
      headers: {
        "content-type": "multipart/form-data"
      },
      data: formData,
      onUploadProgress
    };
    try {
      setData({ loading: true });
      const results = await http(config);
      console.log("Exhibit uploaded successfully!", results);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Download a single file.
   *
   */
  const downloadFile = async (file, onDownloadProgress) => {
    const config = {
      method: "get",
      url: `/sf/files/${file.fileId}/download`,
      responseType: "blob",
      onDownloadProgress
    };

    try {
      // setData({ loading: true });
      const response = await http(config);
      console.log("response", response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * Share files.
   *
   */
  const shareFiles = async (fileIds = [], recipients, subject, message) => {
    console.log("shareFiles", fileIds, recipients, subject, message);
    let fileIdParam = "";
    forEach(fileIds, (fileId) => {
      fileIdParam += `fileId=${fileId}&`;
    });

    const shareEmailData = {
      recipients: recipients,
      subject: subject,
      body: message
    };

    const config = {
      method: "post",
      url: `/sf/files/shareemail?${fileIdParam}`,
      data: shareEmailData
    };

    try {
      setData({ loading: true });
      const response = await http(config);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const checkExhibitNumber = async (fileId) => {
    console.log("checkExhibitNumber", fileId);
    const config = {
      method: "get",
      url: `/sf/files/${fileId}/check-exhibit-number`
    };
    try {
      setData({ loading: true });
      const { data: exhibit } = await http(config);
      setData({ exhibit });
      return exhibit;
    } catch (error) {
      console.error(error);
      // throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const listBookmarks = async (eventId) => {
    const config = {
      method: "get",
      url: `sf/bookmark?hcResourceId=${eventId}`
    };
    try {
      setData({ loading: true });
      const { data: bookmarks } = await http(config);
      setData({ bookmarks });
      return bookmarks.content;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const publishFile = async (pdfId) => {
    console.log("Publishing file...", pdfId);

    try {
      // Upload/import the PDF to PDFService
      const config = {
        method: "post",
        url: `/pdf/${pdfId}/publish`
      };

      setData({ loading: true });
      const { data: pdf } = await http(config);
      console.log("File published successfully!", pdf);

      return pdf;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Returns the authenticated user's file permissions for the specified
   * fileId.   In HusebyConnect, we cannot set permissions on individual exhibits.  Rather,
   * the permissions is set at a user's private folder and the permissions rolls down to any
   * child folders and exhibits.   So, this will essentially return the parent folder's
   * permissions for the exhibit.  We can use this to enable/disable features such as
   * Download File(s), Upload File(s), Share Exhibit(s), Delete Exhibit(s).
   *
   * @param authHeader
   * @param fileId
   */
  const getMyFilePermissions = async (fileId) => {
    console.log("Get root permissions...", fileId);

    try {
      const config = {
        method: "get",
        url: `/sf/permissions/${fileId}/root`
      };

      const { data } = await http(config);

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        setRole,
        listEventParticipants,
        getEventParticipant,
        getFile,
        viewFile,
        uploadFile,
        downloadFile,
        shareFiles,
        uploadFile,
        checkExhibitNumber,
        listBookmarks,
        publishFile,
        getMyFilePermissions
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

const useDataService = () => React.useContext(DataContext);

export { DataContext, DataProvider, useDataService };
