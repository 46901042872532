/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { TranscriptContext } from "../../services/TranscriptService";
import { startTranscriptService } from "../../services/SocketIOService";
import { useStreamTextContext } from "../../services/StreamTextService";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "300px",
  },
  progress: {
    color: "#71ee33",
  },
}));

const StartStreamTextTranscript = ({ open, onClose }) => {
  const classes = useStyles();
  const {
    data: model,
    setData,
    getStreamedTranscript,
    startTranscriptStream,
  } = React.useContext(TranscriptContext);
  const { loadStreamTextTestData, startStreamTextTranscript } = useStreamTextContext();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(null);
  const [streamTextOption, setStreamTextOption] = useState("scheduled");

  React.useEffect(() => {
    loadStreamTextTestData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const startStreaming = async () => {
      console.log("Start streaming Real-Time Transcript...");
      const streamTextOption = e.target.streamTextOption.value;
      let useTestEvent = true;
      if (streamTextOption === "scheduled") {
        console.log("Using scheduled event: {}", model.event.streamTextLegalId);
        useTestEvent = false;
      } else if (streamTextOption === "test") {
        console.log("Using test event...");
        useTestEvent = true;
      }

      // Close the modal
      onClose();

      // Start transcript service so that it streams to other listening clients
      await startTranscriptService(model.eventId, "realTime");

      // Call REST API to start transcript stream from StreamText
      await startTranscriptStream(model.eventId, useTestEvent); // QW-TODO: Rename to startRealtimeTranscriptEvent.
    };

    startStreaming();
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
        disableEnforceFocus
      >
        <CRXDialogTitle onClick={onClose} title="Stream Transcripts" />
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <RadioGroup
              aria-label="streamtext-event"
              name="streamTextOption"
              color="primary"
              defaultValue={streamTextOption}
              onChange={(e) => setStreamTextOption(e.target.value)}
            >
              {/* <Box style={{ height: "40px" }}>
                <FormControlLabel
                  value={"create"}
                  control={<Radio color="primary" />}
                  label="Create and Start StreamText Event"
                />
              </Box> */}
              <Box display="flex" flexDirection="row" style={{ height: "40px" }}>
                <FormControlLabel
                  value={"scheduled"}
                  control={<Radio color="primary" />}
                  label="Use Scheduled StreamText Event"
                />
              </Box>
              {streamTextOption === "scheduled" && (
                <Box display="flex" flexDirection="column" style={{ paddingLeft: "30px" }}>
                  <span>
                    <b>Event Name:</b> {model.event.eventName}
                  </span>
                  <span>
                    <b>Event Id:</b> {model.event.eventId}
                  </span>
                  <span>
                    <b>StreamText Legal Id: </b> {model.event.streamTextLegalId}
                  </span>
                </Box>
              )}
              <Box style={{ height: "40px" }}>
                <FormControlLabel
                  value={"test"}
                  control={<Radio color="primary" />}
                  label="Use Test Event"
                />
              </Box>
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="flex-end" width={1}>
              {loading && <CircularProgress size={30} className={classes.progress} />}
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
              // disabled={!isEmpty(errors)}
            >
              Start StreamText
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default StartStreamTextTranscript;
