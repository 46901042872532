import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Radio
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { isNil } from "lodash";
import { CRXDialogTitle } from "@cirrux888/huseby-client-auth";
import { useSocketIOService } from "../../services/SocketIOService";
import { useDataService } from "../../services/DataService";
import { getRoom } from "../../services/RoomService";
import {
  grantAnnotationRights,
  revokeAnnotationRights
} from "../../services/RoomService";
import {
  updateAnnotator,
  updateEditorHeaderAndToolbars
} from "../useWebViewer";
import { emitAnnotatorChanged } from "../useSocket";

/**
 * "Grant Annotation Rights" Dialog.
 *
 * This dialog displays a list of attendees and allows the Presenter to select which
 * attendee that they want to pass temporary Annotator rights to.
 *
 * @param {*} param0
 * @returns
 */
const GrantAnnotationRightsDialog = ({
  annotator,
  presenter,
  open,
  onClose
}) => {
  const { data: apiData } = useDataService();
  const { data, setData: setSocketData } = useSocketIOService();
  const [loading, setLoading] = useState(false);
  const [attendeeList, setAttendeeList] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState();

  React.useEffect(() => {
    const init = async () => {
      console.log("Fetching latest attendees list.", apiData.eventId);
      const roomInfo = await getRoom(apiData.eventId);

      let userList = [];
      const entries = Object.entries(roomInfo.users);
      const map = new Map(entries);
      map.forEach((v, k) => {
        userList.push(v);
      });
      const filteredAttendees = userList.filter(
        (user) => user.username !== presenter.username
      );
      setAttendeeList(filteredAttendees);
    };
    init();
  }, [open]);

  /**
   * Hook to set the attendeeList.
   */
  React.useEffect(() => {
    if (!isNil(data.attendees)) {
      // Filter out the presenter from the attendees list.
      // console.log("XXX presenter", presenter);
      const filteredAttendees = data.attendees.filter(
        (attendee) => attendee.username !== presenter.username
      );
      setAttendeeList(filteredAttendees);
    }
  }, [data.attendees]);

  const handleAttendeeSelected = (userId) => {
    setSelectedAttendee(userId);
  };

  /**
   * Datagrid columns for attendee list.
   */
  const attendeeColumns = [
    {
      field: "userId",
      headerName: "",
      disableColumnMenu: true,
      width: "50",
      renderCell: (params) => (
        <>
          <Radio
            checked={selectedAttendee === params.row.userId}
            onChange={() => handleAttendeeSelected(params.row.userId)}
            value={params.row.userId}
            name="radio-buttons"
          />
        </>
      )
    },
    {
      field: "name",
      headerName: "Name",
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: "username",
      headerName: "Email",
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: "role",
      headerName: "Role",
      disableColumnMenu: true,
      flex: 1
    }
  ];

  /**
   * Event handler for "Grant Annotation Rights" button.
   */
  const handleGrantAnnotationRights = async () => {
    const annotator = await grantAnnotationRights(
      apiData.eventId,
      selectedAttendee
    );

    if (annotator !== null) {
      emitAnnotatorChanged(apiData.eventId, annotator);
      updateAnnotator(annotator);
      updateEditorHeaderAndToolbars();
    }

    onClose();
  };

  return (
    <>
      <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
        <form>
          <CRXDialogTitle onClick={onClose} title="Grant Annotation Rights" />
          <DialogContent>
            <div style={{ height: "calc(100vh - 400px)", width: "100%" }}>
              {attendeeList && (
                <DataGridPro
                  // className={classes.paper}
                  rows={attendeeList}
                  columns={attendeeColumns}
                  rowHeight={48}
                  pagination
                  // paginationMode="server"
                  // pageSize={pageLength}
                  // rowCount={pagination}
                  // onPageChange={(newPage) => setPageIndex(newPage)}
                  // onPageSizeChange={(newPageSize) => {
                  //   setPageLength(newPageSize.pageSize);
                  // }}
                  // checkboxSelection
                  disableSelectionOnClick
                  getRowId={(row) => row.userId}
                  loading={loading}
                  sortingMode="server"
                  // onSortModelChange={handleSortModelChange}
                  // onSelectionModelChange={(value) => {
                  //   setSelectedIds(value);
                  // }}
                  sx={{
                    boxShadow: 0,
                    border: 1,
                    borderColor: "#e4e4e4",
                    "& .MuiDataGrid-cell": {
                      borderColor: "#e4e4e4"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      borderColor: "#e4e4e4"
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderColor: "#e4e4e4"
                    },
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main"
                    }
                  }}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
            <Button
              onClick={handleGrantAnnotationRights}
              variant="contained"
              color="primary"
            >
              Grant Annotation Rights
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

/**
 * "Revoke Annotation Rights" Dialog.
 *
 * This dialog requests whether the Presenter would like to revoke annotation
 * rights from the current Annotator.
 *
 * @param {*} param0
 * @returns
 */
const RevokeAnnotationRightsDialog = ({
  annotator,
  presenter,
  open,
  onClose
}) => {
  const { data: apiData } = useDataService();

  /**
   * Event handler for "Revoke Annotation Rights" button.
   */
  const handleRevokeAnnotationRights = async () => {
    const revokedAnnotator = await revokeAnnotationRights(apiData.eventId);

    if (revokedAnnotator !== null) {
      emitAnnotatorChanged(apiData.eventId, presenter);
      updateAnnotator(presenter);
      updateEditorHeaderAndToolbars();
    }

    onClose();
  };

  return (
    <>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
        <form>
          <CRXDialogTitle onClick={onClose} title="Revoke Annotation Rights" />
          <DialogContent>
            <div style={{ height: "400px)", width: "100%" }}>
              Please confirm that you wish to revoke annotation rights from the
              current annotator: "{annotator?.name} ({annotator?.username})"
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
            <Button
              onClick={handleRevokeAnnotationRights}
              variant="contained"
              color="primary"
            >
              Revoke Annotation Rights
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

/**
 * "Annotation Rights" confirmation dialog.   This dialog is used to notify
 * other attendees that another attendee has been granted / revoked annotation rights.
 *
 * @param {*} param0
 * @returns
 */
const AnnotationRightsChangedDialog = ({
  annotator,
  presenter,
  open,
  onClose
}) => {
  const [annotationMsg, setAnnotationMsg] = useState("");

  React.useEffect(() => {
    setAnnotationMsg(
      `${annotator.name} (${annotator.username}) has been granted annotation rights to this exhibit.`
    );
  }, [annotator]);

  return (
    <>
      <Dialog maxWidth={"md"} open={open} onClose={onClose}>
        <form
          style={{
            width: "600px"
          }}
        >
          <CRXDialogTitle onClick={onClose} title="Annotation Rights Changed" />
          <DialogContent>{annotationMsg}</DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              OK
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export {
  GrantAnnotationRightsDialog,
  RevokeAnnotationRightsDialog,
  AnnotationRightsChangedDialog
};
