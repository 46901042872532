/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import { CRXDialogTitle } from "../common/dialog/CRXDialogTitle";
import Joi from "joi-browser";
import { isEmpty } from "lodash";
import { useAuthService } from "../../services/AuthService";
import {
  validate,
  validateProperty,
  setErrorMessages,
} from "../common/validateUtilities";

const useStyles = makeStyles((theme) => ({}));

const ResetPasswordDialog = ({ open, onClose, selectedUser, onSuccess }) => {
  const classes = useStyles();
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuthService();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    onClose();
  };

  const schema = {
    oldPassword: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(56)
      .label("Current Password"),
    newPassword: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(56)
      .label("Password"),
    verifyPassword: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(56)
      .label("Verify Password"),
  };

  const handleClose = () => {
    onClose();
  };

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const identity = JSON.parse(localStorage.getItem("huseby-identity"));
      const username = identity.username;
      const oldPassword = e.target.oldPassword.value;
      const newPassword = e.target.newPassword.value;
      const verifyPassword = e.target.verifyPassword.value;

      let errs = validate({ oldPassword, newPassword, verifyPassword }, schema);

      if (errs === null && newPassword !== verifyPassword) {
        if (errs === null) errs = {};
        errs["verifyPassword"] =
          "Password and Verify Password are not the same";
        setErrors(errs);
        return;
      }
      await resetPassword(username, oldPassword, newPassword, verifyPassword);
      setOpenSnackbar(true);
    } catch (ex) {
      console.log(ex.response);

      if (ex.response.status === 403) {
        let errMessage = ex.response.data.message;
        if (ex.response.data.message === "Access Denied") {
          errMessage = "Your current password is not correct.";
        }
        setErrorMessages("server", errMessage, setErrors);
      } else if (ex.message === "Network Error") {
        setErrorMessages(
          "server",
          "There was an issue connecting to the internet.  Please check your connection.",
          setErrors
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <CRXDialogTitle onClick={handleClose} title="Reset Password" />
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            width: "100%",
          }}
        >
          <Box style={{ paddingLeft: 30, paddingRight: 30 }}>
            To change your password, please use a strong password. Your new
            password must following the following rules:
            <ul>
              <li>Must not be the same as current password</li>
              <li>Between 8 and 16 characters long</li>
              <li>Contains one or more capital letters</li>
              <li>Contains a digit</li>
              <li>Contains a special character: ~!@#$%&*()-</li>
            </ul>
          </Box>
          {errors && !isEmpty(errors["server"]) && (
            <div style={{ color: "red", paddingLeft: 30 }}>
              {errors["server"]}
            </div>
          )}
          <DialogContent>
            <TextField
              error={errors && !isEmpty(errors["oldPassword"])}
              helperText={errors && errors["oldPassword"]}
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Current Password"
              type="password"
              id="oldPassword"
              onChange={(e) => {
                setOldPassword(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
            />
            <TextField
              error={
                errors &&
                (!isEmpty(errors["newPassword"]) ||
                  !isEmpty(errors["passwordError"]))
              }
              helperText={
                errors && (errors["newPassword"] || errors["passwordError"])
              }
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Password"
              type="password"
              id="newPassword"
              onChange={(e) => {
                setNewPassword(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
            />
            <TextField
              error={errors && !isEmpty(errors["verifyPassword"])}
              helperText={errors && errors["verifyPassword"]}
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="verifyPassword"
              label="Verify Password"
              type="password"
              id="verifyPassword"
              onChange={(e) => {
                setVerifyPassword(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              //   disabled={!isEmpty(errors)}
              loading={loading}
              loadingPosition="center"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        message="Your password has been updated successfully."
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Your password has been updated successfully.
        </Alert>
      </Snackbar>
    </>
  );
};

export { ResetPasswordDialog };
