// src/hooks/useBrand.js
import { useState, useEffect } from "react";

const esquireBrand = {
  companyName: "Esquire",
  productName: "EsquireConnect",
  aboutUrl: "https://www.esquiresolutions.com/technology/remote-depositions/",
  supportPhoneNumber: {
    tel: "18554537228",
    label: "(855)453-7228",
  },
  themePrimaryColor: "",
  themeSecondaryColor: "",
  favIcon: "/esquire/images/esquire-favicon-45x45.webp",
};

const husebyBrand = {
  companyName: "Huseby",
  productName: "HusebyConnect",
  aboutUrl: "https://www.huseby.com/remote-depositions",
  supportPhoneNumber: {
    tel: "18554537228",
    label: "(855)453-7228",
  },
  themePrimaryColor: "",
  themeSecondaryColor: "",
  favIcon: "/huseby/images/favicon.ico",
};

const useBrand = () => {
  const [brandInfo, setBrandInfo] = useState({});

  useEffect(() => {
    console.log("Setting brand data...");
    // Define your brand info here
    let brandData = husebyBrand;

    if (process.env.REACT_APP_BRAND_ID == "esquire") {
      brandData = esquireBrand;
    }

    console.log("XXX brandData", brandData);
    // Set the brand info in the state
    setBrandInfo(brandData);
  }, []);

  setFavicon(brandInfo.favIcon);

  return brandInfo;
};

// src/utils.js
export const setFavicon = (url) => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
};

export default useBrand;
