/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/
import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { CRXDialogTitle } from "../common/dialog/CRXDialogTitle";

const TermsOfService = ({ open, handleClose }) => {
  const getTermsOfServiceTitle = () => {
    const termsOfUseTitle =
      process.env.REACT_APP_BRAND_ID === "esquire"
        ? "Esquiresolutions.com / EsquireConnect - Terms of Use"
        : "Huseby.com / Huseby Connect - Terms of Use";

    return termsOfUseTitle;
  };

  const getTermsOfUse = () => {
    const termsOfUse =
      process.env.REACT_APP_BRAND_ID === "esquire"
        ? esquireTermsOfUse()
        : husebyTermsOfUse();
    return termsOfUse;
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"lg"}
        aria-labelledby="form-dialog-title"
      >
        <CRXDialogTitle
          onClick={handleClose}
          title={getTermsOfServiceTitle()}
        />

        <DialogContent>{getTermsOfUse()}</DialogContent>
        <DialogActions>
          {/* <Box display="flex" justifyContent="flex-end" width={1}>
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Box> */}
          <Button variant="contained" color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/**
 * Huseby's Terms of Use Policy
 */
const husebyTermsOfUse = () => {
  return (
    <DialogContentText>
      <h2>Huseby.com / Huseby Connect - Terms of Use</h2>
      <h3>Terms of Use ("Terms")</h3>

      <p>
        Last updated: 09/01/2021 Please read these Terms of Use ("Terms", "Terms
        of Use") carefully before using the https://www.huseby.com website and
        the HusebyConnect application (the "Service") operated by Huseby LLC
        (“Huseby”, "us", "we", or "our").
        <br />
        <br />
        Your access to and use of the Service is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users and others who access or use the Service.
        <br />
        <br />
        <b>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </b>
      </p>
      <h3>Purchases</h3>
      <p>
        If you wish to purchase any product or service made available through
        the Service ("Purchase"), you may be asked to supply certain information
        relevant to your Purchase including, but not limited to, your name,
        company name, phone number, email address, mailing address, and
        specifics about the services you provide. We use this information to
        allow us to provide the best possible services to meet your needs.
      </p>
      <h3>Termination</h3>
      <p>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. All provisions of the Terms which by
        their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability.
      </p>
      <h3>Content</h3>
      <p>
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). This information will be used solely to provide our
        Services to you and will not be shared with third parties who are not
        directly involved in providing those Services. You are responsible for
        the quality, accuracy, organization, and content of those materials. You
        are also responsible for making sure that Content does not contain
        malware, viruses, trojans, ransomware, or any other electronic
        instructions, content, or structures designed to interfere with our
        computer systems or software.
      </p>
      <h3>Links To Other Web Sites</h3>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by Huseby. These links are only for sites or
        services required to perform the Services you have requested from us. We
        have no control over, and assume no responsibility for, the content,
        privacy policies, or practices of any third-party web sites or services.
        You further acknowledge and agree that Huseby shall not be responsible
        or liable, directly or indirectly, for any damage or loss caused or
        alleged to be caused by or in connection with use of or reliance on any
        such content, goods or services available on or through any such web
        sites or services.
      </p>
      <h3>Changes</h3>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will use best efforts to
        provide at least 30 days' notice prior to any new terms taking effect.
        What constitutes a material change will be determined at our sole
        discretion.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us via email
        at <a href="mailto:contact@huseby.com">contact@huseby.com</a>, by phone
        at <a href="tel:(704) 333-9889">(704) 333-9889</a>, or by physical mail
        at 1230 W Morehead St #102, Charlotte, NC 28208.
      </p>
    </DialogContentText>
  );
};

/**
 * Esquire's Terms of Use Policy
 */
const esquireTermsOfUse = () => {
  return (
    <DialogContentText>
      <h2>Esquiresolutions.com / EsquireSolutions - Terms of Use</h2>
      <h3>Terms of Use ("Terms")</h3>

      <p>
        Last updated: 09/01/2021 Please read these Terms of Use ("Terms", "Terms
        of Use") carefully before using the https://www.esquiresolutions.com
        website and the EsquireConnect application (the "Service") operated by
        Esquire Solutions (“Esquire”, "us", "we", or "our").
        <br />
        <br />
        Your access to and use of the Service is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users and others who access or use the Service.
        <br />
        <br />
        <b>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </b>
      </p>
      <h3>Purchases</h3>
      <p>
        If you wish to purchase any product or service made available through
        the Service ("Purchase"), you may be asked to supply certain information
        relevant to your Purchase including, but not limited to, your name,
        company name, phone number, email address, mailing address, and
        specifics about the services you provide. We use this information to
        allow us to provide the best possible services to meet your needs.
      </p>
      <h3>Termination</h3>
      <p>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. All provisions of the Terms which by
        their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability.
      </p>
      <h3>Content</h3>
      <p>
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). This information will be used solely to provide our
        Services to you and will not be shared with third parties who are not
        directly involved in providing those Services. You are responsible for
        the quality, accuracy, organization, and content of those materials. You
        are also responsible for making sure that Content does not contain
        malware, viruses, trojans, ransomware, or any other electronic
        instructions, content, or structures designed to interfere with our
        computer systems or software.
      </p>
      <h3>Links To Other Web Sites</h3>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by Esquire. These links are only for sites
        or services required to perform the Services you have requested from us.
        We have no control over, and assume no responsibility for, the content,
        privacy policies, or practices of any third-party web sites or services.
        You further acknowledge and agree that Esquire shall not be responsible
        or liable, directly or indirectly, for any damage or loss caused or
        alleged to be caused by or in connection with use of or reliance on any
        such content, goods or services available on or through any such web
        sites or services.
      </p>
      <h3>Changes</h3>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will use best efforts to
        provide at least 30 days' notice prior to any new terms taking effect.
        What constitutes a material change will be determined at our sole
        discretion.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us via email
        at{" "}
        <a href="mailto:contact@esquiresolutions.com">
          contact@esquiresolutions.com
        </a>
        , by phone at <a href="tel:(704) 333-9889">(704) 333-9889</a>, or by
        physical mail at 1230 W Morehead St #102, Charlotte, NC 28208.
      </p>
    </DialogContentText>
  );
};

export default TermsOfService;
