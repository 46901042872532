/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/
import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { CRXDialogTitle } from "../common/dialog/CRXDialogTitle";

const PrivacyPolicy = ({ open, handleClose }) => {
  const getPrivacyPolicyTitle = () => {
    const privacyPolicyTitle =
      process.env.REACT_APP_BRAND_ID === "esquire"
        ? "Esquire Solutions Privacy and Data Retention Policy"
        : "Huseby, LLC Privacy and Data Retention Policy";

    console.log("XXX privacyPolicyTitle", privacyPolicyTitle);
    return privacyPolicyTitle;
  };

  const getPrivacyPolicy = () => {
    console.log("XXX getPrivacyPolicy");
    const privacyPolicy =
      process.env.REACT_APP_BRAND_ID === "esquire"
        ? esquirePrivacyPolicy()
        : husebyPrivacyPolicy();
    console.log("XXX privacyPolicy", privacyPolicy);
    return privacyPolicy;
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"lg"}
        aria-labelledby="form-dialog-title"
      >
        <CRXDialogTitle onClick={handleClose} title={getPrivacyPolicyTitle()} />

        <DialogContent>{getPrivacyPolicy()}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/**
 * Huseby's Privacy Policy.
 */
const husebyPrivacyPolicy = () => {
  return (
    <DialogContentText>
      <h2>Huseby, LLC Privacy and Data Retention Policy</h2>
      <h3>Privacy Policy</h3>
      <p>
        At Huseby.com, accessible at 
        <a href="https://www.huseby.com" target="_blank">
          https://www.huseby.com
        </a>
        , one of our main priorities is the privacy of our visitors. Our Privacy
        Policy can be found at{" "}
        <a href="https://www.huseby.com/privacy-and-terms" target="_blank">
          Privacy &amp; Terms - Huseby
        </a>{" "}
        on our website. If you have additional questions or require more
        information about our Privacy Policy, or if you need a copy sent
        directly to you, please do not hesitate to contact us using email at 
        <a href="mailto:contact@huseby.com">contact@huseby.com</a> The remaining
        sections of this document provide details about our data retention
        policy.
      </p>
      <h3>Log Files</h3>
      <p>
        Huseby.com follows a standard procedure of using log files. These files
        log visitor activities when you visit our website. This is a standard
        practice of website hosting companies and is a part of hosting
        services&#39; analytics. The information collected by log files include
        internet protocol (IP) addresses, browser type, Internet Service
        Provider (ISP), date and time stamp, referring/exit pages, and key
        interactions you have with our website such as clicks, moving between
        pages, etc. These are not linked to any information that is personally
        identifiable. The purpose of the information is for analyzing trends,
        administering the site, tracking users&#39; movement on the website,
        gathering information used to enhance the site, and maintaining
        security.
      </p>
      <h3>Cookies and Web Beacons</h3>
      <p>
        Like any other website, Huseby.com uses “cookies”. These cookies are
        used to store information including visitors&#39; preferences and the
        pages on the website that the visitor accessed or visited. The
        information is used to optimize the users&#39; experience by customizing
        our web page content based on visitors&#39; browser type and other
        information.
      </p>
      <h3>Advertising and DoubleClick DART Cookies</h3>
      <p>
        Huseby.com does not include ads from advertising partners or other third
        parties and does not use DART cookies.
      </p>
      <h3>How Long We Keep Your Data</h3>
      <p>
        We keep your personal data and data related to your account for a
        minimum of seven (7) years. We will keep it for a longer period as
        needed to maintain our ongoing relationship with you and to enable us to
        continue to provide you with service for work we already performed for
        you.
      </p>
      <p>
        <b>Data Destruction.</b> When our services for you have been completed,
        or you terminate your agreements with us, or, if you determine that your
        data no longer serves a legitimate business need related to the services
        we provide, we will return your data to your or destroy it at your
        written request. We will never destroy your data without your express
        written permission or, if you’ve not responded in writing after we’ve
        given you written notice at least 60 days in advance that data is to be
        destroyed or returned for one of the reasons listed above. Media on
        which your may be stored and associated acceptable methods of
        destruction are listed below:
      </p>
      <p style={{ marginTop: "20px" }}>
        <table>
          <th style={{ textAlign: "left" }}>Company Data stored on:</th>
          <th style={{ textAlign: "left" }}>Will be destroyed by*:</th>
          <tr>
            <td>
              Server or workstation hard disks, or Removable media (e.g. USB
              flash drives, portable hard disks) excluding optical discs
            </td>
            <td>
              Using a “wipe” utility which will overwrite data at least three
              (3) times using either random or single character data, or
              degaussing sufficiently to ensure that your data cannot be
              reconstructed, or physically destroying the disk
            </td>
          </tr>
          <tr>
            <td>Paper documents not containing Confidential Information</td>
            <td>
              Using a “wipe” utility which will overwrite data at least three
              (3) times using either random or single character data, or
              degaussing sufficiently to ensure that your data cannot be
              reconstructed, or physically destroying the disk{" "}
            </td>
          </tr>
          <tr>
            <td>Paper documents not containing Confidential Information</td>
            <td>
              Paper documents not containing Confidential Information Recycling
              through a contracted firm provided the contract with the recycler
              assures that the documents will remain in the recycler’s secure
              chain of custody until recycled.{" "}
            </td>
          </tr>
          <tr>
            <td>Paper documents containing Confidential Information</td>
            <td>On-site shredding, pulping, or incineration </td>
          </tr>
          <tr>
            <td>Optical discs (e.g. CDs or DVDs) </td>
            <td>
              Incineration, shredding, or completely defacing the readable
              surface with a coarse abrasive{" "}
            </td>
          </tr>
          <tr>
            <td>Magnetic tape </td>
            <td>Degaussing, incinerating or crosscut shredding </td>
          </tr>
        </table>
      </p>
    </DialogContentText>
  );
};

/**
 * Esquire's Privacy Policy.
 */
const esquirePrivacyPolicy = () => {
  return (
    <DialogContentText>
      <h2>Esquire Privacy and Data Retention Policy</h2>
      <h3>Privacy Policy</h3>
      <p>
        At esquiresolutions.com, accessible at 
        <a href="https://www.esquiresolutions.com" target="_blank">
          https://www.esquiresolutions.com
        </a>
        , one of our main priorities is the privacy of our visitors. Our Privacy
        Policy can be found at{" "}
        <a
          href="https://www.esquiresolutions.com/privacy-and-terms"
          target="_blank"
        >
          Privacy &amp; Terms - Esquire Solutions
        </a>{" "}
        on our website. If you have additional questions or require more
        information about our Privacy Policy, or if you need a copy sent
        directly to you, please do not hesitate to contact us using email at 
        <a href="mailto:contact@esquiresolutions.com">
          contact@esquiresolutions.com
        </a>{" "}
        The remaining sections of this document provide details about our data
        retention policy.
      </p>
      <h3>Log Files</h3>
      <p>
        esquiresolutions.com follows a standard procedure of using log files.
        These files log visitor activities when you visit our website. This is a
        standard practice of website hosting companies and is a part of hosting
        services&#39; analytics. The information collected by log files include
        internet protocol (IP) addresses, browser type, Internet Service
        Provider (ISP), date and time stamp, referring/exit pages, and key
        interactions you have with our website such as clicks, moving between
        pages, etc. These are not linked to any information that is personally
        identifiable. The purpose of the information is for analyzing trends,
        administering the site, tracking users&#39; movement on the website,
        gathering information used to enhance the site, and maintaining
        security.
      </p>
      <h3>Cookies and Web Beacons</h3>
      <p>
        Like any other website, Esquiresolutions.com uses “cookies”. These
        cookies are used to store information including visitors&#39;
        preferences and the pages on the website that the visitor accessed or
        visited. The information is used to optimize the users&#39; experience
        by customizing our web page content based on visitors&#39; browser type
        and other information.
      </p>
      <h3>Advertising and DoubleClick DART Cookies</h3>
      <p>
        Esquiresolutions.com does not include ads from advertising partners or
        other third parties and does not use DART cookies.
      </p>
      <h3>How Long We Keep Your Data</h3>
      <p>
        We keep your personal data and data related to your account for a
        minimum of seven (7) years. We will keep it for a longer period as
        needed to maintain our ongoing relationship with you and to enable us to
        continue to provide you with service for work we already performed for
        you.
      </p>
      <p>
        <b>Data Destruction.</b> When our services for you have been completed,
        or you terminate your agreements with us, or, if you determine that your
        data no longer serves a legitimate business need related to the services
        we provide, we will return your data to your or destroy it at your
        written request. We will never destroy your data without your express
        written permission or, if you’ve not responded in writing after we’ve
        given you written notice at least 60 days in advance that data is to be
        destroyed or returned for one of the reasons listed above. Media on
        which your may be stored and associated acceptable methods of
        destruction are listed below:
      </p>
      <p style={{ marginTop: "20px" }}>
        <table>
          <th style={{ textAlign: "left" }}>Company Data stored on:</th>
          <th style={{ textAlign: "left" }}>Will be destroyed by*:</th>
          <tr>
            <td>
              Server or workstation hard disks, or Removable media (e.g. USB
              flash drives, portable hard disks) excluding optical discs
            </td>
            <td>
              Using a “wipe” utility which will overwrite data at least three
              (3) times using either random or single character data, or
              degaussing sufficiently to ensure that your data cannot be
              reconstructed, or physically destroying the disk
            </td>
          </tr>
          <tr>
            <td>Paper documents not containing Confidential Information</td>
            <td>
              Using a “wipe” utility which will overwrite data at least three
              (3) times using either random or single character data, or
              degaussing sufficiently to ensure that your data cannot be
              reconstructed, or physically destroying the disk{" "}
            </td>
          </tr>
          <tr>
            <td>Paper documents not containing Confidential Information</td>
            <td>
              Paper documents not containing Confidential Information Recycling
              through a contracted firm provided the contract with the recycler
              assures that the documents will remain in the recycler’s secure
              chain of custody until recycled.{" "}
            </td>
          </tr>
          <tr>
            <td>Paper documents containing Confidential Information</td>
            <td>On-site shredding, pulping, or incineration </td>
          </tr>
          <tr>
            <td>Optical discs (e.g. CDs or DVDs) </td>
            <td>
              Incineration, shredding, or completely defacing the readable
              surface with a coarse abrasive{" "}
            </td>
          </tr>
          <tr>
            <td>Magnetic tape </td>
            <td>Degaussing, incinerating or crosscut shredding </td>
          </tr>
        </table>
      </p>
    </DialogContentText>
  );
};

export default PrivacyPolicy;
