/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { toHex } from "../transcriptUtilities";

const HighlightButton = ({ colors, selected, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedOption, setSelectedOption] = React.useState(selected);

  const handleClick = () => {
    console.info(`You clicked ${selectedOption}`);
  };

  const handleMenuItemClick = (event, option) => {
    setSelectedOption(option);
    setOpen(false);
    onChange(option);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" color="secondary" ref={anchorRef} aria-label="split button">
        <Button color="secondary" onClick={handleClick} style={{ width: "225px" }}>
          <Box
            style={{
              width: "15px",
              height: "15px",
              marginRight: "15px",
              backgroundColor: `${toHex(selectedOption.color)}`,
            }}
          />
          {selectedOption.label}
        </Button>
        <Button color="secondary" size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 1500 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ maxHeight: 250, overflow: "auto", width: 300 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" style={{ padding: 2 }}>
                  {colors.map((option) => (
                    <MenuItem
                      key={option.color}
                      selected={option.color === selectedOption.color}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      <Box
                        display="flex"
                        p={1}
                        style={{ width: "100%", backgroundColor: `${toHex(option.color)}` }}
                      >
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default HighlightButton;
