/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    color: "#000000",
    fontWeight: "bold",
    paddingTop: 10,
    paddingBottom: 10,
    margin: 2,
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
  },
}));

function CRXDialogTitle(props) {
  const classes = useStyles();
  return (
    <DialogTitle className={classes.root}>
      {props.title}
      <IconButton onClick={props.onClick} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

export { CRXDialogTitle };
