/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& * ": {
      fontSize: "10pt"
    },
    "& > * .MuiDialogActions-root": {
      backgroundColor: "#e4e4e4",
      borderTop: "1px solid #999999"
    }
  },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt"
  },
  dialog: {
    borderRadius: 0,
    fontSize: "10pt",
    border: "1px solid #333333"
  },
  dialogTitle: {
    background:
      "linear-gradient(180deg, rgba(228,228,228,1) 50%, rgba(200,200,200,1) 100%)",
    color: "#000000",
    fontSize: "10pt",
    fontWeight: "bold",
    paddingTop: 2,
    paddingBottom: 2,
    margin: 0,
    borderBottom: "1px solid #333333"
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2
  },
  dialogTitleFont: {
    color: "#000000",
    fontSize: "10pt",
    fontWeight: "bold"
  },
  dialogButton: {
    background:
      "linear-gradient(180deg, rgba(228,228,228,1) 50%, rgba(200,200,200,1) 100%)",
    border: "1px solid #333333",
    color: "#000000",
    fontSize: "10pt",
    height: "28px",
    paddingLeft: 10,
    paddingRight: 10,
    textTransform: "none"
  },
  dialogContent: {
    fontSize: "10pt",
    color: "#000000",
    margin: 0,
    padding: 0
  },
  redButton: {
    // background: "linear-gradient(180deg, rgba(175,23,48,1) 90%, rgba(111,15,31,1) 100%)",
    border: "1px solid #333333",
    color: "#ffffff",
    fontSize: "10pt",
    height: "30px",
    paddingLeft: 10,
    paddingRight: 10
    // fontWeight: "bold",
    // textTransform: "none",
  },
  blueButton: {
    // background:
    //   "linear-gradient(180deg, rgba(28,105,214,1) 70%, rgba(20,69,139,1) 100%)",
    border: "1px solid #333333",
    color: "#ffffff",
    fontSize: "10pt",
    height: "30px",
    paddingLeft: 10,
    paddingRight: 10
  }
}));
