/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import { resetTranscript } from "../../../services/SocketIOService";
import { TranscriptContext } from "../../../services/TranscriptService";
import { StreamTextContext } from "../../../services/StreamTextService";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
  },
}));

const DeleteTranscriptConfirmation = ({ open, handleStopTranscript, handleClose, onSuccess }) => {
  const classes = useStyles();
  const { data: model } = React.useContext(TranscriptContext);
  const { resetTranscript: resetStreamTextTranscript } = React.useContext(StreamTextContext);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleDelete = async (e) => {
    try {
      setIsDeleting(true);
      await handleStopTranscript();
      console.log("XXXXX Reset transcript...");
      await resetTranscript(model.eventId);
      await resetStreamTextTranscript(model.eventId);
      onSuccess({
        severity: "success",
        message: `The transcript has been successfully deleted.`,
      });
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        className={classes.root}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableEnforceFocus
      >
        <CRXDialogTitle onClick={handleClose} title="Delete Transcript" />

        <DialogContent>
          <Box m={1}>
            Please confirm that you want to delete the current transcript and any associated
            bookmarks.
            <br />
            <br />
            Are you sure that you want to delete the current transcript?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleDelete}
            loading={isDeleting}
          >
            Delete Transcript
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteTranscriptConfirmation;
