/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SplitButton from "../../../common/SplitButton";
import {
  REALTIME_TRANSCRIPTS_ENABLED,
  VOICETOTEXT_TRANSCRIPTS_ENABLED,
} from "../../../model/Model";
import { TranscriptContext } from "../../../services/TranscriptService";

const themeTypes = [
  { value: "#FFFFFF-#000000", label: "White (B)" },
  { value: "#666666-#000000", label: "Grey (B)" },
  { value: "#111111-#FFFFFF", label: "Black (W)" },
  { value: "#111111-#FFFFCC", label: "Black (Y)" },
  { value: "#663300-#FFFFCC", label: "Sepia (Y)" },
  { value: "#1111FF-#FFFFCC", label: "Blue (Y)" },
];

const fontSizes = [
  { value: "10pt", label: "10 pt" },
  { value: "12pt", label: "12 pt" },
  { value: "14pt", label: "14 pt" },
  { value: "16pt", label: "16 pt" },
];

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingLeft: "0px",
    paddingRight: "10px",
  },
  menuItemSmall: {
    paddingLeft: "0px",
    paddingRight: "10px",
    display: "inline-flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

const ViewerMenu = ({
  onBackgroundColorChange,
  onFontColorChange,
  onFontSizeChange,
  onShowPagesAndLinesChange,
  onShowSpeakersChange,
  onShowTimeChange,
  onScrollToPageChange,
  onScrollToLineChange,
  pageOptions,
  lineOptions,
}) => {
  const classes = useStyles();
  const { data } = React.useContext(TranscriptContext);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(false);
  const [currentPageOptions, setCurrentPageOptions] = useState([]);
  const [currentLineOptions, setCurrentLineOptions] = useState([]);
  const [enableAutoscroll, setEnableAutoscroll] = React.useState(true);
  const [showPagesAndLines, setShowPagesAndLines] = React.useState(false);
  const [showSpeakers, setShowSpeakers] = React.useState(
    data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED
  );
  const [showTime, setShowTime] = React.useState(true);
  const [selectedPageLine, setSelectedPageLine] = React.useState({
    page: 1,
    line: 1,
  });
  const [selectedFontSize, setSelectedFontSize] = React.useState(fontSizes[1].value); // Default to 12pt

  /**
   * Hook for intializing the ViewerMenu when it is opened.
   */
  useEffect(() => {
    if (optionsAnchorEl !== false) {
      setCurrentPageOptions(pageOptions);
      setCurrentLineOptions(lineOptions);
    }
  }, [optionsAnchorEl]);

  /**
   * Event handler for when the "Display Pages and Lines Column" checkbox is changed.
   *
   * @param {*} e
   */
  const handleShowPagesAndLinesChange = (e) => {
    setShowPagesAndLines(e.target.checked);
    onShowPagesAndLinesChange(e.target.checked);
  };

  /**
   * Event handler for when the "Display Speakers Column" checkbox is changed.
   *
   * @param {*} e
   */
  const handleShowSpeakersChange = (e) => {
    setShowSpeakers(e.target.checked);
    onShowSpeakersChange(e.target.checked);
  };

  /**
   * Event handler for when the "Display Time Column" checkbox is changed.
   *
   * @param {*} e
   */
  const handleShowTimeChange = (e) => {
    setShowTime(e.target.checked);
    onShowTimeChange(e.target.checked);
  };

  /**
   * Event handler for when the font size is changed.
   *
   * @param {*} fontSize
   */
  const handleFontSizeChange = (fontSize) => {
    const size = fontSize.value;
    setSelectedFontSize(size);
    onFontSizeChange(size);
  };

  /**
   * Event handler for when a page is selected to scroll to from the "Scroll to Page" <Select> component.
   *
   * @param {*} e
   */
  const handleScrollToPage = (e) => {
    const pageNumber = e.currentTarget.value;
    setSelectedPageLine({
      ...selectedPageLine,
      page: pageNumber,
    });
    onScrollToPageChange(pageNumber, selectedPageLine.line);
  };

  /**
   * Event handler for when a page is selected to scroll to from the "Scroll to Line" <Select> component.
   *
   * @param {*} e
   */
  const handleScrollToLine = (e) => {
    // console.log('handleScrollToLine', e.currentTarget.value);
    const lineNumber = e.currentTarget.value;
    setSelectedPageLine({
      ...selectedPageLine,
      line: lineNumber,
    });
    onScrollToLineChange(lineNumber, selectedPageLine.page);
  };

  return (
    <>
      <Box component="div" display="flex" style={{ margin: 15 }}>
        <Tooltip title="Viewing Options">
          <IconButton
            variant="contained"
            onClick={(e) => setOptionsAnchorEl(e.currentTarget)}
            size="small"
          >
            {/* View Options */}
            <MenuIcon variant="contained" color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        id="customized-menu"
        anchorEl={optionsAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -45,
          horizontal: 130,
        }}
        keepMounted
        open={Boolean(optionsAnchorEl)}
        onClose={() => setOptionsAnchorEl(null)}
      >
        {/* <MenuItem
                    ref={(node) => {
                      if (node) {
                        node.style.setProperty("position", "static", "important");
                      }
                    }}
                  >
                    <span style={{ paddingRight: "10px" }}>Viewer Theme:</span>
                    <span className={classes.menuItemSmall} id="transcriptManagementColor">
                      <SplitButton options={themeTypes} onChange={handleThemeChange} />
                    </span>
                  </MenuItem> */}
        <MenuItem
          ref={(node) => {
            if (node) {
              node.style.setProperty("position", "static", "important");
            }
          }}
        >
          <span style={{ paddingRight: "10px" }}>Font Size:</span>
          <span className={classes.menuItemSmall} id="transcriptManagementFontSize">
            <SplitButton
              defaultValue={selectedFontSize}
              options={fontSizes}
              onChange={handleFontSizeChange}
            />
          </span>
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={handleShowPagesAndLinesChange}
                name="checkboxShowPagesAndLines"
              />
            }
            checked={showPagesAndLines}
            label="Display Page and Lines Column"
          />
        </MenuItem>

        {data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED && (
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleShowSpeakersChange}
                  name="checkboxShowSpeakers"
                />
              }
              checked={showSpeakers}
              label="Display Speakers Column"
            />
          </MenuItem>
        )}

        {data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED && (
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox color="primary" onChange={handleShowTimeChange} name="checkboxShowTime" />
              }
              checked={showTime}
              label="Display Time Column"
            />
          </MenuItem>
        )}
        <MenuItem>
          <span style={{ paddingRight: "10px" }}>Go To Page Number:</span>
          <span className={classes.menuItemSmall}>
            <Select
              className={classes.select}
              native
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              onChange={handleScrollToPage}
              color="primary"
              size="small"
            >
              {currentPageOptions}
            </Select>
          </span>
        </MenuItem>
        <MenuItem>
          <span style={{ paddingRight: "10px" }}>Go To Line:</span>
          <span className={classes.menuItemSmall}>
            <Select
              className={classes.select}
              native
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              onChange={handleScrollToLine}
              size="small"
            >
              {currentLineOptions}
            </Select>
          </span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ViewerMenu;
