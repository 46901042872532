/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import _, { isNil, merge } from "lodash";
import axios from "axios";
import { io } from "socket.io-client";
import { instance as http, AuthContext } from "@cirrux888/huseby-client-auth";

const URL = process.env.REACT_APP_SOCKETIO_URL;
const socket = io(URL, { autoConnect: false });

// Socket IO Event Types
export const TYPE_MESSAGE = "ZoomMeeting.message";
export const TYPE_USER_JOINED_ROOM = "userJoinedRoom";
export const TYPE_USER_LEFT_ROOM = "userLeftRoom";
export const TYPE_USER_TOGGLED_ZOOM_MIC = "userToggledZoomMic";
export const TYPE_USER_IN_BREAKOUT_ROOM = "userInBreakoutRoom";

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  breakoutRoomsInSession: false,
};

const SocketIOContext = React.createContext();

/**
 * Provides services for the SocketIOServer (RoomServer) for both websockets and
 * HTTP-based REST APIs.
 *
 * This consists of:
 * - Socket emitters
 * - Socket event handlers
 * - REST API services
 *
 * @param {*} props
 * @returns
 */
const SocketIOProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const { auth, getIdentity } = React.useContext(AuthContext);
  const identity = JSON.parse(getIdentity());

  /**
   * Join a HusebyConnect Room.
   *
   * @param {*} userId
   * @param {*} username
   * @param {*} name
   * @param {*} roomId
   * @param {*} role
   */
  const joinRoom = (userId, username, name, roomId, role) => {
    console.log("Joining room...", userId, username, name, roomId, role);

    socket.auth = { userId, username, name, roomId, role };
    socket.connect();

    console.log("Connected user to room.", socket);
  };

  /**
   *
   * @param {*} param0
   */
  const handleNewSessionId = ({ userId, username, name, roomId, role }) => {
    // attach the session ID to the next reconnection attempts
    console.log("handleNewSessionId", userId, username, name, roomId, role);
    socket.auth = { userId, username, name, roomId, role };
    socket.userId = userId;
  };

  /**
   * Leave the room.   This disconnects the socket.
   *
   */
  const leaveRoom = () => {
    socket.disconnect();
  };

  return (
    <SocketIOContext.Provider
      value={{
        data,
        setData,
        joinRoom,
        leaveRoom,
      }}
    >
      {props.children}
    </SocketIOContext.Provider>
  );
};

const useSocketIOService = () => React.useContext(SocketIOContext);

export { SocketIOContext, SocketIOProvider, useSocketIOService };

/**
 * Emit the user joined the Zoom room.
 *
 * @param {*} identity
 */
export const emitUserJoinedZoomRoom = (eventId, identity) => {
  console.log("User joined the meeting:", eventId, identity);
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_USER_JOINED_ROOM,
      userId: identity.userId,
      userName: identity.userName,
    },
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: eventId });
};

/**
 * Emit the user left the Zoom room.
 *
 * @oparam {*} identity
 */
export const emitUserLeftZoomRoom = (eventId, identity) => {
  console.log("User left the meeting:", eventId, identity);
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_USER_LEFT_ROOM,
      userId: identity.userId,
      userName: identity.userName,
    },
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: eventId });
};

/**
 * Emit the user toggled the Zoom mic.   If the `isZoomMicOn` is 0, then the
 * Zoom mic is off.   If the `isZoomMicOn` is 1, then the Zoom mic is on.
 *
 * @param {*} identity
 * @param {*} isZoomMicOn
 */
export const emitUserToggledZoomMic = (eventId, identity, isZoomMicOn) => {
  console.log("User toggled the Zoom mic:", eventId, identity, isZoomMicOn);
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_USER_TOGGLED_ZOOM_MIC,
      userId: identity.userId,
      userName: identity.userName,
      isZoomMicOn: isZoomMicOn,
    },
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: eventId });
};

/**
 * Emit the user is in a breakout room.   If the user is in a breakoutroom,
 * then we need to turn off the transcript mic and disable the "On Record" button.
 *
 * @param {*} identity
 * @param {*} inBreakoutRoom
 */
export const emitUserInBreakoutRoom = (eventId, identity, inBreakoutRoom) => {
  console.log("User is in breakout room:", eventId, identity, inBreakoutRoom);
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_USER_IN_BREAKOUT_ROOM,
      userId: identity.userId,
      userName: identity.userName,
      inBreakoutRoom: inBreakoutRoom,
    },
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: eventId });
};
