import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory, Redirect, Route } from "react-router-dom";
import { AuthContext } from "../../../services/AuthService";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const checkAuthentication = async () => {
      // Check if the token is present in parameter.
      let loggedIn = await isAuthenticated(query);
      console.log("loggedIn", loggedIn);
      if (loggedIn) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
        const next = location.pathname;
        const queryParams = location.search.replace("?", "");
        const redirectUrl = `/login?next=${next}&${queryParams}`;
        history.push(redirectUrl);
      }
    };
    checkAuthentication();
  }, []);

  return (
    <Route
      {...rest}
      render={() => {
        return authenticated && children;
      }}
    />
  );
};

export { PrivateRoute };
