/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  TextField,
} from "@mui/material";
import { Alert, LoadingButton } from "@mui/lab";
import { makeStyles, useTheme } from "@mui/styles";
import { AuthContext } from "../../services/AuthService";
import { isEmpty } from "lodash";
import Joi from "joi-browser";
import { CRXDialogTitle } from "../common/dialog/CRXDialogTitle";
import {
  validate,
  validateProperty,
  setErrorMessages,
} from "../common//validateUtilities";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   "& > * .MuiTextField-root": {
  //     margin: theme.spacing(1),
  //     width: "54ch",
  //   },
  // },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
  },
  progress: {
    color: "#71ee33",
  },
}));

const ForgotPassword = ({ open, handleClose }) => {
  const classes = useStyles();
  const { closeButton } = useTheme();
  const { forgotPassword } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    setUsername("");
    setErrors([]);
  }, [open]);

  const schema = {
    email: Joi.string().email().required().label("Email Address"),
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const email = e.target.email.value;
      const errs = validate({ email }, schema);

      if (errs != null) {
        setErrors(errs);
        return;
      }

      await forgotPassword(email);
      setOpenSnackbar(true);
      handleClose();
    } catch (ex) {
      if (
        ex.response &&
        (ex.response.status === 500 || ex.response.status === 403)
      ) {
        setErrorMessages("email", ex.response.data.message, setErrors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth aria-labelledby="form-dialog-title">
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <CRXDialogTitle onClick={handleClose} title="Forgot Password" />

          <DialogContent>
            <DialogContentText>
              Please enter your registered email here.
            </DialogContentText>
            <TextField
              error={errors && !isEmpty(errors["email"])}
              helperText={errors && errors["email"]}
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => {
                setUsername(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isEmpty(errors) || loading}
              loading={loading}
              loadingPosition="start"
              sx={{ width: "125px" }}
            >
              Send
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        message="A Password Recovery email has been sent."
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          A Password Recovery email has been sent.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPassword;
