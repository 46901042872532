/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { withStyles } from "@mui/styles";
import {
  IconButton,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Tooltip,
} from "@mui/material";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import {
  TRANSCRIPTS_DISABLED,
  REALTIME_TRANSCRIPTS_ENABLED,
  VOICETOTEXT_TRANSCRIPTS_ENABLED,
} from "../../../model/Model";
import { TranscriptPermissionsDialog } from "./TranscriptPermissions";
import { SpeakerLabelMapper } from "./SpeakerLabelMapper";
import { startTranscriptService, stopTranscriptService } from "../../../services/SocketIOService";
import { TranscriptContext } from "../../../services/TranscriptService";
import { useStreamTextContext } from "../../../services/StreamTextService";
import { Alert } from "@mui/lab";
import StopTranscriptConfirmation from "./StopTranscriptConfirmation";
import DeleteTranscriptConfirmation from "./DeleteTranscriptConfirmation";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:active": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SettingsMenu = ({ transcriptionDialogMeta, setTranscriptionDialogMeta }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    data: model,
    setData,
    getStreamedTranscript,
    stopTranscriptStream,
  } = React.useContext(TranscriptContext);
  const { stopStreamTextTranscript } = useStreamTextContext();
  const [transcriptServiceStatus, setTranscriptServiceStatus] = React.useState("stopped"); // dialing-in | started | stopped
  const [openPermissionsList, setOpenPermissionsList] = React.useState(false);
  const [openSpeakerlabelMapper, setOpenSpeakerLabelMapper] = React.useState(false);
  const [openStopTranscriptDialog, setOpenStopTranscriptDialog] = React.useState(false);
  const [openDeleteTranscriptDialog, setOpenDeleteTranscriptDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);

  React.useEffect(() => {
    if (anchorEl === null) return;

    setTranscriptServiceStatus(model.currentTranscriptStatus);
  }, [anchorEl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSuccess = async (snackbarData) => {
    // Snackbar
    setSnackbar({
      ...snackbarData,
    });
    handleClose();
  };

  const handleStartStream = async () => {
    if (model?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED) {
      console.log("Starting Voice-to-Text Transcripts...");
      setTranscriptServiceStatus("started");
      await startTranscriptService(model.eventId, "voiceToText");
      await getStreamedTranscript(model.eventId);
      setData({ transcript: [] });
    } else if (model?.event?.transcripts === REALTIME_TRANSCRIPTS_ENABLED) {
      console.log("Starting Real-Time Transcripts...");
      // setData({ transcript: [] });
      setData({ currentTranscriptStatus: "not-started" });
      setTranscriptionDialogMeta({
        ...transcriptionDialogMeta,
        openDialog: true,
      });
      // QW-TODO: When closing the StartStreamTextTranscript modal, the modal will not open again
      // when clicking on "Start Transcript Service" from the SettingsMenu.
    }
  };

  const handleStopStream = async () => {
    setTranscriptServiceStatus("stopped");
    const transcriptType =
      model?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED ? "voiceToText" : "realtime";

    // Send message to SocketIO server to notify clients to stop streaming transcript.
    console.log(
      "Sending message to SocketIO server to notify clients to stop streaming transcript..."
    );
    await stopTranscriptService({
      roomId: model.eventId,
      hangup: false,
      transcriptType: transcriptType,
    });

    // Stop the client from streaming the transcript.
    if (model?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED) {
      console.log("Stopping Voice-to-Text Transcripts...");
      await stopTranscriptStream(model.eventId);
    } else if (model?.event?.transcripts === REALTIME_TRANSCRIPTS_ENABLED) {
      console.log("Stopping Real-Time Transcripts...");
      await stopTranscriptStream(model.eventId);
      await stopStreamTextTranscript();
    }

    setData({ currentTranscriptStatus: "stopped" });
  };

  const handleDeleteTranscript = async () => {
    setOpenDeleteTranscriptDialog(true);
  };

  /**
   * Get the service label for displaying in the menu.
   *
   * @param {*} status
   * @returns
   */
  const getServiceLabel = (status) => {
    switch (status) {
      case "dialing-in":
        return "Starting Transcription Service...";
      case "stopped":
      case "not-started":
        return "Start Transcription Service";
      case "started":
      case "realtimeTranscriptsStarted":
        return "Stop Transcription Service";
      default:
        "Start";
    }
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton
          id="transcriptViewerSettingButton"
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleClick}
        >
          <SettingsIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          disabled={transcriptServiceStatus === "dialing-in"}
          onClick={() => {
            handleClose();
            if (
              transcriptServiceStatus === "stopped" ||
              transcriptServiceStatus === "not-started"
            ) {
              handleStartStream();
            } else {
              // handleStopStream();
              if (model?.event?.transcripts === REALTIME_TRANSCRIPTS_ENABLED) {
                setOpenStopTranscriptDialog(true);
              } else {
                handleStopStream();
              }
            }
          }}
        >
          <ListItemIcon>
            <Tooltip title={transcriptServiceStatus}>
              <PlaylistPlayIcon fontSize="small" color="primary" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={getServiceLabel(transcriptServiceStatus)} />
        </StyledMenuItem>
        {model?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED && (
          <StyledMenuItem
            onClick={() => {
              handleClose();
              setOpenSpeakerLabelMapper(true);
            }}
          >
            <ListItemIcon>
              <GroupIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary="Manage Speaker Labels" />
          </StyledMenuItem>
        )}
        <Divider />

        <StyledMenuItem
          onClick={() => {
            handleClose();
            setOpenPermissionsList(true);
          }}
        >
          <ListItemIcon>
            <SecurityIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Permissions List" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleDeleteTranscript();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Delete Transcript" />
        </StyledMenuItem>
      </StyledMenu>

      {openPermissionsList && (
        <TranscriptPermissionsDialog
          open={openPermissionsList}
          handleClose={() => setOpenPermissionsList(false)}
          onSuccess={handleOnSuccess}
        />
      )}
      {openSpeakerlabelMapper && (
        <SpeakerLabelMapper
          open={openSpeakerlabelMapper}
          handleClose={() => setOpenSpeakerLabelMapper(false)}
          onSuccess={handleOnSuccess}
        />
      )}

      {openStopTranscriptDialog && (
        <StopTranscriptConfirmation
          open={openStopTranscriptDialog}
          handleStopTranscript={handleStopStream}
          handleClose={() => {
            setOpenStopTranscriptDialog(false);
          }}
          onSuccess={handleOnSuccess}
        />
      )}

      {openDeleteTranscriptDialog && (
        <DeleteTranscriptConfirmation
          open={openDeleteTranscriptDialog}
          handleStopTranscript={handleStopStream}
          handleClose={() => {
            setOpenDeleteTranscriptDialog(false);
          }}
          onSuccess={handleOnSuccess}
        />
      )}

      {snackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={!!snackbar}
          autoHideDuration={4000}
          onClose={() => setSnackbar(null)}
        >
          <Alert
            onClose={() => setSnackbar(null)}
            severity={snackbar?.severity}
            style={{ textTransform: "none" }}
          >
            {snackbar?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SettingsMenu;
