/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http, AuthContext } from "@cirrux888/huseby-client-auth";
import { isNil, isEmpty, merge } from "lodash";
import {
  getRoom,
  getCurrentTranscriptStatusFromSocketState,
  TYPE_VTT_TRANSCRIPT_STARTED,
} from "./SocketIOService";
import { listDefaultColors } from "../components/transcripts/transcriptUtilities";
import { Model } from "../model/Model";

const DELAY = 800;
const BROADCAST_INTERVAL = 2000;

export const ROLE_HOST = "host";
export const ROLE_COHOST = "co-host";
export const ROLE_PARTICIPANT = "participant";

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

/**
 * The TranscriptViewer Model.
 */
const initialState = new Model();

/**
 * The timer for streaming transcripts.
 */
let streamingTranscriptTimer;

const TranscriptContext = React.createContext();

/**
 * Provides services for Transcripts, including connecting to HusebyConnect REST APIs.
 *
 * @param {*} props
 * @returns
 */
const TranscriptProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const { auth, getIdentity } = React.useContext(AuthContext);

  /**
   * Initialize model.
   *
   * @param {*} eventId
   * @returns
   */
  const initializeModel = async (eventId, isArchive) => {
    console.log("Initializing TranscriptViewer application...");
    console.log("- eventId", eventId);
    console.log("- isArchive", isArchive);

    console.log("Fetching event...");
    const results = await getEvent(eventId);
    const _event = results.event;
    console.log("- event:", _event);

    if (_event.transcripts === 0) {
      setData({ transcripts: 0 });
      // setIsRealTimeTextEnabled(false);
      return;
    }
    setData({ transcripts: 1 });
    setData({ event: _event });

    let _transcript = null;
    try {
      console.log("Fetching transcript from Event... ");
      _transcript = await getTranscriptByEventId(_event.eventId);
      // console.trace(
      //   "TranscriptProvider::initializeModel Transcript found for this Event. ",
      //   _transcript
      // );
    } catch (error) {
      console.log(
        "No transcript associated with this Event.  Creating a transcript record for the first time. "
      );
      _transcript = await createTranscript(_event.eventId);
      // console.trace(
      //   "TranscriptProvider::initializeModel After Create transcript the value of _transcript ",
      //   _transcript
      // );
    }
    // console.log("_transcript", _transcript);
    setData({ myTranscript: _transcript });

    let _transcriptBookmark = null;
    if (!isNil(_transcript))
      _transcriptBookmark = await createTranscriptBookmarks(_transcript.transcriptId);
    await listTranscriptSpeakers(_transcript.transcriptId);

    const _transcriptId = _transcript ? _transcript.transcriptId : -1;
    const _transcriptBookmarkId = _transcriptBookmark
      ? _transcriptBookmark.transcriptBookmarkId
      : -1;
    setData({ myTranscriptBookmarks: _transcriptBookmark });

    // Fetching StreamText Event
    const streamTextEvent = await getStreamTextEvent(eventId);
    if (!isNil(streamTextEvent)) setData({ useTestEvent: streamTextEvent.useTestEvent });

    // Initialize Model
    setData({ isArchive: isArchive });
    setData({ eventId: eventId });
    setData({ event: _event });
    setData({ transcriptId: _transcriptId });
    setData({ transcriptBookmarkId: _transcriptBookmarkId });

    console.log("Getting logged-in user's identity...");
    const identity = JSON.parse(getIdentity());
    const username = identity.username;
    const name = identity.name;
    console.log("Logged-in user's identity", identity);
    setData({ me: identity });

    const myContact = localStorage.getItem("huseby-contact");
    setData({ myContact });
    console.log("Logged-in user's contact info", myContact);

    let _isAdmin = false;
    let _isHost = false;
    try {
      if (
        identity.contactTypeId === 1 ||
        identity.contactTypeId === 2 ||
        identity.contactTypeId === 3
      ) {
        _isAdmin = true;
        _isHost = true;
        setData({ grantViewTranscripts: true });
        setData({ grantSaveTranscripts: true });
      } else {
        _isAdmin = false;
        _isHost = false;
        // Fetching permissions
        let permissions = await getEventParticipant(eventId, identity.contactId);
        if (permissions) {
          setData({ permissions });
          setData({ grantViewTranscripts: permissions.grantViewTranscripts });
          setData({ grantEnableMic: permissions.grantEnableMic });
          setData({ grantSaveTranscripts: permissions.grantSaveTranscripts });
        }
        console.log("- permissions", permissions);
      }

      setData({ isAdmin: _isAdmin });
      setData({ isHost: _isHost });

      const role = _isHost ? ROLE_HOST : ROLE_PARTICIPANT;
      setData({ role });
      setData({ initialized: true });

      // Get room settings
      let _room = await getRoom(eventId);

      // If the state flag is set to TYPE_VTT_TRANSCRIPT_STARTED, then start streaming.
      if (_room.transcriptViewer.state === TYPE_VTT_TRANSCRIPT_STARTED) {
        await getStreamedTranscript(eventId);
      }

      // Set the currentTranscriptStatus
      if (_room.transcriptViewer.state) {
        // setData({ currentTranscriptStatus: _room.transcriptViewer.state });

        const status = getCurrentTranscriptStatusFromSocketState(_room.transcriptViewer.state);
        setData({ currentTranscriptStatus: status });
      }

      // Initialize my zoomUser settings for `zoomUserInRoom` and `zoomMicOn`.
      if (_room.zoomUsers) {
        const entries = Object.entries(_room.zoomUsers);
        const map = new Map(entries);
        const zoomUser = map.get(identity.userId.toString());
        setData({ zoomUserInRoom: zoomUser.isUserInRoom });
        setData({ zoomMicOn: zoomUser.isZoomMicOn });
        setData({ inBreakoutRoom: zoomUser.inBreakoutRoom });
      }
    } catch (error) {
      console.log("error", error);
      // setNotAuthorized(true);
      return;
    }
  };

  /**
   * Get Event by eventId.
   *
   * @param {*} eventId
   * @returns
   */
  const getEvent = async (eventId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}`,
    };
    try {
      setData({ loading: true });
      const { data: eventData } = await http(config);
      setData({ event: eventData });
      return eventData;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Get an event participant.
   */
  const getEventParticipant = async (eventId, contactId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants/${contactId}`,
    };

    try {
      setData({ loading: true });
      const { data: eventParticipant } = await http(config);
      return eventParticipant;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getStreamedTranscript = async (eventId) => {
    let _transcript = await getTranscriptByEventId(eventId);

    streamingTranscriptTimer = setInterval(async () => {
      // if (!data.isStreaming) {
      getArchivedTranscript(_transcript.transcriptId);
      // }
    }, BROADCAST_INTERVAL);

    console.log("XXX streamingTranscriptTimer", streamingTranscriptTimer);
  };

  /**
   * Fetches an archived transcript, which is a transcript that
   * belongs to an Event that has already occurred.  An archived
   * transcript will contain the transcript, bookmarks and notes.
   *
   * @param {*} transcriptId
   */
  const getArchivedTranscript = async (transcriptId) => {
    // Fetch transcripts from REST API
    // console.log("Fetch transcripts from REST API", transcriptId);
    if (transcriptId === -1) return;

    let _transcript = {};
    if (transcriptId != -1) {
      await listTranscriptSpeakers(transcriptId);
      _transcript = await getTranscript(transcriptId);
      // console.trace(
      //   "TranscriptProvider::getArchivedTranscript Checking value for _transcript before pako",
      //   _transcript
      // );
    }

    var pako = require("pako");
    try {
      var result = null;
      if (!isEmpty(_transcript.content)) {
        result = pako.inflate(atob(_transcript.content), { to: "string" });
        // console.trace(
        //   "TranscriptProvider::getArchivedTranscript Checking value for result after pako",
        //   result
        // );
        // console.trace(
        //   "TranscriptProvider::getArchivedTranscript Checking value for data?.colors after pako",
        //   data?.colors
        // );
        const transcriptsData = JSON.parse(result);
        const transcriptLines = transcriptsData.map((tr, index) => {
          tr.bookmark = {};
          tr.colors = data.colors;
          return tr;
        });
        setData({ clear: "transcript" });
        setData({ transcript: transcriptLines });
      } else {
        // console.log("Empty transcript.  Setting to empty array...");
        setData({ clear: "transcript" });
        setData({ transcript: [] });
      }
    } catch (err) {
      console.log("Error while inflating transcript!", err);
    }
  };

  /**
   * Get Transcript for specified transcriptId.
   *
   * @param {*} transcriptId
   * @returns
   */
  const getTranscript = async (transcriptId) => {
    const config = {
      method: "get",
      url: `/hc/transcripts/${transcriptId}`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      // setData({ transcript: data });

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Get Transcript for specified eventId.
   *
   * @param {*} eventId
   * @returns
   */
  const getTranscriptByEventId = async (eventId) => {
    const config = {
      method: "get",
      url: `/hc/transcripts/${eventId}/by-event-id`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ transcript: data });

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Create Transcript.
   *
   * @param {*} eventId
   * @returns
   */
  const createTranscript = async (eventId) => {
    const config = {
      method: "post",
      url: `/hc/transcripts`,
      data: {
        eventId: eventId,
      },
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ transcript: data });
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Save Transcript.
   *
   * @param {*} transcript
   */
  const saveTranscript = async (transcript) => {
    const config = {
      method: "get",
      url: `/hc/transcripts/${transcript.transcriptId}`,
      // data: transcript,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ transcript: data });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Reset Transcript.
   *
   * @param {*} transcriptId
   */
  const resetTranscript = async (transcriptId) => {
    const config = {
      method: "put",
      url: `/hc/transcripts/${transcriptId}/reset`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      // setData({ transcript: data });
      setData({ transcript: [] });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Get Transcript Bookmarks.
   *
   * @param {*} transcriptId
   * @returns
   */
  const getTranscriptBookmarks = async (transcriptId) => {
    console.log("Getting transcript bookmarks...", transcriptId);

    const config = {
      method: "get",
      url: `/hc/transcripts/${transcriptId}/bookmarks`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      const _bookmark = data;
      setData({ transcriptBookmarks: data });

      var pako = require("pako");
      let bookmarks;
      let result;
      let bookmarkMap = {};
      let colorsMap = listDefaultColors();
      if (_bookmark != null) {
        // console.log("XXXXXX _bookmark", _bookmark);
        try {
          result = pako.inflate(atob(_bookmark.data), { to: "string" });
          const bookmarkData = JSON.parse(result);
          // colorsMap = bookmarkData.colors;
          if (bookmarkData.colors && bookmarkData.colors.length > 0) {
            colorsMap = bookmarkData.colors;
          }

          // Map bookmarks
          bookmarks = bookmarkData.bookmarks;
          setData({ bookmarks: bookmarks });
          for (let i = 0; i < bookmarks.length; i++) {
            const index = bookmarks[i].id;
            bookmarkMap[index] = bookmarks[i];
          }
          setData({ colors: colorsMap });

          setData({ clear: "transcriptBookmarks" });
          setData({ transcriptBookmarks: bookmarkMap });
        } catch (err) {
          console.log(err);
          // setColors(listDefaultColors());
          data.colors = listDefaultColors();
          // Set default colors
          setData({ colors: colorsMap });
        }
      } else {
        // Set default colors
        setData({ colors: colorsMap });
      }

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Create Transcript Bookmarks.
   *
   * @param {*} transcriptId
   * @returns
   */
  const createTranscriptBookmarks = async (transcriptId) => {
    const config = {
      method: "post",
      url: `/hc/transcripts/${transcriptId}/bookmarks`,
      data: {
        transcriptId: transcriptId,
      },
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ transcriptBookmarks: data });
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Save Transcript Bookmarks.
   *
   * @param {*} transcriptId
   * @param {*} bookmarks
   */
  const saveTranscriptBookmarks = async (transcriptId, bookmarks) => {
    const config = {
      method: "put",
      url: `/hc/transcripts/${transcriptId}/bookmarks`,
      data: bookmarks,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ transcriptBookmarks: data }); // TODO
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Delete Transcript Bookmarks.
   *
   * @param {*} transcriptBookmarkId
   */
  const deleteTranscriptBookmarks = async (transcriptBookmarkId) => {
    const config = {
      method: "delete",
      url: `/hc/transcripts/bookmarks/${transcriptBookmarkId}`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({ transcriptBookmarks: data });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * List Event Participants.   This is used for displaying recording permissions.
   */
  const listEventParticipants = async (
    eventId,
    searchQuery = "",
    pageNumber = 0,
    pageLength = 15,
    sortParam = "contact.firstName:asc"
  ) => {
    console.log("TranscriptService.listEventParticipants()", eventId);
    try {
      const config = {
        method: "get",
        url: `/hc/events/${eventId}/participants?pageLength=${pageLength}&pageIndex=${pageNumber}&sortParam=${sortParam}`, //&${urlParams}`,`,
      };
      if (searchQuery) {
        config.url += `&firstName=${searchQuery}&lastName=${searchQuery}&email=${searchQuery}`;
      }

      const { data: participantData } = await http(config);
      setData({ loading: false, participants: participantData });
      console.log("TranscriptService.listEventParticipants participant data is", participantData);
      return participantData;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  /**
   * Update Event Participants.  This is used for updating recording permissions.
   */
  const updateEventParticipants = async (eventId, eventParticipants) => {
    console.log("TranscriptService.updateEventParticipants()", eventId, eventParticipants);
    const config = {
      method: "put",
      url: `/hc/events/${eventId}/participants`,
      data: eventParticipants,
    };
    try {
      const { data: participants } = await http(config);
      setData({ loading: false, participants });
      return participants;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  /**
   * Get the StreamText Event.
   *
   * @param {*} eventId
   * @returns
   */
  const getStreamTextEvent = async (eventId) => {
    const config = {
      method: "get",
      url: `/st/events/by-hceventId?hcEventId=${eventId}`,
    };

    try {
      const { data } = await http(config);
      setData({ useTestEvent: data.useTestEvent });
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Start the Transcript Stream.
   *
   * @param {*} eventId
   * @param {*} useTestEvent
   * @returns
   */
  const startTranscriptStream = async (eventId, useTestEvent) => {
    console.log("startTranscriptStream...", eventId, useTestEvent);

    const config = {
      method: "post",
      url: `/st/events/${eventId}/transcripts/start?useTestEvent=${useTestEvent}`,
      data: {},
    };

    try {
      setData({ loading: true });
      const { data: transcript } = await http(config);
      return transcript;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Stop the Transcript Stream.
   *
   * @param {*} eventId
   * @returns
   */
  const stopTranscriptStream = async (eventId) => {
    console.log("stopTranscriptStream...", eventId, streamingTranscriptTimer);
    clearInterval(streamingTranscriptTimer);

    const config = {
      method: "post",
      url: `/st/events/${eventId}/transcripts/stop`,
      data: {},
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const listTranscriptSpeakers = async (transcriptId) => {
    const config = {
      method: "get",
      url: `/st/${transcriptId}/speakers`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      setData({
        transcriptSpeakerMapping: data,
      });
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const updateTranscriptSpeaker = async (transcriptId, mappedSpeakerData) => {
    console.log({ transcriptId, mappedSpeakerData });

    //     "transcriptId": 11407,
    //     "speakerIndex": 0,
    //     "speakerName": "Jane Smith",
    //     "color": "0xFFFF00"
    // }
    // /st/{transcriptId}/speakers/{speakerId}

    mappedSpeakerData.forEach(async (data) => {
      const config = {
        method: isNaN(data.id) ? "put" : "post",
        url: isNaN(data.id)
          ? `/st/${transcriptId}/speakers/${data.id}`
          : `/st/${transcriptId}/speakers`,
        data: {
          transcriptId: transcriptId,
          speakerIndex: data.speaker,
          speakerName: data.speakerName,
          color: data.color,
        },
      };

      try {
        setData({ loading: true });
        const { data } = await http(config);
        // setData({ transcript: data });
        // setData({
        //   transcriptSpeakerMapping: data
        // });
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => setData({ loading: false }), DELAY);
        await listTranscriptSpeakers(transcriptId);
      }
    });
  };

  const getTranscriptSpeaker = (speakerIndex) => {
    // /st/{transcriptId}/speakers/{speakerId}

    console.log({ speakerIndex });
  };

  const deleteTranscriptSpeaker = (speakerIndex) => {
    console.log({ speakerIndex });
  };

  const createTranscriptSpeaker = (transcriptId) => {
    // /st/{transcriptId}/speakers
  };

  return (
    <TranscriptContext.Provider
      value={{
        data,
        setData,
        initializeModel,
        getEvent,
        getStreamedTranscript,
        getArchivedTranscript,
        getTranscript,
        getTranscriptByEventId,
        getTranscriptBookmarks,
        createTranscript,
        saveTranscript,
        resetTranscript,
        createTranscriptBookmarks,
        saveTranscriptBookmarks,
        deleteTranscriptBookmarks,
        listEventParticipants,
        updateEventParticipants,
        startTranscriptStream,
        stopTranscriptStream,
        listTranscriptSpeakers,
        updateTranscriptSpeaker,
        getTranscriptSpeaker,
        deleteTranscriptSpeaker,
      }}
    >
      {props.children}
    </TranscriptContext.Provider>
  );
};

export { TranscriptContext, TranscriptProvider };
