/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import axios from "axios";
import { camelCase, find, forEach, merge } from "lodash";
import hmacSHA256 from "crypto-js/hmac-sha256";
import encBase64 from "crypto-js/enc-base64";
import { Base64 } from "js-base64";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = [];

const ZoomMeetingContext = React.createContext();

const ZoomMeetingProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);

  const joinEventMeeting = async (eventId, userName, userEmail, role) => {
    console.log("joinEventMeeting", eventId, role);
    const config = {
      method: "post",
      url: `/zoom/meetings/event/${eventId}/join`,
      data: {
        userName: userName,
        userEmail: userEmail,
        role: role,
      },
    };

    try {
      setData({ loading: true });
      const { data: response } = await http(config);
      console.log("XXX joinZoomMeetingResponse", response);
      setData({ meeting: response["zoom_meeting"] });
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const endEventMeeting = async (eventId) => {
    console.log("endEventMeeting", eventId);
    const config = {
      method: "post",
      url: `/zoom/meetings/event/${eventId}/end`,
    };

    try {
      setData({ loading: true });
      const { data } = await http(config);
      console.log("XXX endZoomMeetingResponse", response);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getMeetingByHcEventId = async (eventId) => {
    console.log("getMeetingByHcEventId", eventId);
    const config = {
      method: "get",
      url: `/zoom/meetings/event-id/${eventId}`,
    };
    try {
      setData({ loading: true });
      console.log("");
      const { data: meetingData } = await http(config);
      setData({ meeting: meetingData });
      return meetingData;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const generateSignature = async (meetingNumber, role) => {
    try {
      const config = {
        method: "post",
        url: `/zoom/meetings/${meetingNumber}/signature?role=${role}`,
      };
      try {
        setData({ loading: true });
        const { data: signature } = await http(config);
        // console.log("XXX data.signature", signature.signature);
        setData({ signature: signature.signature });
        return signature.signature;
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => setData({ loading: false }), DELAY);
      }

      // if (props?.success) {
      //   props.success(signature);
      // }
      return signature;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ZoomMeetingContext.Provider
      value={{
        data,
        setData,
        getMeetingByHcEventId,
        generateSignature,
        joinEventMeeting,
        endEventMeeting,
      }}
    >
      {props.children}
    </ZoomMeetingContext.Provider>
  );
};

const useZoomMeetingService = () => React.useContext(ZoomMeetingContext);

export { ZoomMeetingContext, ZoomMeetingProvider, useZoomMeetingService };
