/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { useStyles as useExhibitStyles } from "../exhibits.styles.js";
import { CRXDialogTitle } from "@cirrux888/huseby-client-auth";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    width: "100%",
    margin: 2,
    marginRight: 10
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

export const PublishExhibitConfirmationDialog = ({
  open,
  publishing = "not-started",
  onYesClick,
  onNoClick
}) => {
  const exhibitClasses = useExhibitStyles();

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <CRXDialogTitle onClick={onNoClick} title="Publish Exhibit" />
      <DialogContent>
        <DialogContentText className={exhibitClasses.dialogContent}>
          {publishing === "not-started" && (
            <span>
              Are you sure you are finished annotating the exhibit? This
              document will become non-editable upon save.
            </span>
          )}

          {publishing !== "not-started" && (
            <span>
              {publishing === "in-progress" && (
                <span>Your exhibit is being published...</span>
              )}

              {publishing === "complete" && (
                <span>Your exhibit has been published.</span>
              )}

              <Box
                width={1}
                my={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <BorderLinearProgress
                  variant={publishing ? "determinate" : "indeterminate"}
                />
                {publishing === "complete" && (
                  <CheckIcon style={{ color: "green" }} />
                )}
                {publishing === "in-progress" && <CircularProgress size={20} />}
                {publishing === "error" && (
                  <ErrorIcon style={{ color: "red" }} />
                )}
              </Box>
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {publishing === "not-started" && (
          <>
            <Button
              color="primary"
              variant="contained"
              disabled={publishing === "complete"}
              onClick={onYesClick}
            >
              Yes
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={publishing === "complete"}
              onClick={onNoClick}
            >
              No
            </Button>
          </>
        )}

        {publishing !== "not-started" && (
          <>
            <Button
              color="primary"
              variant="contained"
              disabled={publishing !== "complete" && publishing !== "error"}
              onClick={onNoClick}
            >
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const CloseConfirmation = ({
  open,
  handleClose,
  onYesClick,
  onNoClick
}) => {
  const exhibitClasses = useExhibitStyles();

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <CRXDialogTitle onClick={onNoClick} title="Exhibit Editor" />
      <DialogContent>
        <DialogContentText>
          Do you really want to close this pdf file?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onYesClick}>
          Yes
        </Button>
        <Button color="primary" variant="contained" onClick={onNoClick}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddExhibitStampAlert = ({ open, onOkClick }) => {
  const exhibitClasses = useExhibitStyles();

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <CRXDialogTitle onClick={onOkClick} title="Exhibit Editor" />
      <DialogContent>
        <DialogContentText className={exhibitClasses.dialogContent}>
          Please add an exhibit stamp.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onOkClick}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
