/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/
import axios from "axios";
import { get } from "lodash";
import logger from "./LogService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const tokenKey = "huseby-token";
const identityKey = "huseby-identity";
const refreshTokenKey = "huseby-refresh";

instance.interceptors.response.use(null, async (error) => {
  const status = get(error, ["response", "status"], 511);
  const expectedError = status >= 400 && status < 500;

  if (status === 401) {
    logger.log(error);
    console.error(new Error(status));
    try {
      // Refresh the JWT
      await refreshJwt();

      /// Retry the original request
      console.log("Retrying the original request...", error);
      return await instance({
        method: error.response.config.method,
        url: error.response.config.url,
        data: error.response.config.data,
        headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
      });
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        console.log(
          "Error while trying to refresh token and retry original request...",
          error.response.data.message
        );
        console.log("Redirecting to login screen...");
        // Need to use location.href since history.push does not work.
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(identityKey);
        localStorage.removeItem(refreshTokenKey);
        location.replace("/login");
      }
    }
  }

  if (!expectedError) {
    logger.log(error);
    console.error(new Error(status));
  }

  return Promise.reject(error);
});

const refreshJwt = async () => {
  /// refreshJwt
  console.log("Token expired while sending http request.");
  console.log("Refreshing token now...");
  let refreshToken = localStorage.getItem("huseby-refresh");
  const config = {
    method: "get",
    url: "/auth/refresh-token",
    headers: { RefreshToken: `Bearer ${refreshToken}` },
  };

  const { data } = await instance(config);
  // console.log("Refresh tokens", data);
  const refreshedAuth = {
    token: data.token,
    identity: JSON.stringify(data.user),
    refreshToken: data.refreshToken,
  };
  localStorage.setItem(tokenKey, refreshedAuth.token);
  localStorage.setItem(identityKey, refreshedAuth.identity);
  localStorage.setItem(refreshTokenKey, refreshedAuth.refreshToken);
  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${refreshedAuth.token}`;
  ///
};

export { instance };
