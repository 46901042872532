/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "./HttpService";
import { isEmpty, isNil, merge } from "lodash";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import Cookies from "js-cookie";

let reducer = (data, newData) => {
  return { ...merge(data, newData) };
};

const tokenKey = "huseby-token";
const identityKey = "huseby-identity";
const contactKey = "huseby-contact";
const refreshTokenKey = "huseby-refresh";

const initialState = {
  loading: true,
};
const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [auth, setAuth] = React.useReducer(reducer, initialState);
  const history = useHistory();

  /**
   *
   * @returns The identity object of the authenticated user.
   *
   */
  const getIdentity = () => {
    return localStorage.getItem(identityKey);
  };

  /**
   * Check if a user is authenticated.
   *
   * We want to check authentication on a PrivateRoute according to the following rules:
   *  if a token is passed in parameter, attempt to authenticate with this token.  Ideally, we
   *   should be passing in this token in the http header.
   *    - if no token is passed in parameter/http header, then check localStorage
   *    - if no token is found in localStorage, then logout and redirect user to /login screen.
   *    - else if token is found in localStorage, then check if valid
   *        - if token is expired, then refresh with refreshToken.
   *             update jwtToken, refreshToken
   *         - if token is not valid, then logout and redirect user to login screen.
   *       - if token is valid, then continue
   *
   * @param {*} query
   * @returns
   */
  const isAuthenticated = async (query) => {
    let loggedIn = false;

    // Check if a token is passed over in the query string.
    // This should be deprecated soon since we will not allow this
    // as its not secure to pass over token in the query.
    if (!isEmpty(query.get("token"))) {
      try {
        console.log("Found token in parameter.");
        console.log("Attempting to login with token...", query.get("token"));
        loggedIn = await loginJwt(query.get("token"));
        console.log("loggedIn", loggedIn);
        return true;
      } catch (error) {
        console.log("Refresh token expired.", error);
        console.log("Redirecting to login screen...");
        logout();
        return false;
      }
    }

    // No token in parameter, just check localStorage
    console.log("No token in parameter, just check localStorage...");
    let token = localStorage.getItem(tokenKey);
    let identity = localStorage.getItem(identityKey);
    let refreshToken = localStorage.getItem(refreshTokenKey);

    // Log if the token is empty.
    if (isEmpty(token) || isEmpty(identity)) {
      console.log("No token found in the parameter and localStorage.");
    }

    // If token is found, then check the expiration time on the token.
    let isExpired = false;
    if (token) {
      // Token expiry time is encoded in the token in UTC time format. So it
      // can be fetched and checked manually against current time in UTC.
      console.log("Found token.  Checking the expiration date on the token...");
      const expiry = JSON.parse(atob(token.split(".")[1])).exp;
      isExpired = Math.floor(new Date().getTime() / 1000) >= expiry;
      if (isExpired) {
        try {
          console.log(
            "Token expired.  Refreshing expired token...",
            refreshToken
          );
          await refreshJwt(refreshToken);
          console.log("Token refreshed.");
        } catch (error) {
          console.log("Refresh token expired also.", error);
          // logout();
          // return false;
        }
      }
    }

    // If no token is found in parameter and localStorage, check the cookies.  This is
    // the backup if we are trying to launch apps across subdomains, which happens in
    // the Meeting app.
    if (isEmpty(token) || isEmpty(identity) || isExpired) {
      console.log("Checking the cookies for valid session...");
      token = Cookies.get(tokenKey);
      identity = Cookies.get(identityKey);
      refreshToken = Cookies.get(refreshTokenKey);
      if (!isEmpty(token)) console.log(`Found session cookie: ${token}`);

      if (!isNil(token)) {
        console.log(
          "Setting 'token' value from cookie values to localStorage."
        );
        localStorage.setItem(tokenKey, token);
      }
      if (!isNil(identity)) localStorage.setItem(identityKey, identity);
      if (!isNil(refreshToken))
        localStorage.setItem(refreshTokenKey, refreshToken);
    }

    // if no token is found in localStorage, then logout and redirect user to login screen.
    if (isEmpty(token) || isEmpty(identity)) {
      console.log("No token or identity found.");
      return false;
    }

    token = localStorage.getItem(tokenKey);
    console.log("User is authenticated.");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return true;
  };

  /**
   *
   */
  const authenticate = async () => {
    console.log("Authenticating...");
    try {
      let token = localStorage.getItem(tokenKey);
      let identity = JSON.parse(localStorage.getItem(identityKey));
      let refreshToken = localStorage.getItem(refreshTokenKey);
      if (token === null || identity === null) {
        console.log(
          "No token or identity found.  Redirecting to Login screen."
        );

        logout();
      }

      if (auth.token) {
        // Link from active session
      } else if (token) {
        // Refresh from active session
        // TODO: check if JWT token is expired
      } else if (refreshToken) {
        // JWT has expired
        token = await refreshJWT(refreshToken);
      } else {
        // No active session
        throw new Error("No active session found.");
      }
      console.log("Session established.");
      http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setAuth({ token, identity, refreshToken });
    } catch (e) {
      console.error(e.message);
      logout();
    }
  };

  /**
   * Set the login cookies.  This is used as a backup because we cannot access
   * localStorage across subdomains and localStorage is used to store authentication
   * tokens.
   *
   * @param {*} authTokenVal
   * @param {*} identityVal
   * @param {*} refreshTokenVal
   */
  const setLoginCookies = (authTokenVal, identityVal, refreshTokenVal) => {
    console.log("Set the login cookies...");
    console.log(
      `Setting default cookie attributes for domain: ${process.env.REACT_APP_COOKIE_DOMAIN}`
    );
    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
    Cookies.set(tokenKey, authTokenVal, { path: "/", domain: cookieDomain });
    Cookies.set(identityKey, identityVal, { path: "/", domain: cookieDomain });
    Cookies.set(refreshTokenKey, refreshTokenVal, {
      path: "/",
      domain: cookieDomain,
    });
    console.log("Login cookies set.");
  };

  /**
   * Login the user into HusebyConnect.
   *
   * @param {*} username
   * @param {*} password
   */
  const login = async (username, password) => {
    const { data } = await http.post("/auth/login", {
      username,
      password,
    });
    const newAuth = {
      token: data.token,
      identity: JSON.stringify(data.user),
      refreshToken: data.refreshToken,
    };
    setAuth({ ...newAuth });

    localStorage.setItem(tokenKey, newAuth.token);
    localStorage.setItem(identityKey, newAuth.identity);
    localStorage.setItem(refreshTokenKey, newAuth.refreshToken);
    setLoginCookies(newAuth.token, newAuth.identity, newAuth.refreshToken);
    authenticate();

    // Need to call authenticated REST API here since 'Authorization' header is now set.
    const myContact = await getContact(data.user.contactId);
    localStorage.setItem(contactKey, JSON.stringify(myContact));
  };

  /**
   * Login the user into HusebyConnect with the JWT token.
   *
   * @param {*} token
   * @returns
   */
  const loginJwt = async (token) => {
    try {
      const { data } = await http.post("/auth/login", {
        token,
      });
      const newAuth = {
        token: data.token,
        identity: JSON.stringify(data.user),
        refreshToken: data.refreshToken,
      };
      setAuth({ ...newAuth });
      localStorage.setItem(tokenKey, newAuth.token);
      localStorage.setItem(identityKey, newAuth.identity);
      localStorage.setItem(refreshTokenKey, newAuth.refreshToken);
      setLoginCookies(newAuth.token, newAuth.identity, newAuth.refreshToken);
      authenticate();
      return true;
    } catch (error) {
      console.log("Could not authenticate with token.", error);
      return false;
    }
  };

  /**
   * Log the user out of HusebyConnect.
   */
  const logout = () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(identityKey);
    localStorage.removeItem(refreshTokenKey);
    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
    Cookies.remove(tokenKey, { path: "/", domain: cookieDomain });
    Cookies.remove(identityKey, { path: "/", domain: cookieDomain });
    Cookies.remove(refreshTokenKey, { path: "/", domain: cookieDomain });
    // history.push("/login");
  };

  /**
   *
   * @param {*} refreshToken
   */
  const refreshJwt = async (refreshToken) => {
    if (refreshToken === undefined) history.push("/login");

    const config = {
      method: "get",
      url: "auth/refresh-token",
      headers: { RefreshToken: `Bearer ${refreshToken}` },
    };

    const { data } = await http(config);
    const refreshedAuth = {
      token: data.token,
      identity: JSON.stringify(data.user),
      refreshToken: data.refreshToken,
    };
    setAuth({ ...refreshedAuth });
    localStorage.setItem(tokenKey, refreshedAuth.token);
    localStorage.setItem(identityKey, refreshedAuth.identity);
    localStorage.setItem(refreshTokenKey, refreshedAuth.refreshToken);
    setLoginCookies(
      refreshedAuth.token,
      refreshedAuth.identity,
      refreshedAuth.refreshToken
    );
    http.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
  };

  /**
   *
   * @param {*} username
   */
  const forgotPassword = async (username) => {
    await http.get("auth/forgot-password", {
      params: { email: username },
    });
  };

  /**
   *
   * @param {*} activationCode
   */
  const getByActivationCode = async (activationCode) => {
    const config = {
      method: "get",
      url: `/auth/activation-code?code=${activationCode}`,
    };
    try {
      const { data: myContact } = await http(config);
      console.log("myContact", myContact);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   *
   * @param {*} username
   * @param {*} oldPassword
   * @param {*} newPassword
   * @param {*} verifyPassword
   * @returns
   */
  const resetPassword = async (
    username,
    oldPassword,
    newPassword,
    verifyPassword
  ) => {
    const config = {
      method: "post",
      url: `/auth/reset-password`,
      data: {
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword,
        verifyPassword: verifyPassword,
      },
    };
    try {
      const { data } = await http(config);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   *
   * @param {*} newPassword
   * @param {*} activationCode
   */
  const resetPasswordByActivationCode = async (newPassword, activationCode) => {
    const config = {
      method: "post",
      url: `/auth/reset-password-by-activation-code`,
      data: {
        code: activationCode,
        password: newPassword,
        verifyPassword: newPassword,
      },
    };

    await http(config);
  };

  /**
   * Get the guest login settings that are used to construct the login screen.
   *
   * @param {*} param0
   * @returns
   */
  const getGuestLoginSettings = async ({ meetingUrl }) => {
    console.log("XXXXX getGuestLoginSettings", meetingUrl);
    try {
      const config = {
        method: "get",
        url: `/hc/events/guest/loginSettings?meetingUrl=${meetingUrl}`,
      };
      const { data: guestLoginSettings } = await http(config);
      return guestLoginSettings;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  /**
   * Register a User for an event with a token.   For now, the token is
   * just the username and a passcode that is generated from HusebyConnect server.
   *
   * @param {*} param0
   */
  const registerUserWithToken = async ({
    username = null,
    meetingUrl = null,
    passcode = null,
  }) => {
    console.log(
      "Registering user with token...",
      username,
      meetingUrl,
      passcode
    );

    const config = {
      method: "post",
      url: `/hc/events/registerEvent`,
      data: {
        username: username,
        meetingUrl: meetingUrl,
        password: Base64.decode(passcode),
      },
    };

    const { data } = await http(config);
    const newAuth = {
      token: data.token,
      identity: JSON.stringify(data.user),
      refreshToken: data.refreshToken,
    };
    setAuth({ ...newAuth });

    localStorage.setItem(tokenKey, newAuth.token);
    localStorage.setItem(identityKey, newAuth.identity);
    localStorage.setItem(refreshTokenKey, newAuth.refreshToken);
    setLoginCookies(newAuth.token, newAuth.identity, newAuth.refreshToken);
    authenticate();
  };

  /**
   * Register a Guest user for an event.
   *
   * @param {*} param0
   */
  const registerGuest = async ({
    firstName = null,
    lastName = null,
    email = null,
    username = null,
    meetingUrl = null,
  }) => {
    console.log("XXX guestLogin", firstName, lastName, email, meetingUrl);

    const config = {
      method: "post",
      url: `/hc/events/registerEvent`,
      data: {
        firstName: firstName,
        lastName: lastName,
        username: username,
        email: email,
        meetingUrl: meetingUrl,
      },
    };
    console.log("XXX config", config);

    const { data } = await http(config);
    const newAuth = {
      token: data.token,
      identity: JSON.stringify(data.user),
      refreshToken: data.refreshToken,
    };
    setAuth({ ...newAuth });

    localStorage.setItem(tokenKey, newAuth.token);
    localStorage.setItem(identityKey, newAuth.identity);
    localStorage.setItem(refreshTokenKey, newAuth.refreshToken);
    setLoginCookies(newAuth.token, newAuth.identity, newAuth.refreshToken);
    authenticate();
  };

  /**
   * Get Contact by contactId.
   *
   * @param {*} contactId
   * @returns
   */
  const getContact = async (contactId) => {
    const config = {
      method: "get",
      url: `/hc/contacts/${contactId}`,
    };
    try {
      const { data: contact } = await http(config);
      return contact;
    } catch (error) {
      console.error("error", error);
      // throw error;
    }
  };

  /**
   *
   */
  const testApi = async () => {
    const config = {
      method: "get",
      url: `/hc/events/20950`,
    };

    try {
      let { data } = await http(config);
      console.log("data", data);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   *
   * @returns
   */
  const isAdminMode = () => {
    let identity = JSON.parse(localStorage.getItem(identityKey));

    if (process.env.REACT_APP_URL.includes("localhost")) return true;
    if (identity === null) return false;

    // return (
    //   identity.contactTypeId === ContactTypeEnum.FirmMember ||
    //   identity.contactTypeId === ContactTypeEnum.SuperAdmin
    // );
    // console.log("XXX isAdminMode", identity.contactTypeId === ContactTypeEnum.FirmMember);
    // return (
    //   identity.contactTypeId === ContactTypeEnum.FirmMember
    //   // identity.contactTypeId === ContactTypeEnum.SuperAdmin
    // );

    // QW-XXX: Hardcode isAdminMode() to always return true for now.
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        isAuthenticated,
        authenticate,
        registerUserWithToken,
        registerGuest,
        getGuestLoginSettings,
        loginJwt,
        login,
        refreshJwt,
        setLoginCookies,
        logout,
        forgotPassword,
        getByActivationCode,
        resetPassword,
        resetPasswordByActivationCode,
        testApi,
        isAdminMode,
        getIdentity,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuthService = () => React.useContext(AuthContext);

export { AuthContext, AuthProvider, useAuthService };
