import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DataGridPro, GridPagination } from "@mui/x-data-grid-pro";
import { makeStyles } from "@mui/styles";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import { VOICETOTEXT_TRANSCRIPTS_ENABLED } from "../../../model/Model";
import { TranscriptContext } from "../../../services/TranscriptService";
import { isNil } from "lodash";
import { emitPermissionsChanged } from "../../../services/SocketIOService";
import SearchBar from "../../../common/SearchBar";

const LIST_PAGE_LENGTH = 15;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& .header-with-checkbox": {
      "& .MuiDataGrid-columnHeaderTitleContainer ": {
        paddingLeft: 0,
      },
    },
  },
  container: {
    height: "calc(100vh - 250px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "4px",
  },
  buttonBar: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recordingListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "calc(100vh - 85px)", // 8px from padding
  },
}));

const TranscriptPermissionsDialog = ({ open, handleClose, onSuccess }) => {
  // console.log('Inside TranscriptPermissionsDialog eventId is',eventId);

  const classes = useStyles();
  const { data, listEventParticipants, updateEventParticipants } = useContext(TranscriptContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [pagination, setPagination] = useState(0);
  const [sortModel, setSortModel] = useState([
    {
      field: "eventParticipantType.eventParticipantTypeId",
      sort: "asc",
    },
  ]);
  const [sortParam, setSortParam] = useState("eventParticipantType.eventParticipantTypeId:asc");
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [loading, setLoading] = useState();
  const [isViewAll, setIsViewAll] = useState(false);
  const [isEnableMicAll, setIsEnableMicAll] = useState(false);
  const [isSaveAll, setIsSaveAll] = useState(false);

  // Transcript permissions columns
  const permissionsColumns = [
    {
      field: "contact.contactId",
      headerName: "Name",
      minWidth: 400,
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        const fullName = `${params.row.contact.firstName} ${params.row.contact.lastName}   (${params.row.contact.email})`;
        return fullName;
      },
    },
    // {
    //   field: "contact.email",
    //   headerName: "Email",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   disableClickEventBubbling: true,
    //   valueGetter: (params) => {
    //     return params.row.contact.email;
    //   },
    // },
    {
      field: "eventParticipantType.eventParticipantType",
      headerName: "Type",
      width: 150,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return params.row.eventParticipantType.eventParticipantType;
      },
    },
    {
      field: "grantViewTranscripts",
      headerName: "View",
      width: 100,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      headerClassName: "header-with-checkbox",
      renderHeader: (params) => {
        return (
          <span>
            <Checkbox color="primary" checked={isViewAll} onChange={handleViewAll} />
            View
          </span>
        );
      },
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantViewTranscripts}
              disabled={params.row.eventParticipantType.eventParticipantType === "Internal User"}
              onChange={(e) =>
                handleViewTranscriptsChecked(params.row.eventParticipantId, e.target)
              }
            />
          </span>
        );
      },
    },
  ];

  if (data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED) {
    permissionsColumns.push({
      field: "grantEnableMic",
      headerName: "Mic",
      width: 100,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      headerClassName: "header-with-checkbox",
      renderHeader: (params) => {
        return (
          <span>
            <Checkbox color="primary" checked={isEnableMicAll} onChange={handleEnableMicAll} />
            Mic
          </span>
        );
      },
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantEnableMic}
              disabled={params.row.eventParticipantType.eventParticipantType === "Internal User"}
              onChange={(e) =>
                handleEnableMicChecked(params.row.eventParticipantId, e.currentTarget)
              }
            />
          </span>
        );
      },
    });
  }

  permissionsColumns.push({
    field: "grantSaveTranscripts",
    headerName: "Manage",
    width: 100,
    disableColumnMenu: true,
    disableClickEventBubbling: true,
    sortable: false,
    headerClassName: "header-with-checkbox",
    renderHeader: (params) => {
      return (
        <span>
          <Checkbox color="primary" checked={isSaveAll} onChange={handleSaveAll} />
          Manage
        </span>
      );
    },
    renderCell: (params) => {
      return (
        <span>
          <Checkbox
            color="primary"
            checked={params.row.grantSaveTranscripts}
            disabled={params.row.eventParticipantType.eventParticipantType === "Internal User"}
            onChange={(e) =>
              handleSaveTranscriptsChecked(params.row.eventParticipantId, e.currentTarget)
            }
          />
        </span>
      );
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const eventParticipants = await listEventParticipants(
          data.eventId,
          searchQuery,
          pageIndex,
          pageLength,
          sortParam
        );

        setFilteredParticipants(eventParticipants.content);
        setPagination(eventParticipants.totalElements);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageIndex, pageLength, searchQuery, sortModel]);

  useEffect(() => {
    console.log("Inside TranscriptPermissions useEffect", open, data.eventId);
    if (isNil(open) || open === "false") return;

    const fetchData = async () => {
      console.log("Inside TranscriptPermissions fetchData");
      try {
        setLoading(true);
        if (sortModel?.sortModel?.length > 0) {
          const _sortParam = `${sortModel.sortModel[0].field}:${sortModel.sortModel[0].sort}`;
          setSortParam(_sortParam);
        }
        const eventParticipants = await listEventParticipants(
          data.eventId,
          searchQuery,
          pageIndex,
          pageLength,
          sortParam
        );
        setFilteredParticipants(eventParticipants.content);
        setIsViewAll(!eventParticipants.content.some((p) => p.grantViewTranscripts === false));
        setIsSaveAll(!eventParticipants.content.some((p) => p.grantSaveTranscripts === false));
        setPagination(eventParticipants.totalElements);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [open]);

  useEffect(() => {
    // console.log('Inside TranscriptPermissions useEffect 2');
    const fetchData = async () => {
      // console.log('Inside TranscriptPermissions fetchData 2');
      try {
        setLoading(true);
        if (sortModel?.sortModel?.length > 0) {
          const _sortParam = `${sortModel.sortModel[0].field}:${sortModel.sortModel[0].sort}`;
          setSortParam(_sortParam);
        }
        const eventParticipants = await listEventParticipants(
          data.eventId,
          searchQuery,
          pageIndex,
          pageLength,
          sortParam
        );
        setFilteredParticipants(eventParticipants.content);
        setPagination(eventParticipants.totalElements);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [searchQuery]);

  /**
   *
   * @param {*} eventParticipantId
   * @param {*} currentTarget
   */
  const handleViewTranscriptsChecked = (eventParticipantId, currentTarget) => {
    console.log("handleViewTranscriptsChecked", eventParticipantId, currentTarget.checked);
    let isAllChecked = true;
    const participants = filteredParticipants.map((p) => {
      if (p.eventParticipantId === eventParticipantId) {
        p.grantViewTranscripts = currentTarget.checked;
      }
      isAllChecked = isAllChecked && p.grantViewTranscripts;
      return p;
    });
    setIsViewAll(isAllChecked);
    setFilteredParticipants(participants);
  };

  /**
   *
   * @param {*} eventParticipantId
   * @param {*} currentTarget
   */
  const handleEnableMicChecked = (eventParticipantId, currentTarget) => {
    console.log("handleEnableMicChecked", eventParticipantId, currentTarget.checked);
    let isAllChecked = true;
    const participants = filteredParticipants.map((p) => {
      if (p.eventParticipantId === eventParticipantId) {
        p.grantEnableMic = currentTarget.checked;
      }
      isAllChecked = isAllChecked && p.grantEnableMic;
      return p;
    });
    setIsEnableMicAll(isAllChecked);
    setFilteredParticipants(participants);
  };

  /**
   *
   * @param {*} eventParticipantId
   * @param {*} currentTarget
   */
  const handleSaveTranscriptsChecked = (eventParticipantId, currentTarget) => {
    console.log("handleSaveTranscriptsChecked", eventParticipantId, currentTarget.checked);
    let isAllChecked = true;
    const participants = filteredParticipants.map((p) => {
      if (p.eventParticipantId === eventParticipantId) {
        p.grantSaveTranscripts = currentTarget.checked;
      }
      isAllChecked = isAllChecked && p.grantSaveTranscripts;
      return p;
    });
    setIsSaveAll(isAllChecked);
    setFilteredParticipants(participants);
  };

  /**
   *
   * @param {*} newModel
   */
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  /**
   *
   */
  const handleSavePermissions = async () => {
    try {
      setLoading(true);
      await updateEventParticipants(data.eventId, filteredParticipants);

      emitPermissionsChanged(data.eventId, filteredParticipants);

      handleClose();
      onSuccess({
        severity: "success",
        message: `The transcript permissions have been successfully updated.`,
      });
    } catch (error) {
      console.log("Unexpected error while saving event participant permissions...", error);
      // onError({
      //   severity: "error",
      //   message: `There was an unexpected error while saving recording permissions.`,
      // });
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  /**
   *
   * @param {*} e
   */
  const handleViewAll = (e) => {
    console.log("Inside handleViewAll");
    const participants = filteredParticipants.map((row) => {
      if (row.eventParticipantType.eventParticipantType !== "Internal User") {
        row.grantViewTranscripts = e.target.checked;
        return row;
      }
      return row;
    });
    console.log("Inside handleViewAll data after update is", participants);
    setFilteredParticipants(participants);
    setIsViewAll(e.target.checked);
  };

  /**
   *
   * @param {*} e
   */
  const handleEnableMicAll = (e) => {
    console.log("Inside handleEnableMicAll");
    const participants = filteredParticipants.map((row) => {
      if (row.eventParticipantType.eventParticipantType !== "Internal User") {
        row.grantEnableMic = e.target.checked;
        console.log("XXXXXX row.grantEnableMic", row.grantEnableMic);
        return row;
      }
      return row;
    });

    console.log("Inside handleEnableMic data after update is", participants);
    setFilteredParticipants(participants);
    setIsEnableMicAll(e.target.checked);
  };

  /**
   *
   * @param {*} e
   */
  const handleSaveAll = (e) => {
    console.log("Inside handleSaveAll");
    const participants = filteredParticipants.map((row) => {
      if (row.eventParticipantType.eventParticipantType !== "Internal User") {
        row.grantSaveTranscripts = e.target.checked;
        return row;
      }
      return row;
    });

    console.log("Inside handleSaveAll data after update is", participants);
    setFilteredParticipants(participants);
    setIsSaveAll(e.target.checked);
  };

  /**
   * Handles the page change event in the DataGridPro.
   *
   * This function is triggered when the user navigates to a different page
   * using the pagination controls (Next/Previous buttons). It updates the
   * current page index to load the data for the selected page.
   *
   * @param {number} newPage - The index of the newly selected page.
   */
  const handlePageChange = (newPage) => {
    setPageIndex(newPage);
  };

  /**
   * Handles the page size change event in the DataGridPro.
   *
   * This function is triggered when the user selects a different number of
   * rows to be displayed per page. It updates the page length to reflect
   * the selected page size.
   *
   * @param {number} newPageSize - The newly selected page size.
   */
  const handlePageSizeChange = (newPageSize) => {
    setPageLength(newPageSize);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        className={classes.root}
        disableEnforceFocus
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSavePermissions(e);
          }}
        >
          <CRXDialogTitle onClick={handleClose} title="Transcript Permissions" />

          <DialogContent>
            <Box className={classes.container}>
              <Box className={classes.buttonBar}>
                <Box width={1} display="flex" flexDirection="row" justifyContent="flex-end">
                  <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
                </Box>
              </Box>

              <Box className={classes.recordingListContainer}>
                <DataGridPro
                  className={classes.paper}
                  rows={filteredParticipants}
                  columns={permissionsColumns}
                  rowHeight={48}
                  pagination
                  paginationMode="server"
                  pageSize={pageLength}
                  rowCount={pagination}
                  page={pageIndex}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
                  disableSelectionOnClick
                  components={{
                    Pagination: GridPagination,
                  }}
                  getRowId={(row) => row.eventParticipantId}
                  loading={loading}
                  onSortModelChange={handleSortModelChange}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { TranscriptPermissionsDialog };
