/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useRef, useState } from "react";
import { Box, Dialog, Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { useDataService } from "../services/DataService";
import { DownloadExhibitsDialog } from "./toolbars/DownloadExhibits";
import WebViewer from "@pdftron/pdfjs-express";

import { initWebViewer, ADVANCED_MODE } from "./useWebViewer";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px",
    overflow: "hidden",
    backgroundColor: "#f8f9fa"
  },
  container: {
    backgroundColor: "#343A40",
    overflow: "hidden"
  },
  webviewer: {
    width: "100%",
    height: "calc(90vh - 64px)"
  },
  notAuthorizedMsg: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    color: "#ffffff"
  }
}));

const ExhibitPreview = ({
  eventId,
  fileId,
  style,
  open,
  presenter,
  onLaunchExhibitEditor,
  onPublishExhibit,
  onClosePreview,
  isMeetingRoom = false,
  exhibitMode = ADVANCED_MODE,
  useDialog = true
}) => {
  const classes = useStyles();
  const { auth, getIdentity } = React.useContext(AuthContext);
  const { data, setData, getFile, getMyFilePermissions } = useDataService();
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [openDownloadExhibits, setOpenDownloadExhibits] = useState(false);
  const viewer = useRef(null);

  React.useEffect(() => {
    if (isNil(eventId) || isNil(fileId)) return;

    const fetchData = async () => {
      console.log(
        "ExhibitPreview.Initializing Exhibit Editor...",
        eventId,
        fileId,
        presenter,
        useDialog
      );

      let isHost = false;
      const isPreview = true; // For PDFJS IN PREVIEW MODE
      setData({ eventId });

      console.log("ExhibitPreview.Getting logged-in user's identity...");
      const identity = JSON.parse(getIdentity());
      const username = identity.username;
      console.log("ExhibitPreview.Logged-in user's identity", identity);

      try {
        console.log("ExhibitPreview.getFile", fileId);
        const exhibit = await getFile(fileId);
        setData({ clear: "exhibit" });
        setData({ exhibit });

        if (presenter && presenter === username) {
          isHost = true;
        } else {
          isHost = false;
        }

        // Get my file permissions
        const filePermissions = await getMyFilePermissions(
          fileId,
          identity.userId
        );
        setData({ filePermissions: filePermissions });

        await loadWebViewer(
          username,
          isHost,
          isPreview,
          filePermissions,
          isMeetingRoom
        );
        setData({ clear: "viewerInit" });
        setData({ viewerInit: "true" });
      } catch (error) {
        console.log(error);
        setNotAuthorized(true);
      }
    };
    fetchData();
  }, [eventId, fileId]);

  const handleCloseExhibit = () => {
    onClosePreview && onClosePreview();
  };

  const handleLaunchExhibitEditor = () => {
    onLaunchExhibitEditor && onLaunchExhibitEditor();
  };

  /**
   * Load the WebViewer.
   */
  const loadWebViewer = async (
    username,
    isHost,
    isPreview,
    filePermissions,
    isMeetingRoom
  ) => {
    console.log(
      "ExhibitPreview.Loading WebViewer...",
      fileId,
      username,
      isHost,
      isPreview
    );

    setData({ clear: "fileId" });
    setData({ fileId });
    WebViewer(
      {
        licenseKey: `${process.env.REACT_APP_PDFJS_WEBVIEWER_LICENSE}`,
        path: "/lib",
        css: "/styles/editor.css",
        preloadWorker: "pdf",
        disabledElements: ["header", "toolsHeader"]
      },
      viewer.current
    ).then(async (instance) => {
      initWebViewer({
        instance,
        eventId,
        fileId,
        isPreview,
        username,
        filePermissions,
        isMeetingRoom,
        exhibitMode,
        useDialog,
        onDownloadExhibitClick: handleDownloadExhibit,
        onCloseClick: handleCloseExhibit,
        onLaunchExhibitEditor: handleLaunchExhibitEditor
      });
    });
  };

  const handleDownloadExhibit = async () => {
    setOpenDownloadExhibits(true);
  };

  return (
    <>
      {useDialog ? (
        <Dialog
          fullWidth
          maxWidth="lg"
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <PDFViewer
            viewer={viewer}
            style={style}
            notAuthorized={notAuthorized}
          />
        </Dialog>
      ) : (
        <PDFViewer
          viewer={viewer}
          style={style}
          notAuthorized={notAuthorized}
        />
      )}

      {/* Download Exhibits */}
      {fileId && openDownloadExhibits && (
        <DownloadExhibitsDialog
          open={openDownloadExhibits}
          handleClose={(e) => {
            setOpenDownloadExhibits(false);
          }}
          file={data.exhibit}
        />
      )}
    </>
  );
};

/**
 * Standalone PDF Viewer.
 *
 * @param {*} param0
 * @returns
 */
const PDFViewer = ({ viewer, style, notAuthorized }) => {
  const classes = useStyles();
  const { data, setData } = useDataService();

  return (
    <Box className={classes.main} style={style}>
      <Box className={classes.container}>
        {notAuthorized === false && (
          <div className={classes.webviewer} ref={viewer} />
        )}
        {notAuthorized === true && (
          <Box className={classes.notAuthorizedMsg}>
            You are not authorized to access this Exhibit.
          </Box>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={data.snackbar.open}
        autoHideDuration={6000}
        onClose={() => setData({ snackbar: { open: false } })}
      >
        <Alert
          onClose={() => setData({ snackbar: { open: false } })}
          severity={data.snackbar.severity}
        >
          {data.snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ExhibitPreview;
