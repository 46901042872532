/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  TextField
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Joi from "joi-browser";
import { isEmpty, isNil } from "lodash";
import { Alert } from "@mui/lab";
import {
  AuthContext,
  validate,
  validateProperty
} from "@cirrux888/huseby-client-auth";
import {
  useDataService,
  ROLE_HOST,
  ROLE_PARTICIPANT
} from "../services/DataService";
import { validateExhibitNumber } from "../services/EditorService";

import { getRoom } from "../services/RoomService";
import { useSocketIOService } from "../services/SocketIOService";
import { emitExhibitPublishing } from "./useSocket";
import {
  AddExhibitStampAlert,
  PublishExhibitConfirmationDialog
} from "./toolbars/PublishExhibitDialogs";
import { DownloadExhibitsDialog } from "./toolbars/DownloadExhibits";
import {
  AnnotationRightsChangedDialog,
  GrantAnnotationRightsDialog,
  RevokeAnnotationRightsDialog
} from "./toolbars/GrantAnnotationRights";

import WebViewer from "@pdftron/pdfjs-express";

import {
  initWebViewer,
  publishExhibit,
  onExhibitNumberUpdated,
  onCancelExhibitNumber,
  ADVANCED_MODE
} from "./useWebViewer";
import { CRXDialogTitle } from "@cirrux888/huseby-client-auth";
import useOnlineStatus from "../hooks/useOnlineStatus";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#343A40",
    paddingRight: "0px",
    height: "100%",
    width: "100%"
  },
  menubar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#343A40",
    height: "55px",
    width: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "hidden"
  },
  editorBackground: {
    display: "flex",
    backgroundColor: "#21242A",
    overflowY: "overflow",
    overflowX: "hidden",
    height: "100vh"
  },
  notAuthorizedMsg: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    color: "#ffffff"
  }
}));

const ExhibitEditor = (props) => {
  const classes = useStyles();
  const { eventId, fileId, style, onCloseExhibit } = props;
  const { auth, getIdentity } = React.useContext(AuthContext);
  const {
    data,
    setData,
    setRole,
    getFile,
    getEventParticipant,
    getMyFilePermissions
  } = useDataService();
  const {
    data: socketData,
    setData: setSocketData,
    joinRoom
  } = useSocketIOService();
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [openAddExhibitStampAlert, setOpenAddExhibitStampAlert] =
    useState(false);
  const [publishConfirmationOpen, setPublishConfirmationOpen] = useState(false);
  const [openDownloadExhibits, setOpenDownloadExhibits] = useState(false);
  const [openExhibitNumber, setOpenExhibitNumber] = useState(false);
  const [openGrantAnnotationRights, setOpenGrantAnnotationRights] =
    useState(false);
  const [openRevokeAnnotationRights, setOpenRevokeAnnotationRights] =
    useState(false);
  const [openAnnotatorChanged, setOpenAnnotatorChanged] = useState(false);
  const [publishing, setPublishing] = useState("not-started");
  const [annotator, setAnnotator] = useState();
  const [presenter, setPresenter] = useState();
  const viewer = useRef(null);
  const isOnline = useOnlineStatus();
  const [existingExhibitNumber, setExistingExhibitNumber] = useState();

  const [showParticipantPublish, setShowParticipantPublish] = React.useState({
    isPublishing: false,
    state: "not-started"
  });

  React.useEffect(() => {
    if (isNil(eventId) || isNil(fileId)) return;
    if (!isOnline) return;

    const fetchData = async () => {
      console.log(
        "ExhibitEditor.Initializing Exhibit Editor...",
        eventId,
        fileId
      );
      let isHost = false;
      const isPreview = false;
      setData({ eventId });
      setSocketData({ roomId: eventId });

      console.log("ExhibitEditor.Getting logged-in user's identity...");
      const identity = JSON.parse(getIdentity());
      const username = identity.username;
      const name = identity.name;
      const userId = identity.userId;
      console.log("ExhibitEditor.Logged-in user's identity", identity);

      const myContact = localStorage.getItem("huseby-contact");
      setData({ myContact });

      try {
        console.log("ExhibitEditor.getFile", fileId);
        const exhibit = await getFile(fileId);
        console.trace("ExhibitEditor::useEffect exhibit", exhibit);
        console.trace(
          "ExhibitEditor::useEffect exhibitNumber",
          exhibit.exhibitNumber
        );
        setExistingExhibitNumber(exhibit.exhibitNumber);
        console.trace(
          "ExhibitEditor::useEffect existingExhibitNumber",
          exhibit.exhibitNumber
        );
        setData({ clear: "exhibit" });
        setData({ exhibit });

        console.log("ExhibitEditor.Getting event participant info...");
        const eventParticipant = await getEventParticipant(
          eventId,
          identity.contactId
        );
        console.log("XXX eventParticipant", eventParticipant);

        const role = ROLE_PARTICIPANT;

        // Get my file permissions
        const filePermissions = await getMyFilePermissions(
          fileId,
          identity.userId
        );
        setData({ filePermissions: filePermissions });

        console.log("ExhibitEditor.Joining room...", eventId);
        await joinRoom(userId, username, name, eventId, role);

        setRole(role);
        setData({ clear: "role" });
        setData({ role });

        await loadWebViewer(
          username,
          isPreview,
          filePermissions,
          exhibit.exhibitNumber
        );
        setData({ clear: "viewerInit" });
        setData({ viewerInit: "true" });
      } catch (error) {
        console.log("error", error);
        setNotAuthorized(true);
      }
    };
    fetchData();
  }, [eventId, fileId, isOnline]);

  /// End of SocketIO stuff

  const handleCloseExhibit = () => {
    onCloseExhibit && onCloseExhibit();
  };

  /**
   * Load the WebViewer.
   */
  const loadWebViewer = async (
    username,
    isPreview,
    filePermissions,
    existingExhibitNumber
  ) => {
    console.log(
      "ExhibitEditor.Loading WebViewer...",
      fileId,
      username,
      socketData.exhibitSettings,
      filePermissions
    );
    // if (isNil(socketData.exhibitSettings)) return;

    setData({ clear: "fileId" });
    setData({ fileId });
    console.trace(
      "ExhibitEditor::loadWebViewer exhibitNumber ",
      existingExhibitNumber
    );
    WebViewer(
      {
        licenseKey: `${process.env.REACT_APP_PDFJS_WEBVIEWER_LICENSE}`,
        path: "/lib",
        css: "/styles/editor.css",
        preloadWorker: "pdf",
        // Disabling downloader if the server doesn't support byte range requests
        // and aren't able to enable them for some reason.
        // useDownloader: false,
        // Disable the header and toolsHeader until fully initialized
        disabledElements: ["header", "toolsHeader"]
      },
      viewer.current
    ).then(async (instance) => {
      initWebViewer({
        instance,
        eventId,
        fileId,
        isPreview,
        username,
        filePermissions,
        exhibitSettings: socketData.exhibitSettings,
        isMeetingRoom: false,
        exhibitMode: ADVANCED_MODE,
        useDialog: true, // QW-XXX: Don't hardcode boolean value
        onPublishExhibitConfirmClick: handlePublishExhibitConfirmClick,
        onPublishExhibitClick: handlePublishExhibit,
        onDownloadExhibitClick: handleDownloadExhibit,
        onExhibitNumberClick: handleOpenExhibitNumber,
        onCloseClick: handleCloseExhibit,
        onLaunchExhibitEditor: null,
        handleParticipantPublishState: handleParticipantPublishState,
        onAnnotationRightsClick: handleAnnotationRightsClick,
        onAnnotatorRightsChanged: handleAnnotationRightsChanged,
        existingExhibitNumber
      });
    });
  };

  const handleParticipantPublishState = (state) => {
    setShowParticipantPublish(state);
  };

  const handleDownloadExhibit = async () => {
    setOpenDownloadExhibits(true);
  };

  /**
   * Event handler for "Annotation Rights" button onClick event.
   */
  const handleAnnotationRightsClick = async () => {
    const roomInfo = await getRoom(eventId);
    // console.log("XXX roomInfo", roomInfo);
    // console.log(roomInfo.appState, roomInfo.appState.annotator);

    const annotator = roomInfo?.appState?.annotator;
    setAnnotator(annotator);
    const presenter = roomInfo?.appState?.presenter;
    setPresenter(presenter);
    setAnnotator(annotator);
    if (presenter?.username === annotator?.username || annotator == null) {
      setOpenGrantAnnotationRights(true);
    } else {
      setOpenRevokeAnnotationRights(true);
    }
  };

  const handleAnnotationRightsChanged = async (annotator) => {
    setAnnotator(annotator);
    setOpenAnnotatorChanged(annotator);
  };

  /**
   * Event handler for "Publish Exhibit" button onClick event.
   */
  const handlePublishExhibit = async () => {
    try {
      console.log("handlePublishExhibit...");

      // Show progress loader...
      console.log("Setting publishing to true....");
      setPublishing("in-progress");
      emitExhibitPublishing(eventId, fileId, "in-progress");

      // Publish  the Exhibit
      await publishExhibit();

      // setPublishConfirmationOpen(false);
      setPublishing("complete");
      emitExhibitPublishing(eventId, fileId, "complete");
    } catch (error) {
      console.log(error);

      setPublishing("error");
    }
  };

  const handlePublishExhibitConfirmClick = (hasStamp) => {
    console.log("handlePublishExhibitConfirmClick", hasStamp);
    if (!hasStamp) {
      setOpenAddExhibitStampAlert(!hasStamp);
    } else {
      setPublishConfirmationOpen(true);
    }
  };

  const handleOpenExhibitNumber = (e) => {
    setOpenExhibitNumber(true);
  };

  return (
    <>
      <Box className={classes.main} style={style}>
        <Box className={classes.container}>
          <Box
            // for some reason, these styles need to be on the tag instead of
            // in classes.editorBackground
            flexGrow={1}
            className={classes.editorBackground}
            style={{ width: "100%" }}
            justifyContent="center"
          >
            {!isOnline && (
              <Box className={classes.notAuthorizedMsg}>
                There was an issue connecting to the internet. Please check your
                connection.{" "}
              </Box>
            )}
            {notAuthorized === false && isOnline && (
              <div
                className="webviewer"
                ref={viewer}
                style={{
                  width: "100%",
                  height: "100%"
                }}
              />
            )}
            {notAuthorized === true && (
              <Box className={classes.notAuthorizedMsg}>
                You are not authorized to access this Exhibit.
              </Box>
            )}
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={data.snackbar.open}
          autoHideDuration={6000}
          onClose={() => setData({ snackbar: { open: false } })}
        >
          <Alert
            onClose={() => setData({ snackbar: { open: false } })}
            severity={data.snackbar.severity}
          >
            {data.snackbar.message}
          </Alert>
        </Snackbar>
      </Box>

      <AddExhibitStampAlert
        open={openAddExhibitStampAlert}
        onOkClick={() => setOpenAddExhibitStampAlert(false)}
      />

      <PublishExhibitConfirmationDialog
        open={publishConfirmationOpen}
        publishing={publishing}
        onYesClick={handlePublishExhibit}
        onNoClick={() => setPublishConfirmationOpen(false)}
      />
      {showParticipantPublish.isPublishing && (
        <Dialog fullWidth={true} maxWidth={"sm"} open={showParticipantPublish}>
          <CRXDialogTitle title="Publish Exhibit" />
          <DialogContent>
            <DialogContentText>
              <span>Your exhibit is being published...</span>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setPublishConfirmationOpen(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Download Exhibits */}
      {fileId && openDownloadExhibits && (
        <DownloadExhibitsDialog
          open={openDownloadExhibits}
          handleClose={(e) => {
            setOpenDownloadExhibits(false);
          }}
          file={data.exhibit}
        />
      )}

      {/* ExhibitNumber Dialog */}
      {/* {console.trace(
        "ExhibitEditor::return Before opening exhibit dialog exhibitNumber ",
        existingExhibitNumber
      )} */}
      {fileId && eventId && openExhibitNumber && (
        <ExhibitNumberDialog
          open={openExhibitNumber}
          onClose={(e, reason) => {
            // Force the user to enter an exhibit number.
            // the only way to close the dialog.
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              setOpenExhibitNumber(false);
            }
          }}
          eventId={eventId}
          fileId={fileId}
          existingExhibitNumber={existingExhibitNumber}
        />
      )}

      {/* Grant Annotation Rights Dialog */}
      {openGrantAnnotationRights && (
        <GrantAnnotationRightsDialog
          annotator={annotator}
          presenter={presenter}
          open={openGrantAnnotationRights}
          onClose={(e) => {
            setOpenGrantAnnotationRights(false);
            console.log("XXX viewer.current", viewer.current);
          }}
        />
      )}

      {/* Revoke Annotation Rights Dialog */}
      {openRevokeAnnotationRights && (
        <RevokeAnnotationRightsDialog
          annotator={annotator}
          presenter={presenter}
          open={openRevokeAnnotationRights}
          onClose={(e) => {
            setOpenRevokeAnnotationRights(false);
          }}
        />
      )}

      {openAnnotatorChanged && (
        <AnnotationRightsChangedDialog
          annotator={annotator}
          open={openAnnotatorChanged}
          onClose={(e) => {
            setOpenAnnotatorChanged(false);
          }}
        />
      )}
    </>
  );
};

const ExhibitNumberDialog = ({
  open,
  onClose,
  eventId,
  fileId,
  existingExhibitNumber
}) => {
  const [exhibitNumber, setExhibitNumber] = React.useState("");
  const [errors, setErrors] = useState(null);

  const schema = {
    exhibitNumber: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(10)
      .regex(/^[^`'^ \[\]\{\}\\|]+$/)
      .label("Exhibit Number")
  };

  useEffect(() => {
    if (!open) return;

    setExhibitNumber(existingExhibitNumber);
  }, [open]);

  const handleSubmit = async (evt) => {
    console.trace("ExhibitEditor::handleSubmit Entering ");
    evt.preventDefault();

    const errs = validate({ exhibitNumber }, schema);
    console.trace("ExhibitEditor::handleSubmit After validate ");

    if (errs != null) {
      setErrors(errs);
      return;
    }

    try {
      console.trace("ExhibitEditor::handleSubmit eventId ", eventId);
      console.trace(
        "ExhibitEditor::handleSubmit exhibitNumber ",
        exhibitNumber
      );
      console.trace("ExhibitEditor::handleSubmit fileId ", fileId);
      console.trace(
        "ExhibitEditor::handleSubmit Before  validateExhibitNumber"
      );
      await validateExhibitNumber(eventId, exhibitNumber, fileId);
      console.trace("ExhibitEditor::handleSubmit After  validateExhibitNumber");
    } catch (error) {
      const serverErrs = {};
      if (error.response) {
        serverErrs.exhibitNumber = error.response.data.message;
      } else {
        serverErrs.exhibitNumber = "An unknown error has occurred.";
      }
      setErrors(serverErrs);
      return;
    }

    onClose();
    onExhibitNumberUpdated(exhibitNumber);
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    onCancelExhibitNumber();
    onClose();
  };

  return (
    <>
      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <form
          style={{
            width: "400px"
          }}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            Please enter your Exhibit Number.
            <br />
            <br />
            <TextField
              id="exhibitNumber"
              label="Exhibit Number"
              name="exhibitNumber"
              value={exhibitNumber}
              variant="outlined"
              required
              error={errors && !isEmpty(errors["exhibitNumber"])}
              helperText={errors && errors["exhibitNumber"]}
              onChange={(e) => {
                setExhibitNumber(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              autoFocus
              style={{ width: 300 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ExhibitEditor;
