/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import axios from "axios";

const roomHttp = axios.create({
  baseURL: process.env.REACT_APP_ROOMSVC_API_URL
});

/**
 * Get the room's info, including application state and exhibit state.
 *
 * @param {*} roomId
 * @returns
 */
const getRoom = async (roomId) => {
  const config = {
    method: "get",
    url: `/rooms/${roomId}`
  };

  try {
    const { data: roomInfo } = await roomHttp(config);
    return roomInfo;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Grant annotation rights.
 *
 * @param {*} sessionId - The sessionId of the selected attendee
 */
const grantAnnotationRights = async (roomId, sessionId) => {
  console.log("RoomService.grantAnnotationRights", sessionId);
  const config = {
    method: "put",
    url: `/rooms/${roomId}/annotator?sessionId=${sessionId}`
  };

  try {
    const { data: annotatorInfo } = await roomHttp(config);
    console.log("Granted annotation rights to annotator: ", annotatorInfo);
    return annotatorInfo;
  } catch (error) {
    console.log("Error while granting rights to annotator!", error);
    throw error;
  }
};

/**
 * Revoke annotation rights.
 *
 * @param {*} roomId
 * @param {*} annotator
 */
const revokeAnnotationRights = async (roomId) => {
  console.log("RoomService.revokeAnnotationRights", roomId);
  const config = {
    method: "delete",
    url: `/rooms/${roomId}/annotator`
  };

  try {
    const { data: annotatorInfo } = await roomHttp(config);
    console.log("Revoked annotation rights from annotator.");
    return annotatorInfo;
  } catch (error) {
    console.log("Error while revoking annotatio rights from annotator!", error);
    throw error;
  }
};

export { getRoom, grantAnnotationRights, revokeAnnotationRights };
