/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/
/**
 * Encodes the bookmarks object for sending to REST API and
 * wraps in an object with transcriptId, bookmarkTranscriptId,
 * contactId and contentBytes.
 *
 * Create a copy
 *
 * @param {*} bookmarks
 */
export function encodeBookmarks(transcript, bookmarks, colors, contactId) {
  var pako = require("pako");

  let bInfo = {};
  bInfo.bookmarks = bookmarks;
  bInfo.colors = colors;
  const bInfoString = JSON.stringify(bInfo);

  let contentBytes = pako.deflate(bInfoString, { to: "string" });
  contentBytes = btoa(contentBytes);

  let bVO = {};

  bVO.transcriptId = transcript.transcriptId;
  bVO.contactId = contactId;
  bVO.transcriptBookmarkId = transcript.transcriptBookmarkId;
  bVO.contentBytes = contentBytes;

  return bVO;
}

export const COLOR_WHITE_DEC = 16777215;
export const COLOR_GREY_DEC = 15000804;
export const COLOR_HIGHLIGHT_DEC = 16776867;

export function listDefaultColors() {
  const colors = [
    { id: "0", label: "Issue 0", color: 9087204 },
    { id: "1", label: "Followup", color: 16767861 },
    { id: "2", label: "Doc/Info Request", color: 12438943 },
    { id: "3", label: "Credibility", color: 15769247 },
    { id: "4", label: "Issue 4", color: 12232417 },
    { id: "5", label: "Issue 5", color: 10141620 },
    { id: "6", label: "Issue 6", color: 16234115 },
    { id: "7", label: "Issue 7", color: 14199744 },
    { id: "8", label: "Issue 8", color: 9614284 },
    { id: "9", label: "Issue 9", color: 13286575 },
  ];

  return colors;
}

export function toHex(decimalColor) {
  if (decimalColor === undefined) return "#FFFFFF";
  return "#" + new Number(decimalColor).toString(16);
}

export function toDec(hexColor) {
  try {
    if (hexColor === undefined) hexColor = "#FFFFFF";
    const hex = parseInt(hexColor.replace(/^#/, ""), 16);
    const dec = parseInt(Number(hex), 10);
    return dec;
  } catch (err) {
    return hexColor;
  }
}

export function getColorFromList(color, colors) {
  if (color === null) return colors[0];

  for (let i = 0; i < colors.length; i++) {
    if (colors[i].color === color) return colors[i];
  }
  return colors[0];
}

export function removeNewLines(input) {
  input = input.replace(/\n/g, "");
  return input;
}

export function decodeURIAdditionalCases(input) {
  input = replace(input, "%23", "#");
  input = replace(input, "%24", "$");
  input = replace(input, "%26", "&");
  input = replace(input, "%2B", "+");
  input = replace(input, "%2C", ",");
  input = replace(input, "%2F", "/");
  input = replace(input, "%3A", ":");
  input = replace(input, "%3B", ";");
  input = replace(input, "%3D", "=");
  input = replace(input, "%3F", "?");
  input = replace(input, "%40", "@");
  return input;
}

function replace(source, what, replace) {
  return source.split(what).join(replace);
}
