/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  TextField,
} from "@mui/material";
import { Alert, LoadingButton } from "@mui/lab";
import { makeStyles, useTheme } from "@mui/styles";
import { AuthContext } from "../../services/AuthService";

import { isEmpty } from "lodash";
import Joi from "joi-browser";
import { CRXDialogTitle } from "../common/dialog/CRXDialogTitle";

import { useLocation, useHistory } from "react-router-dom";
import {
  validate,
  validateProperty,
  setErrorMessages,
} from "../common/validateUtilities";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   "& > * .MuiTextField-root": {
  //     margin: theme.spacing(1),
  //     width: "54ch",
  //   },
  // },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
  },
  progress: {
    color: "#71ee33",
  },
}));

function useQuery() {
  try {
    return new URLSearchParams(useLocation().search);
  } catch (error) {
    console.log("Unexpected error while fetching query string...", error);
    return null;
  }
}

const GuestLogin = ({ passcode, open, handleClose }) => {
  const classes = useStyles();
  const { closeButton } = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const { registerGuest } = React.useContext(AuthContext);
  const [apiError, setApiError] = React.useState(null);
  const query = useQuery();
  const history = useHistory();
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  let meetingUrl;

  React.useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");

    setErrors([]);
  }, [open]);

  const schema = {
    firstName: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(255)
      .label("First Name"),
    lastName: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(255)
      .label("Last Name"),
    email: Joi.string()
      .email()
      .required()
      .trim(true)
      .min(0)
      .max(255)
      .label("Email"),
  };
  const handleSubmit = async (e) => {
    setApiError(null);
    try {
      e.preventDefault();
      setLoading(true);
      const email = e.target.email.value;
      const firstName = e.target.firstName.value;
      const lastName = e.target.lastName.value;
      // const password = e.target.password.value;

      if (query != null && query.get("next") != null) {
        meetingUrl = query.get("next");
      }

      const errs = validate({ firstName, lastName, email }, schema);
      if (errs != null) {
        setErrors(errs);
        return;
      }

      await registerGuest({ firstName, lastName, email, meetingUrl, passcode });
      setOpenSnackbar(true);
      handleClose();
      history.push(meetingUrl);
      setApiError(null);
    } catch (ex) {
      if (ex.response && ex.response.status != 200) {
        setApiError(ex.response.data.message);
        setOpenErrorSnackbar(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth aria-labelledby="form-dialog-title">
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <CRXDialogTitle onClick={handleClose} title="Guest Login" />

          <DialogContent>
            <DialogContentText>
              Please enter your details here to login as a guest.
            </DialogContentText>
            <TextField
              error={errors && !isEmpty(errors["firstName"])}
              helperText={errors && errors["firstName"]}
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              onChange={(e) => {
                setFirstName(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              autoFocus
            />
            <TextField
              error={errors && !isEmpty(errors["lastName"])}
              helperText={errors && errors["lastName"]}
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastname"
              onChange={(e) => {
                setLastName(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
            />
            <TextField
              error={errors && !isEmpty(errors["email"])}
              helperText={errors && errors["email"]}
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => {
                setEmail(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isEmpty(errors)}
              loading={loading}
              loadingPosition="start"
            >
              Login
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        message="You are registered as a guest and you will be redirected into meeting room."
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          You are registered as a guest and you will be redirected into meeting
          room.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
        autoHideDuration={10000}
        message={apiError}
      >
        <Alert onClose={handleCloseErrorSnackbar} severity="error">
          <div style={{ color: "red" }}>{apiError}</div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default GuestLogin;
