/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState } from "react";
import {
  Button,
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useLocation, useHistory } from "react-router-dom";
import Joi from "joi-browser";
import { isEmpty } from "lodash";
import { AuthContext } from "../../services/AuthService";
import Copyright from "./Copyright";
import ForgotPassword from "./ForgotPassword";
import GuestLogin from "./GuestLogin";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import {
  validate,
  validateProperty,
  setErrorMessages,
} from "../../components/common/validateUtilities";
import useBrand from "../../components/common/useBrand";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflowY: "scroll",
  },
  image: {
    backgroundImage: `url("/${process.env.REACT_APP_BRAND_ID}/images/login_splash.png") `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    color: "#71ee33",
  },
  table: {
    border: "1px solid #333333",
    borderCollapse: "collapse",
    "& th": {
      border: "1px solid #333333",
      padding: "8px",
    },
    "& td": {
      border: "1px solid #333333",
      padding: "8px",
    },
  },
}));

function useQuery() {
  try {
    return new URLSearchParams(useLocation().search);
  } catch (error) {
    console.log("Unexpected error while fetching query string...", error);
    return null;
  }
}

export default function Login() {
  const classes = useStyles();
  const { login, registerUserWithToken, getGuestLoginSettings } =
    React.useContext(AuthContext);
  const { companyName, productName } = useBrand();
  const [loading, setLoading] = useState(false);
  const [meetingUrl, setMeetingUrl] = useState(null);
  const [hidden, setHidden] = useState(true);
  const [guestLoginSettings, setGuestLoginSettings] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passcode, setPasscode] = useState(null);
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  const query = useQuery();
  const [open, setOpen] = React.useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [openTermsOfService, setOpenTermsOfService] = React.useState(false);
  const [openGuestLogin, setOpenGuestLogin] = React.useState(false);

  React.useEffect(() => {
    // Initialize the component.
    const init = async () => {
      console.log("Initializing Login screen....");

      // Check if username is passed over in login url
      let username = null;
      if (query != null && query.get("username") != null)
        username = query.get("username");

      // Check if passcode is passed over in login url
      let passcode = null;
      if (query != null && query.get("passcode") != null) {
        passcode = query.get("passcode");
        setPasscode(passcode);
      }

      // Check if meetingUrl is passed over in login url
      let _meetingUrl = null;
      let lookupUrl = null;
      if (
        query != null &&
        query.get("next") != null &&
        query.get("next").indexOf("/rm/") != -1
      ) {
        _meetingUrl = query.get("next");
        setMeetingUrl(_meetingUrl);

        // The lookupUrl is used for the REST API for registering
        lookupUrl = _meetingUrl.replace("/rm/", "/");
      }

      // If the username, passcode and meetingUrl are not null,
      // then automatically login the user.   We should have an authentication
      // token instead on a per-user basis.
      if (username !== null && passcode !== null && _meetingUrl !== null) {
        await registerUserWithToken({
          username,
          meetingUrl: lookupUrl,
          passcode,
        });
        history.push(_meetingUrl);
      } else {
        // Check if 'enableGuestAccess' is true for this meetingUrl.
        if (_meetingUrl !== null) {
          let settings = await getGuestLoginSettings({
            meetingUrl: lookupUrl,
          });
          console.log("Guest login settings = ", settings);
          if (settings != null) {
            setGuestLoginSettings(settings);
          }
        }
      }

      setHidden(false);
    };

    init();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleGuestLoginOpen = () => {
    setOpenGuestLogin(true);
  };

  const schema = {
    email: Joi.string()
      // .email()
      .required()
      .trim(true)
      .min(0)
      .max(255)
      .label("Email Address"),
    password: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(56)
      .label("Password"),
  };

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      let nextUrl = "/";
      if (query != null && query.get("next") != null)
        nextUrl = query.get("next");
      const querySearch = location.search.replace("?", "");
      const params = querySearch.split("&");

      if (params.length > 0) nextUrl += "?";

      for (let i in params) {
        const name = params[i].split("=")[0];
        if (name !== "next") {
          nextUrl += params[i];
        }
        if (i < params.length - 1) nextUrl += "&";
      }

      setLoading(true);
      const email = e.target.email.value;
      const password = e.target.password.value;

      const errs = validate({ email, password }, schema);
      if (errs != null) {
        setErrors(errs);
        return;
      }

      await login(email, password);
      history.push(nextUrl);
    } catch (ex) {
      console.log(ex);
      if (ex.response) {
        if (ex.response.data.errors != null) {
          setErrorMessages(
            ex.response.data.errors.fields,
            ex.response.data.errors.defaultMessage,
            setErrors
          );
        } else if (ex.response.data.message) {
          let errMessage = ex.response.data.message;
          if (errMessage === "Sorry user is inactive and can't be logged in") {
            errMessage =
              "Sorry, we could not log you into HusebyConnect.    Please try again.   If you are still having issues, please use the 'Forgot Password' link below to reset your password.";
          } else if (errMessage === "Bad credentials") {
            errMessage =
              "Invalid Email Address or Password.   Please try again.";
          }
          setErrorMessages("password", errMessage, setErrors);
        }
      } else if (ex.message === "Network Error") {
        setErrorMessages(
          "password",
          "There was an issue connecting to the internet.  Please check your connection.",
          setErrors
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {!hidden && (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box className={classes.paper}>
              <Box mt={10} mb={10}>
                <img
                  src={`${process.env.REACT_APP_BRAND_ID}/images/logo.png`}
                  alt="Logo"
                />
              </Box>

              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  error={errors && !isEmpty(errors["email"])}
                  helperText={errors && errors["email"]}
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                    validateProperty(schema, e.currentTarget, setErrors);
                  }}
                  autoFocus
                />
                <Box my={2} />
                <TextField
                  error={errors && !isEmpty(errors["password"])}
                  helperText={errors && errors["password"]}
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.currentTarget.value);
                    validateProperty(schema, e.currentTarget, setErrors);
                  }}
                />
                <Box display="flex" flexDirection="row" width={1} my={4}>
                  <Box width={400}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" width={1} gap={0.5}>
                  <Box width={1}>
                    By signing in, I agree to {companyName}'s{" "}
                    <Link
                      onClick={() => setOpenPrivacyPolicy(true)}
                      disabled={loading}
                      variant="body2"
                      style={{
                        cursor: "pointer",
                        marginRight: "0px",
                      }}
                    >
                      Privacy Statement
                    </Link>{" "}
                    and{" "}
                    <Link
                      onClick={() => setOpenTermsOfService(true)}
                      disabled={loading}
                      variant="body2"
                      style={{
                        cursor: "pointer",
                        marginLeft: "0px",
                      }}
                    >
                      Terms of Service
                    </Link>
                    .
                  </Box>
                </Box>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  fullWidth
                  disabled={loading || !isEmpty(errors)}
                  loading={loading}
                  loadingPosition="start"
                >
                  Sign In
                </LoadingButton>

                {guestLoginSettings?.enableGuestAccess && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    fullWidth
                    onClick={handleGuestLoginOpen}
                  >
                    Guest Login
                  </Button>
                )}
                <Grid container>
                  <Grid item xs>
                    <Link
                      onClick={handleClickOpen}
                      disabled={loading}
                      variant="body2"
                      style={{ cursor: "pointer" }}
                    >
                      Forgot Password?
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      )}

      <ForgotPassword open={open} handleClose={() => setOpen(false)} />

      <GuestLogin
        open={openGuestLogin}
        passcode={passcode}
        handleClose={() => setOpenGuestLogin(false)}
      />

      <PrivacyPolicy
        open={openPrivacyPolicy}
        handleClose={() => setOpenPrivacyPolicy(false)}
      />

      <TermsOfService
        open={openTermsOfService}
        handleClose={() => {
          setOpenTermsOfService(false);
        }}
      />
    </>
  );
}
