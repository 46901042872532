/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect } from "react";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  ToggleButton,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert } from "@mui/lab";
import {
  REALTIME_TRANSCRIPTS_ENABLED,
  VOICETOTEXT_TRANSCRIPTS_ENABLED,
} from "../../../model/Model";
import SettingsMenu from "../settings/SettingsMenu";
import ExportTranscript from "../export/ExportTranscript";
import { stopTranscriptService } from "../../../services/SocketIOService";
import { TranscriptContext } from "../../../services/TranscriptService";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { Default, Mobile } from "../../../common/Responsive";
import ViewerMenu from "./ViewerMenu";
import TranscriptSearch from "../transcriptSearch/TranscriptSearch";
import { Microphone } from "../../awsTranscribe/Microphone";

import "../../../styles.module.css";
import StartStreamTextTranscript from "../../streamText/StartStreamTextTranscript";

const NOTSTARTED = "not-started";
const DIALING = "dialing-in";
const STARTED = "started";
const STOPPED = "stopped";

const mapTranscriptionStatusToLabel = {
  [NOTSTARTED]: "Transcription Not Started",
  [DIALING]: "Transcription Service is starting...",
  [STARTED]: "Transcription Service has started",
  [STOPPED]: "Transcription Service has stopped",
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
  },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
  },
  buttonBar: {
    height: "38px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    maxHeight: 440,
  },
  searchLabel: {
    color: "#ffffff",
  },
  searchResults: {
    width: "100%",
    maxWidth: 400,
    backgroundColor: "#fffea3",
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
    borderWidth: "1px",
    borderColor: "#999999",
  },
  select: {
    minWidth: "60px",
    "&:before": {
      borderColor: theme.palette.common.black,
    },
    "&:after": {
      borderColor: theme.palette.common.black,
    },
  },
  icon: {
    fill: theme.palette.common.black,
  },
  loader: {
    margin: "0 auto",
  },
}));

const TranscriptsViewerMenuBar = ({
  transcripts,
  onBackgroundColorChange,
  onFontColorChange,
  onFontSizeChange,
  onEnableAutoscrollChange,
  onShowPagesAndLinesChange,
  onShowSpeakersChange,
  onShowTimeChange,
  onScrollToPageChange,
  onScrollToLineChange,
  onScrollToView,
  onOpenPermissionsList,
  setEnableAutoscrollIndicator,
  enableAutoscrollIndicator,
}) => {
  const classes = useStyles();
  const { data, setData, stopTranscriptStream } = React.useContext(TranscriptContext);
  const [pageOptions, setPageOptions] = React.useState([]); // QW-TODO: Clean up
  const [lineOptions, setLineOptions] = React.useState([]); // QW-TODO: Clean up
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isZoomMicOn, setIsZoomMicOn] = React.useState(false);
  const [transcriptionDialogMeta, setTranscriptionDialogMeta] = React.useState({
    transcriptionStatus: NOTSTARTED,
    openDialog: false,
  });
  const { getIdentity } = React.useContext(AuthContext);
  const identity = JSON.parse(getIdentity());

  useEffect(() => {
    // If RealTime Transcripts is enabled and the status is "not-started",
    // then show the StartStreamTextTranscript.
    if (
      data?.event?.transcripts === REALTIME_TRANSCRIPTS_ENABLED &&
      data.currentTranscriptStatus === NOTSTARTED
    ) {
      setTranscriptionDialogMeta({
        ...transcriptionDialogMeta,
        openDialog: true,
        transcriptionStatus: data.currentTranscriptStatus,
      });
      // If Voice-to-Text Transcripts is enabled and the status is "not-started",
      // then show the "TranscriptStatusDialog"
    } else if (data.currentTranscriptStatus !== NOTSTARTED) {
      setTranscriptionDialogMeta({
        ...transcriptionDialogMeta,
        openDialog: true,
        transcriptionStatus: data.currentTranscriptStatus,
      });
    }
  }, [data.currentTranscriptStatus]);

  React.useEffect(() => {
    let maxL = 0;
    const po = new Map();
    transcripts?.forEach((tr) => {
      const pageNum = parseInt(tr.pageNumber);
      if (!po.has(pageNum))
        po.set(
          pageNum,
          <option key={pageNum} value={pageNum}>
            {pageNum}
          </option>
        );

      if (maxL < parseInt(tr.lineNumber)) maxL = parseInt(tr.lineNumber);
    });
    setPageOptions(Array.from(po.values()));

    var lo = [];
    for (let i = 1; i <= maxL; i++) {
      lo.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    setLineOptions(lo);
  }, [transcripts?.length]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAutoscroll = (e) => {
    setEnableAutoscrollIndicator(e.target.checked);
    onEnableAutoscrollChange(e.target.checked);
  };

  const handleStopStream = async () => {
    await stopTranscriptService({ roomId: data.eventId, hangup: true });
    await stopTranscriptStream(data.eventId);

    setData({ currentTranscriptStatus: STOPPED });
  };

  return (
    <div className="viewerMenuBar">
      <Box
        display="flex"
        component="span"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "0px" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            {/* Viewer Settings Menu */}
            <ViewerMenu
              onBackgroundColorChange={onBackgroundColorChange}
              onFontColorChange={onFontColorChange}
              onFontSizeChange={onFontSizeChange}
              onShowPagesAndLinesChange={onShowPagesAndLinesChange}
              onShowSpeakersChange={onShowSpeakersChange}
              onShowTimeChange={onShowTimeChange}
              onScrollToPageChange={onScrollToPageChange}
              onScrollToLineChange={onScrollToLineChange}
              pageOptions={pageOptions}
              lineOptions={lineOptions}
            />

            {/* Autoscroll Feature */}
            {!data.isArchive && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleAutoscroll}
                    name="checkboxShowPagesAndLines"
                  />
                }
                checked={enableAutoscrollIndicator}
                label="Autoscroll"
              />
            )}
          </Box>
          {/* Transcript Search */}
          <Default>
            <TranscriptSearch onScrollToView={onScrollToView} width="250px" />
          </Default>
        </Box>
        <Box display="flex" flexDirection="row" mx={1} className={classes.buttonBar}>
          {/* The Microphone Iframe.  We want this in a separate iframe so 
              that we can allocate its own memory for performance reasons.  */}
          <Default>
            {!data.isArchive && data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED && (
              <>
                {/* <ToggleButton
                  onClick={() => {
                    setIsZoomMicOn(!isZoomMicOn);
                  }}
                >
                  Mic
                </ToggleButton> */}
                <Microphone
                  eventId={data.eventId}
                  isZoomEnabled={data?.event.enableMeeting}
                  isZoomMicOn={data.zoomMicOn}
                  // isZoomEnabled={false}
                  // isZoomMicOn={isZoomMicOn}
                />
              </>
            )}
          </Default>

          {/* Export Transcripts */}
          {(identity.contactTypeId === 1 ||
            identity.contactTypeId === 2 ||
            identity.contactTypeId === 3 ||
            identity.contactTypeId === 6) && (
            <Tooltip title="Export Transcript">
              <IconButton
                id="transcriptViewerExportButton"
                variant="contained"
                size="medium"
                onClick={() => setOpenPrintDialog(true)}
              >
                <DownloadIcon color="primary" fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}

          {openPrintDialog && (
            <ExportTranscript
              open={openPrintDialog}
              handleClose={() => {
                setOpenPrintDialog(false);
              }}
              transcripts={transcripts ?? []}
              speakerMapping={data?.transcriptSpeakerMapping ?? []}
              bookmarks={data?.bookmarks ?? []}
              headerMetaData={{
                caseName: data.event.myCase.caseName,
                dateCreated: data.event.myCase.dateCreated,
                witnessName: data.event.event.witnessName,
              }}
            />
          )}

          {/* Settings Menu */}
          {data.grantSaveTranscripts && !data.isArchive && (
            <SettingsMenu
              setTranscriptionDialogMeta={setTranscriptionDialogMeta}
              transcriptionDialogMeta={transcriptionDialogMeta}
              onOpenPermissionsList={onOpenPermissionsList}
            />
          )}
        </Box>
      </Box>
      <Mobile>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "0px 10px 10px 10px" }}
        >
          <Microphone
            eventId={data.eventId}
            isZoomEnabled={data?.event.enableMeeting}
            isZoomMicOn={data.zoomMicOn}
          />
          <TranscriptSearch onScrollToView={onScrollToView} width="calc(100vw - 240px)" />
        </Box>
      </Mobile>

      {/* Transcript Started Dialog */}
      {(data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED ||
        data.currentTranscriptStatus === STOPPED) && (
        <Dialog
          open={transcriptionDialogMeta.openDialog}
          keepMounted
          onClose={() => {
            setTranscriptionDialogMeta({
              ...transcriptionDialogMeta,
              openDialog: false,
            });
          }}
          aria-describedby="alert-dialog-transcription-description"
          disableEnforceFocus
        >
          <DialogTitle>{"Transcription Status"}</DialogTitle>
          {transcriptionDialogMeta.transcriptionStatus === DIALING && (
            <CircularProgress className={classes.loader} size={30} />
          )}
          <DialogContent>
            <DialogContentText id="alert-dialog-transcription-description">
              {mapTranscriptionStatusToLabel[transcriptionDialogMeta.transcriptionStatus]}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            {data.grantSaveTranscripts &&
              transcriptionDialogMeta.transcriptionStatus === DIALING && (
                <Button
                  style={{ backgroundColor: "#af1730", color: "#fff" }}
                  onClick={() => {
                    handleStopStream();
                    setTranscriptionDialogMeta({
                      ...transcriptionDialogMeta,
                      openDialog: false,
                    });
                  }}
                >
                  {"Cancel"}
                </Button>
              )}
            <Button
              style={{ backgroundColor: "#af1730", color: "#fff" }}
              onClick={() => {
                setTranscriptionDialogMeta({
                  openDialog: false,
                });
              }}
            >
              {"Ok"}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {(identity.contactTypeId === 1 || identity.contactTypeId === 2) &&
        data?.event?.transcripts === REALTIME_TRANSCRIPTS_ENABLED &&
        data.currentTranscriptStatus === NOTSTARTED &&
        !data.isArchive && (
          <StartStreamTextTranscript
            open={transcriptionDialogMeta.openDialog}
            onClose={() => {
              setTranscriptionDialogMeta({
                ...transcriptionDialogMeta,
                openDialog: false,
              });
            }}
          />
        )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        message="Permissions updated successfully for selected participants."
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Permissions updated successfully for selected participants.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TranscriptsViewerMenuBar;
