/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthService } from "@cirrux888/huseby-client-auth";
import { useZoomMeetingService } from "../services/ZoomMeetingService";
import { SocketIOContext } from "../services/SocketIOService";
import {
  emitUserJoinedZoomRoom,
  emitUserLeftZoomRoom,
  emitUserToggledZoomMic,
  emitUserInBreakoutRoom,
} from "../services/SocketIOService";
import { ZoomMtg } from "@zoom/meetingsdk";

const useStyles = makeStyles(() => ({
  test: {
    backgroundColor: "#0000ff",
  },
  notAuthenticatedMsg: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#222222",
    color: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
  },
}));

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

const Index = ({ eventId, meetingUrl, role }) => {
  const classes = useStyles();
  const { getIdentity } = useAuthService();
  const { joinEventMeeting, endEventMeeting } = useZoomMeetingService();
  const { setData: setSocketData, joinRoom } = useContext(SocketIOContext);

  let sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
  let meetingNumber;
  // let role = ROLE_HOST;
  let leaveUrl = `/rm/${meetingUrl}/meeting`;
  let userFriendlyName;
  let userName;
  let userEmail;
  let meetingPassword;
  let signature;
  let registrantToken = "";

  useEffect(() => {
    const initMeetingApp = async () => {
      console.log("Initalizing Zoom meeting...", eventId, meetingUrl, role);
      console.log("Zoom.SharedArrayBuffer enabled: ", typeof SharedArrayBuffer === "function");

      const identity = JSON.parse(getIdentity());
      console.log("Get logged-in user's identity", identity);
      userFriendlyName = identity.name;
      userName = identity.username;
      userEmail = identity.email;

      // Join the room
      await joinRoom(identity.userId, userName, userFriendlyName, eventId, role);

      let joinMeetingData;
      let zoomMeeting;
      console.log("Joining Event Meeting...", eventId, role);
      joinMeetingData = await joinEventMeeting(eventId, userName, userEmail, role);
      console.log("   joinMeetingData", joinMeetingData);
      zoomMeeting = joinMeetingData["zoom_meeting"];
      meetingNumber = zoomMeeting.id;
      meetingPassword = zoomMeeting.password;
      signature = joinMeetingData.signature;

      console.log("   meetingData", zoomMeeting);
      startMeeting();

      // Add event listeners for a user leaving the event.   This appears to be work for both
      // attendees and the host.   We need to detect the mic muting / unmuting separately.
      ZoomMtg.inMeetingServiceListener("onUserLeave", function (data) {
        console.log("User left the meeting:", data);

        // If the current user is leaving, Zoom sends back "You are leave meeting by yourself".
        if (data.reason == "You are leave meeting by yourself") {
          emitUserLeftZoomRoom(eventId, identity);
        }
      });
    };
    initMeetingApp();
  }, []);

  function startMeeting() {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success);

        // Update Join Meeting button label
        const joinBtn = document.querySelector(".preview-join-button");
        if (joinBtn !== null) joinBtn.textContent = "Join Meeting";

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userFriendlyName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: meetingPassword,
          tk: registrantToken,
          success: (success) => {
            console.log("User joined the Zoom meeting...", userName, userEmail);
            const identity = JSON.parse(getIdentity());
            emitUserJoinedZoomRoom(eventId, identity);

            // Fix for HUSEBYA105-7622:  Instead of detecting when the microphone button is
            // clicked, we need to add a MutationObserver to detect for changes in the mic buttons label.
            // This is because when Zoom is first initialized, it will get the state of the mic
            // from cookies and set it accordingly.   Therefore, we need to listen to changes in the label
            // instead of whether the mic button is clicked.
            const micBtn = document.querySelector("button.join-audio-container__btn");
            if (micBtn !== null) {
              const label = micBtn.getAttribute("aria-label");
              const isZoomMicOn = label === "mute my microphone";
              console.log("zoom-mic-on", micBtn.getAttribute("aria-label"), isZoomMicOn);
              emitUserToggledZoomMic(eventId, identity, isZoomMicOn);

              var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                  if (mutation.type === "attributes") {
                    const identity = JSON.parse(getIdentity());
                    const label = micBtn.getAttribute("aria-label");
                    const isZoomMicOn = label === "mute my microphone";
                    console.log("zoom-mic-on", micBtn.getAttribute("aria-label"), isZoomMicOn);
                    emitUserToggledZoomMic(eventId, identity, isZoomMicOn);
                  }
                });
              });

              observer.observe(micBtn, {
                attributes: true,
              });
            }

            // Detect if the user is in a breakout room.   There isn't a good way to do this with ZoomMtg
            // component or via webhooks, so we need to check if the button in the Zoom room says "Leave Room" :(
            // We don't need to add the MutationObserver here because whenever we join a breakout room, the
            // entire Zoom component refreshes.   This has the undesirable side effect of emitting the
            // 'userJoinedRoom' and 'userLeftRoom' events so we may be running into some performance issues.
            const leaveRoomBtn = document.querySelector("div.footer__leave-btn-container button");
            console.log("Checking for `leaveRoomBtn` element...", leaveRoomBtn);
            if (leaveRoomBtn !== null) {
              // Emit if user is in breakout room
              const label = leaveRoomBtn.getAttribute("aria-label");
              const inBreakoutRoom = label === "Leave Room";
              console.log("- inBreakoutRoom", inBreakoutRoom);
              emitUserInBreakoutRoom(eventId, identity, inBreakoutRoom);
            }

            ZoomMtg.showRecordFunction({
              show: true,
            });
          },
          error: (error) => {
            console.log("XXX error", error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  return (
    <>
      <div className="meeting-app">
        <main>
          {/* <button className="joinWindowBtn" onClick={() => startMeeting()}>
            Join Meeting
          </button> */}
        </main>
      </div>
    </>
  );
};

export { Index };
