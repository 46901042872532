import React, { useEffect, useState } from "react";
import { Box, ToggleButton, Tooltip } from "@mui/material";
import { AwsTranscribeContext } from "../../services/AwsTranscribeService";
import { TranscriptContext } from "../../services/TranscriptService";
import green from "@mui/material/colors/green";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const Microphone = ({ eventId, isZoomEnabled, isZoomMicOn }) => {
  const {
    initializeAudioAnalyzer,
    initializeAwsTranscribe,
    initializeMicrophoneStream,
    updateVoiceToTextTranscript,
    closeSocket,
  } = React.useContext(AwsTranscribeContext);

  const { data } = React.useContext(TranscriptContext);

  const [tooltipMessage, setTooltipMessage] = useState("");
  const [isMicOn, setIsMicOn] = useState(false);
  const [isMicEnabled, setIsMicEnabled] = useState(false);
  const [micLevel, setMicLevel] = useState(0);
  const [stream, setStream] = useState(null);
  const [microphoneStream, setMicrophoneStream] = useState(null);
  const [transcription, setTranscription] = useState(null);
  const [transcriptStatus, setTranscriptStatus] = useState();

  /**
   * Enable / disable transcript microphone when Zoom mic is toggled.
   * The transcript mic is toggled whenenever the zoom mic or the transcript status
   * is changed.
   */
  useEffect(() => {
    // console.log("XXXXX isZoomMicOn", isZoomMicOn, transcriptStatus, data.zoomUserInRoom);
    setIsMicOn(
      isZoomMicOn &&
        data.zoomUserInRoom &&
        transcriptStatus == "started" &&
        data?.grantEnableMic &&
        !data?.inBreakoutRoom
    );
  }, [data?.grantEnableMic, isZoomMicOn, transcriptStatus, data.zoomUserInRoom]);

  useEffect(() => {
    setIsMicEnabled(transcriptStatus === "started" && !data?.inBreakoutRoom);
  }, [transcriptStatus, data?.inBreakoutRoom]);

  useEffect(() => {
    setTranscriptStatus(data.currentTranscriptStatus);
  }, [data.currentTranscriptStatus]);

  /**
   * Enable / disable AWS Transcribe
   */
  useEffect(() => {
    if (isMicOn) {
      console.log("Initializing AWS Transcribe...");
      initializeMediaStream();
    } else {
      closeSocket();
      setMicrophoneStream(null);
    }
  }, [isMicOn]);

  /**
   * POST the AWS transcription to HusebyConnect REST API.  This is
   * necessary since I need to control whether or not `isMicOn` is true or false.
   * AWS Transcribe expects the audio to be sent continuously in realtime, so we need
   * to send data over continuously.  When trying to get the value of `isMicOn` in the
   * javascript callbacks, I was getting the wrong value.   In addition, I cannot use the
   * `playRecording` and`pauseRecording` features as it seems to cause the AWS web socket to drop
   * after fiften sections.
   */
  useEffect(() => {
    if (isMicOn) {
      updateVoiceToTextTranscript(transcription, eventId)
        .then((isNewSpeakerRegistered) => {
          if (isNewSpeakerRegistered) {
            console.log("New speaker is registered.  Refresh the speaker listing and emit.");
          }
        })
        .catch((error) => {});
    }
  }, [transcription]);

  /**
   * Initialize the media stream.
   */
  const initializeMediaStream = () => {
    console.log("Initializing the media stream...");
    // if (!isOnline) return;

    navigator.mediaDevices
      .getUserMedia({
        video: false,
        audio: true,
      })
      .then((stream) => {
        const audioTrack = stream.getAudioTracks()[0];
        audioTrack
          .applyConstraints({ noiseSuppression: true, echoCancellation: true, volume: 1.0 })
          .then(() => {
            // Do something with the track such as using the Image Capture API
            console.log("Constraints applied successfully to audio track.");
          })
          .catch((e) => {
            // The constraints could not be satisified by the available devices.
            console.log("Error while trying to apply constraints to audioTrack.");
          });

        setStream(stream);
        console.log("The media stream has been initialized.");
        console.log("Initializing audio analyzer...");
        initializeAudioAnalyzer(stream, (micLevel) => {
          setMicLevel(micLevel);
        });
        console.log("Streamining audio to AWS web socket...");
        const micStream = initializeMicrophoneStream(stream);
        setMicrophoneStream(micStream);
        console.log("Initializing AWS transcribe and web sockets...");
        initializeAwsTranscribe(micStream, onErrorHandler, onAwsDataHandler);
      })
      .catch((err) => {
        console.log("XXX error while connecting", err);
      });
  };

  const onErrorHandler = (msg) => {
    console.log("Error detected while handling AWS transcript audio!", msg);
  };

  const onAwsDataHandler = (message) => {
    setTranscription(message);
  };

  /**
   * Hook for updating the tooltip message and emitting the
   * SocketIO message for "On Record".
   */
  React.useEffect(() => {
    if (!data?.grantEnableMic) {
      setTooltipMessage(
        "You do not have permissions to go on record.  You can request an attendee with Manage permissions to grant you microphone rights."
      );
    } else if (transcriptStatus !== "started") {
      setTooltipMessage(
        "The Transcript Service has not been started.    Once an attendee with Manage permissions starts the Transcript service, you may go on record."
      );
    } else if (data?.inBreakoutRoom) {
      setTooltipMessage(
        "You are in a breakout room.   You are automatically 'Off Record' and your audio will not be transcribed. "
      );
    } else if (data?.grantEnableMic && isZoomEnabled && isMicOn) {
      setTooltipMessage("Mute your Zoom microphone to go off record.");
    } else if (data?.grantEnableMic && isZoomEnabled && !isMicOn) {
      setTooltipMessage("Unmute your Zoom microphone to go on record.");
    } else if (data?.grantEnableMic && !isZoomEnabled && isMicOn) {
      setTooltipMessage("Click to go off record.");
    } else if (data?.grantEnableMic && !isZoomEnabled && !isMicOn) {
      setTooltipMessage("Click to go on record.");
    }
    // console.trace(
    //   "Microphone::useEffect[data?.grantEnableMic, isMicOn, transcriptStatus, data?.inBreakoutRoom] isMicOn ",
    //   isMicOn
    // );
    // console.trace(
    //   "Microphone::useEffect[data?.grantEnableMic, isMicOn, transcriptStatus, data?.inBreakoutRoom] data ",
    //   data
    // );
    // console.trace(
    //   "Microphone::useEffect[data?.grantEnableMic, isMicOn, transcriptStatus, data?.inBreakoutRoom] isZoomEnabled ",
    //   isZoomEnabled
    // );
  }, [data?.grantEnableMic, isMicOn, transcriptStatus, data?.inBreakoutRoom]);

  const colorMicOn = green[500];
  const colorMicOff = "#e4e4e4"; // "#af1730"
  const colorMicDisabled = "#e4e4e4";

  const onRecordButtonStyle = {
    display: "flex",
    height: "40px",
    width: "200px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: !isMicEnabled ? colorMicDisabled : isMicOn ? colorMicOn : colorMicOff,
    color: !isMicEnabled ? "#999999" : isMicOn ? "#000000" : "#000000",
  };

  return (
    <>
      <Tooltip title={tooltipMessage}>
        <div
          style={{
            height: "40px",
            width: "200px",
            paddingTop: "0px",
          }}
        >
          {!isZoomEnabled && (
            <ToggleButton
              id="captureTranscriptsButton"
              variant="contained"
              size="medium"
              onClick={() => setIsMicOn(!isMicOn)}
              disabled={!isMicEnabled}
              style={onRecordButtonStyle}
            >
              {isMicOn && (
                <>
                  <MicIcon fontSize="medium" />
                  <span>On Record</span>
                </>
              )}
              {!isMicOn && (
                <>
                  <MicOffIcon fontSize="medium" />
                  <span>Off Record</span>
                </>
              )}
            </ToggleButton>
          )}
          {isZoomEnabled && (
            <Box style={onRecordButtonStyle}>
              {isMicOn && (
                <>
                  <MicIcon fontSize="medium" />
                  <span>On Record</span>
                </>
              )}
              {!isMicOn && (
                <>
                  <MicOffIcon fontSize="medium" />
                  <span>Off Record</span>
                </>
              )}
            </Box>
          )}
          {isMicOn && (
            <div
              style={{
                marginTop: "2px",
                height: "10px",
                backgroundColor: "green",
                width: `${(micLevel - 2) * 2}px`,
              }}
            ></div>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export { Microphone };
