/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

export function getParameterByName(name) {
  if (name !== "" && name !== null && name != undefined) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  } else {
    var arr = window.location.href.split("/");
    return arr[arr.length - 1];
  }
}

export function getFileSize(fileSize) {
  if (fileSize < 1000000) return Math.round(fileSize / 1024) + "KB";
  else return Math.round(fileSize / 1024000).toFixed(2) + "MB";
}
