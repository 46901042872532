/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect } from "react";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    fontSize: "10pt",
  },
  search: {
    height: "38px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
}));

const SearchBar = ({ onQueryChange }) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = (e) => {
    if (e.keyCode === 13) onQueryChange(searchQuery);
  };

  const handleReset = () => {
    setSearchQuery("");
    onQueryChange("");
  };

  return (
    <>
      <Box className={classes.search}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          py={0.5}
          px={1}
        >
          <TextField
            placeholder="Search…"
            variant="standard"
            size="small"
            style={{ width: 225 }}
            value={searchQuery}
            onChange={handleQueryChange}
            onKeyUp={handleSearch}
            InputProps={{
              startAdornment: <SearchIcon />,
              disableUnderline: true,
            }}
          />
          <IconButton className={classes.button} color="default" size="small" onClick={handleReset}>
            <ClearIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          style={{ height: "30px", width: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            onQueryChange(searchQuery);
          }}
        >
          Go
        </Button>
      </Box>
    </>
  );
};

export default SearchBar;
