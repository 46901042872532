/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import _ from "lodash";
import { instance as http } from "@cirrux888/huseby-client-auth";

/**
 * Register/Import the PDF from the specified fileId.
 *
 * @param {*} fileId
 * @returns
 */
const importFile = async (fileId) => {
  console.log("Import PDF from fileId...", fileId);

  try {
    const importConfig = {
      method: "post",
      url: `/pdf/import?fileId=${fileId}`
    };

    const { data: pdf } = await http(importConfig);
    console.log("ImportFile - PDF imported successfully!", pdf);

    return pdf;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Get file information for viewing.
 *
 * @param {*} fileId
 * @returns
 */
const viewFile = async (fileId, isPresenting) => {
  console.log("Get file information for viewing...", fileId);

  try {
    const viewConfig = {
      method: "get",
      url: `/sf/files/${fileId}/view?isPresenting=${encodeURIComponent(
        isPresenting
      )}`
    };

    const { data } = await http(viewConfig);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const listAnnotations = async (pdfId) => {
  console.log("Listing annotations...", pdfId);

  try {
    const config = {
      method: "get",
      url: `/pdf/${pdfId}/annotations`
    };

    // setData({ loading: true });
    const { data: annotations } = await http(config);
    return annotations;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const saveAnnotation = async (pdfId, annotationId, xfdf) => {
  const config = {
    method: "post",
    url: `/pdf/${pdfId}/annotations`,
    data: {
      annotationId,
      xfdf
    }
  };
  try {
    await http(config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getNextExhibitNumber = async (eventId) => {
  console.log("getNextExhibitNumber", eventId);
  const config = {
    method: "get",
    url: `/sf/files/event/${eventId}/next-exhibit-number`
  };
  try {
    const nextResp = await http(config);
    return nextResp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const validateExhibitNumber = async (eventId, exhibitNumber, fileId) => {
  console.log("validateExhibitNumber", eventId);
  const config = {
    method: "get",
    url: `/sf/files/event/${eventId}/validate-exhibit-number?exhibitNumber=${encodeURIComponent(
      exhibitNumber
    )}&fileId=${encodeURIComponent(fileId)}`
  };

  try {
    const nextResp = await http(config);
    return nextResp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const publishExhibitAndUpdateExhibitNumber = async (
  pdfId,
  exhibitNumber,
  exhibitBlob
) => {
  console.log("publishExhibitAndUpdateExhibitNumber", pdfId, exhibitNumber);
  try {
    const formData = new FormData();
    formData.append("file", exhibitBlob);

    const config = {
      method: "post",
      url: `/pdf/${pdfId}/publish?exhibitNumber=${encodeURIComponent(
        exhibitNumber
      )}`,
      headers: {
        "content-type": "multipart/form-data"
      },
      data: formData
    };

    await http(config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  importFile,
  viewFile,
  listAnnotations,
  saveAnnotation,
  getNextExhibitNumber,
  validateExhibitNumber,
  publishExhibitAndUpdateExhibitNumber
};
