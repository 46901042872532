/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import Raven from 'raven-js'

function init() {
  Raven.config(
    'https://58f14eb6d4654b59ba6ecae6faf783f2@o434291.ingest.sentry.io/5391114',
    {
      release: '1-0-0',
      environment: 'development-test'
    }
  ).install()
}

function log(error) {
  Raven.captureException(error)
}

export default {
  init,
  log
}
