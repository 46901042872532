/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

/**
 * Utility functions for emitting socket messages from the PDF.JS WebViewer to
 * the RoomServer.  The RoomServer will then in turn emit socket messages to
 * all listening socket clients.
 *
 */
import { isNil } from "lodash";
import { io } from "socket.io-client";

const URL = `${process.env.REACT_APP_SOCKETIO_URL}`;
const socket = io(URL, { autoConnect: false });

// Socket IO Event Types
export const TYPE_MESSAGE = "ExhibitEditor.message";
export const TYPE_ANNOTATION_CREATED = "annotationCreated";
export const TYPE_ANNOTATION_UPDATED = "annotationUpdated";
export const TYPE_ANNOTATION_DELETED = "annotationDeleted";
export const TYPE_EXHIBIT_PUBLISHING = "exhibitPublishing";
export const TYPE_ACTIVE_PRESENTER_CHANGED = "activePresenterChanged";
export const TYPE_ANNOTATOR_CHANGED = "annotatorChanged";
export const TYPE_ANNOTATION_RIGHTS_GRANTED = "annotationRightsGranted";
export const TYPE_ANNOTATION_RIGHTS_REVOKED = "annotationRightsRevoked";
export const TYPE_PAGE_SCROLL_EVENT = "pageScrollEvent";

/**
 * Emit an Event message.
 *
 * @param {*} latestEvent
 * @param {*} roomId
 * @returns
 */
export const emitEventMessage = (latestEvent, roomId) => {
  // console.log("emitEventmessage", latestEvent, roomId);
  if (isNil(latestEvent)) return;

  const message = {
    //   username: getIdentity().username,
    type: "event",
    data: {
      type: latestEvent.type,
      id: latestEvent.id,
      emit: latestEvent.emit,
      pageIndex: latestEvent.pageIndex,
      data: {
        global: latestEvent.position
      },
      toolMode: latestEvent.toolMode,
      sub: latestEvent.sub,
      value: latestEvent.value
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId: roomId });
};

/**
 * Emit annotations message.
 *
 * @param {*} annotations
 * @param {*} roomId
 */
export const emitAnnotationsMessage = (annotations, roomId) => {
  const message = {
    type: "annotations",
    data: annotations
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId: roomId });
};

/**
 * Emit TYPE_ANNOTATION_CREATED message.  This socket message tells other
 * listening clients that an annotation has been created.  This is called
 * when the PDF.JS WebViewer creates an annotation.
 *
 * @param {*} roomId
 * @param {*} annotationId
 * @param {*} annotation
 */
export const emitAnnotationCreated = (roomId, annotationId, annotation) => {
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_ANNOTATION_CREATED,
      annotationId,
      annotation
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId });
};

/**
 * Emit TYPE_ANNOTATION_UPDATED message.  This socket message tells other
 * listening clients that an annotation has been updated.  This is called
 * when the PDF.JS WebViewer creates an annotation.
 *
 * @param {*} roomId
 * @param {*} annotationId
 * @param {*} annotation
 */
export const emitAnnotationUpdated = (roomId, annotationId, annotation) => {
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_ANNOTATION_UPDATED,
      annotationId,
      annotation
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId });
};

/**
 * Emit TYPE_ANNOTATION_DELETED message.  This socket message tells other
 * listening clients that an annotation has been deleted.  This is called
 * when the PDF.JS WebViewer deletes an annotation.
 *
 * @param {*} roomId
 * @param {*} annotationId
 * @param {*} annotation
 */
export const emitAnnotationDeleted = (roomId, annotationId, annotation) => {
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_ANNOTATION_DELETED,
      annotationId,
      annotation
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId });
};

/**
 * Emit TYPE_PAGE_SCROLL_EVENT.  This socket message tells other
 * listening clients that exhibit's pages are scrolling.
 *
 * @param {*} roomId
 * @param {*} username
 * @param {number} position top position of the page in the scrollViewElement
 */
export const emitPageScrolling = (roomId, username, position) => {
  // console.log("Emitting page scroll event to SocketIO server...");
  const message = {
    username: username,
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_PAGE_SCROLL_EVENT,
      emit: true,
      position
    }
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: roomId });
};

/**
 * Emit TYPE_EXHIBIT_ROTATION_UPDATED.
 *
 * @param {*} roomId
 * @param {*} username
 * @param {*} rotation
 */
export const emitRotationUpdated = (roomId, username, rotation) => {
  const message = {
    username: username,
    type: TYPE_MESSAGE,
    data: {
      type: "rotationUpdated",
      emit: true,
      rotation: rotation
    }
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: roomId });
};

/**
 * Emit TYPE_EXHIBIT_ROTATION_UPDATED.
 *
 * @param {*} roomId
 * @param {*} username
 * @param {*} pageNumber
 */
export const emitPageNumberUpdated = (roomId, username, pageNumber) => {
  const message = {
    username: username,
    type: TYPE_MESSAGE,
    data: {
      type: "pageNumberUpdated",
      emit: true,
      pageNumber: pageNumber
    }
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: roomId });
};

/**
 * Emit TYPE_ZOOM_UPDATED.
 *
 * @param {*} roomId
 * @param {*} username
 * @param {*} zoom
 */
export const emitZoomUpdated = (roomId, username, zoom) => {
  const message = {
    username: username,
    type: TYPE_MESSAGE,
    data: {
      type: "zoomUpdated",
      emit: true,
      zoom: zoom
    }
  };
  socket.emit(TYPE_MESSAGE, { data: message, roomId: roomId });
};

/**
 * Emit TYPE_DISPLAY_MODE_UPDATED.
 *
 * @param {*} roomId
 * @param {*} username
 * @param {*} zoom
 */
export const emitDisplayModeUpdated = (roomId, username, layoutMode) => {
  const message = {
    username: username,
    type: TYPE_MESSAGE,
    data: {
      type: "displayModeUpdated",
      emit: true,
      layoutMode: layoutMode
    }
  };
  socket.emit(TYPE_MESSAGE, {
    data: message,
    roomId: roomId
  });
};

/**
 * Emit TYPE_EXHIBIT_PUBLISHING message.  This socket message tells other
 * listening clients that an exhibit is being published.
 *
 * @param {*} roomId
 * @param {*} fileId
 * @param {*} state
 */
export const emitExhibitPublishing = (roomId, fileId, state) => {
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_EXHIBIT_PUBLISHING,
      fileId,
      state
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId });
};

export const emitActivePresenterChanged = (roomId, presenter) => {
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_ACTIVE_PRESENTER_CHANGED,
      presenter: presenter
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId });
};

export const emitAnnotatorChanged = (roomId, annotator) => {
  const message = {
    type: TYPE_MESSAGE,
    data: {
      type: TYPE_ANNOTATOR_CHANGED,
      annotator: annotator
    }
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId });
};

export default socket;
