/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import HighlightColorEditor from "./HighlightColorEditor";
import HighlightButton from "./HighlightButton";
import { isNil } from "lodash";
import { TranscriptContext } from "../../../services/TranscriptService";
import { toHex, COLOR_WHITE_DEC, getColorFromList } from "../transcriptUtilities";

const EditBookmark = ({ transcript, open, onClose, onSaveBookmark }) => {
  // Bookmark textarea limit
  const CHARACTER_LIMIT = 300;
  const { data } = React.useContext(TranscriptContext);
  const [bookmark, setBookmark] = React.useState(null);
  const [highlightColorLabelingOpen, setHighlightColorLabelingOpen] = React.useState(false);
  const [highlightOption, setHighlightOption] = React.useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState(COLOR_WHITE_DEC);
  const [bookmarkText, setBookmarkText] = React.useState(transcript?.bookmark?.notes);
  const [availableColors, setAvailableColors] = React.useState([]);
  const [inputHelperText, setInputHelperText] = React.useState({
    message: `0/${CHARACTER_LIMIT}`,
    error: false,
  });

  React.useEffect(() => {
    if (!open) return;

    console.log("XXXXX transcript", transcript);

    // Look up transcript bookmark.
    const _bookmark = data.transcriptBookmarks[transcript.id];

    // Set properties for EditBookmark component
    setBookmark(_bookmark);
    setInputHelperText({
      message: `${_bookmark?.notes?.length || 0}/${CHARACTER_LIMIT}`,
      error: false,
    });

    if (_bookmark?.color) {
      setBackgroundColor(_bookmark.color);
    }
    if (_bookmark?.notes) {
      setBookmarkText(_bookmark.notes);
    }

    const noColor = { color: COLOR_WHITE_DEC, id: "-1", label: "No Color" };
    let available = [noColor];
    if (!isNil(transcript?.colors)) available.push(...transcript?.colors);
    if (!isNil(_bookmark?.colors)) {
      available = JSON.parse(_bookmark.colors);
    }
    setHighlightOption(getColorFromList(backgroundColor, available));
    setAvailableColors(available);
  }, [open]);

  const handleHighlightChange = (option) => {
    setHighlightOption(option);
    setBackgroundColor(option.color);
  };

  const handleSaveBookmark = () => {
    const newBookmark = {
      label: highlightOption.label,
      color: backgroundColor,
      id: `${transcript.id}`,
      lineVO: {
        isAlternating: false,
        selectionOn: false,
        pageNumber: transcript.pageNumber,
        lineNumber: transcript.lineNumber,
        lineType: transcript.lineType,
        selectionStart: transcript.selectionStart,
        selectionEnd: transcript.selectionEnd,
        timeStamp: transcript.timeStamp,
        text: transcript.text,
      },
      colors: `${JSON.stringify(availableColors)}`,
      notes: bookmarkText,
      position: 0,
    };

    if (bookmark?.id) {
      newBookmark.id = bookmark.id;
      console.log("Updating bookmark", newBookmark.id);
    } else {
      console.log("Creating a new bookmark", newBookmark);
    }

    transcript.bookmark = newBookmark;
    onSaveBookmark(transcript);
  };

  const handleInputChange = (e) => {
    setBookmarkText(e.currentTarget.value);

    if (e.currentTarget.value.length <= CHARACTER_LIMIT) {
      setInputHelperText({
        message: `${e.currentTarget.value.length} / ${CHARACTER_LIMIT}`,
        error: false,
      });
    } else {
      setInputHelperText({
        message: `Bookmark notes cannot exceed ${CHARACTER_LIMIT} characters`,
        error: true,
      });
    }
  };

  const handleChangeLabel = (colors) => {
    transcript.colors = colors;
    setAvailableColors(colors);
  };

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg" disableEnforceFocus>
        <CRXDialogTitle
          onClick={() => {
            onClose();
            setBookmarkText("");
            setBackgroundColor(COLOR_WHITE_DEC);
          }}
          title="Add/Edit Bookmark"
        />

        <DialogContent>
          <Box style={{ height: "225px" }}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" my={2}>
              Highlight:
              <Box mx={2}>
                <HighlightButton
                  colors={availableColors}
                  selected={highlightOption}
                  onChange={handleHighlightChange}
                />
              </Box>
              <IconButton
                color="primary"
                aria-label="edit highlight"
                component="span"
                onClick={(e) => setHighlightColorLabelingOpen(true)}
                size="small"
              >
                <EditIcon />
              </IconButton>
              <HighlightColorEditor
                colors={availableColors}
                open={highlightColorLabelingOpen}
                onClose={(e) => setHighlightColorLabelingOpen(false)}
                onChangeLabel={handleChangeLabel}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "500px", height: "100px" }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                style={{ marginBottom: "10px", width: "100%" }}
              >
                <span style={{ color: "#000000", fontSize: "10pt" }}>
                  {inputHelperText.message}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                style={{ width: "100%" }}
              >
                <TextField
                  id="bookmarkText"
                  name="bookmarkText"
                  multiline
                  rows={5}
                  defaultValue={bookmarkText}
                  variant="outlined"
                  inputProps={{ maxLength: CHARACTER_LIMIT + 1 }}
                  error={inputHelperText.error}
                  // helperText={inputHelperText.message}
                  style={{
                    width: 500,
                    backgroundColor: `${toHex(backgroundColor)}`,
                  }}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
              setBookmarkText("");
              setBackgroundColor(COLOR_WHITE_DEC);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={bookmarkText?.length > 300}
            variant="contained"
            color="primary"
            onClick={handleSaveBookmark}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditBookmark;
