import React from "react";
import { Buffer } from "buffer";
import MicrophoneStream from "microphone-stream";
import { isNil, merge, update } from "lodash";
import { downsampleBuffer, pcmEncode } from "../components/awsTranscribe/audioUtils";
import { createPresignedURL } from "../components/awsTranscribe/aws-signature-v4";
import { instance as http, AuthContext } from "@cirrux888/huseby-client-auth";
import { emitNewTranscriptRow } from "./SocketIOService";
import { v4 } from "uuid";

const crypto = require("crypto"); // tot sign our pre-signed URL
const marshaller = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);

const DELAY = 800;

// our global variables for managing state
let accessKey = process.env.REACT_APP_ACCESS_KEY;
let secretKey = process.env.REACT_APP_SECRET_KEY;
let sessionToken;
let languageCode = process.env.REACT_APP_LANGUAGE_CODE;
let region = process.env.REACT_APP_REGION;
let sampleRate = 16000;
let vocabularyName = "HusebyConnect";
let inputSampleRate;
let transcription = "";
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

// Initialize reducer
let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

// Initial state
const initialState = {
  transcript: "",
  transcript: [],
};

const AwsTranscribeContext = React.createContext();

// check to see if the browser allows mic access
if (!window.navigator.mediaDevices.getUserMedia) {
  // Use our helper method to show an error on the page
  alert(
    "We support the latest versions of Chrome, Firefox, Safari, and Edge. Update your browser and try your request again."
  );
}

const AwsTranscribeProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const { auth, getIdentity } = React.useContext(AuthContext);

  /**
   * Initialize audio analyzer.
   */
  const MAX_UI_MIC_LEVEL = 80;
  const initializeAudioAnalyzer = (stream, onMicLevelChange) => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function () {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      const micLevel =
        Math.round(average) > MAX_UI_MIC_LEVEL ? MAX_UI_MIC_LEVEL : Math.round(average);
      // setMicLevel(micLevel);
      onMicLevelChange(micLevel);
      // colorPids(average);
    };
  };

  /**
   * Init AWS Transcribe web socket.
   *
   * @param {*} micStream
   * @param {*} onErrorHandler
   * @param {*} onAwsDataHandler
   */
  const initializeAwsTranscribe = (micStream, onErrorHandler, onAwsDataHandler) => {
    console.log("Initializing AwsTranscribeService...");
    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = createPresignedUrl();

    //open up our WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    let sampleRate = 0;

    // when we get audio data from the mic, send it to the WebSocket if possible
    socket.onopen = function () {
      micStream?.on("data", function (rawAudioChunk) {
        // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
        let binary = convertAudioToBinaryMessage(rawAudioChunk);

        if (socket.readyState === socket.OPEN) {
          socket.send(binary);
        }
      });
    };

    wireSocketEvents(onErrorHandler, onAwsDataHandler);
  };

  /**
   * Stream raw audio to AWS Transcribe.
   *
   * @param {*} userMediaStream
   */
  const initializeMicrophoneStream = (userMediaStream) => {
    const audioTrack = userMediaStream.getAudioTracks()[0];
    console.log("- audioTrack.getCapabilities()", audioTrack.getCapabilities());
    console.log("- audioTrack.getConstraints()", audioTrack.getConstraints());
    audioTrack
      .applyConstraints({ noiseSuppression: true, echoCancellation: true, volume: 1.0 })
      .then(() => {
        // Do something with the track such as using the Image Capture API
        console.log("Constraints applied successfully to audio track.");
      })
      .catch((e) => {
        // The constraints could not be satisified by the available devices.
        console.log("XXX Error while trying to apply constraints to audioTrack.");
      });

    //let's get the mic input from the browser, via the microphone-stream module
    const opts = {
      stream: null,
      objectMode: false,
      bufferSize: null,
      context: null,
    };
    micStream = new MicrophoneStream(opts);

    micStream.on("format", function (data) {
      inputSampleRate = data.sampleRate;
    });

    micStream.setStream(userMediaStream);

    return micStream;
  };

  /**
   * Wire the socket events.
   */
  const wireSocketEvents = (onErrorHandler, onAwsDataHandler) => {
    // handle inbound messages from Amazon Transcribe
    socket.onmessage = function (message) {
      // Convert the binary event stream message to JSON
      let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
      let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
      if (messageWrapper.headers[":message-type"].value === "event") {
        handleEventStreamMessage(messageBody, onAwsDataHandler);
      } else {
        transcribeException = true;
        console.log("Error while transcribing audio...", messageBody.Message);
        onErrorHandler(messageBody.Message);
      }
    };

    socket.onerror = function (error) {
      socketError = true;
      console.log("WebSocket connection error. Try again.", error);
      // QW-TODO: Emit stop event.
    };

    socket.onclose = function (closeEvent) {
      // console.log("XXX socket.onclose...", closeEvent);
      // QW-TODO: Emit stop event.

      // the close event immediately follows the error event; only handle one.
      if (!socketError && !transcribeException) {
        if (closeEvent.code != 1000) {
          console.log("Streaming Exception!" + closeEvent.reason);
        }
        // QW-TODO: Emit stop event.
      }
    };
  };

  /**
   * Handle the Event stream message.  This is where the text comes in from
   * AWS transcribe and is set into the data
   *
   * @param {*} messageJson
   */
  const transcriptList = [];
  const handleEventStreamMessage = (messageJson, onAwsDataHandler) => {
    let results = messageJson.Transcript.Results;
    let transcriptLine = "";

    const MAX_LENGTH = 54;

    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        let transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial) {
          transcription += transcript + "\n";
          // setData({ transcript: transcript });
          setData({ clear: "transcript" });
          transcriptList.push({ id: v4(), text: transcript });
          setData({ transcript: transcriptList });
          setData({ transcription: transcription });

          // updateVoiceToTextTranscript(messageJson, data.eventId);
          onAwsDataHandler(messageJson, data.eventId);
        }

        setData({ transcription: transcription });
      }
    }
  };

  /**
   * Close the socket.
   *
   */
  const closeSocket = () => {
    console.log("Closing web socket for AWS Transcribe...");
    if (!isNil(socket) && socket.readyState === socket.OPEN) {
      try {
        micStream.stop();

        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
        socket.send(emptyBuffer);
        socket.close;

        // socket = null;
        // micStream = null;
      } catch (error) {
        console.log("Unexpected error while closing socket.", error);
      }
    }
  };

  /**
   * Convert audio to binrary message.
   *
   * @param {*} audioChunk
   * @returns
   */
  const convertAudioToBinaryMessage = (audioChunk) => {
    let raw = MicrophoneStream.toRaw(audioChunk);

    if (raw == null) return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate);
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
  };

  /**
   *
   * @param {*} buffer
   * @returns
   */
  const getAudioEventMessage = (buffer) => {
    // wrap the audio data in a JSON envelope
    return {
      headers: {
        ":message-type": {
          type: "string",
          value: "event",
        },
        ":event-type": {
          type: "string",
          value: "AudioEvent",
        },
      },
      body: buffer,
    };
  };

  /**
   * Create the presigned AWS url.
   *
   * @returns
   */
  const createPresignedUrl = () => {
    let endpoint = "transcribestreaming." + region + ".amazonaws.com:8443";

    // get a preauthenticated URL that we can use to establish our WebSocket
    return createPresignedURL(
      "GET",
      endpoint,
      "/stream-transcription-websocket",
      "transcribe",
      crypto.createHash("sha256").update("", "utf8").digest("hex"),
      {
        key: accessKey,
        secret: secretKey,
        sessionToken: sessionToken,
        protocol: "wss",
        expires: 300, // Fix for HUSEBYA105-2918.  Incerase expiration window.
        region: region,
        query:
          "language-code=" +
          languageCode +
          "&media-encoding=pcm&sample-rate=" +
          sampleRate +
          "&vocabulary-name=" +
          vocabularyName +
          "&show-speaker-label=true",
      }
    );
  };

  /**
   * Update the Voice-to-Text Transcript.
   *
   * @param {*} message
   * @param {*} meetingId
   * @returns Boolean that states whether new speaker is registered.  This can be used to refresh the
   *    speaker mapping.
   */
  const updateVoiceToTextTranscript = async (message, eventId) => {
    // Add speakerId (contactId) and speakerName to AWS Transcript
    const me = JSON.parse(getIdentity());
    const speakerId = me.contactId;
    const speakerName = me.name;
    addSpeakerNameToAwsTranscript(message, speakerId, speakerName);

    const config = {
      method: "post",
      url: `/st/${eventId}/voiceToText`,
      data: message,
    };

    try {
      setData({ loading: true });
      const { data: isNewSpeakerRegistered } = await http(config);
      console.log("Voice-to-text transcripts submitted successfully.");
      return isNewSpeakerRegistered;
    } catch (error) {
      console.log("Unexpected error while HTTP POSTing voice-to-text transcripts..", error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Utility function to add the speakerName to the JSON transcripts message.
   *
   * @param {*} speakerId
   * @param {*} speakerName
   */
  const addSpeakerNameToAwsTranscript = (message, speakerId, speakerName) => {
    const items = message.Transcript.Results[0].Alternatives[0].Items;
    items.forEach((item) => {
      item["Speaker"] = speakerId;
      item["SpeakerName"] = speakerName;
    });
  };

  return (
    <AwsTranscribeContext.Provider
      value={{
        data,
        setData,
        initializeAudioAnalyzer,
        initializeAwsTranscribe,
        initializeMicrophoneStream,
        wireSocketEvents,
        closeSocket,
        convertAudioToBinaryMessage,
        createPresignedURL,
        updateVoiceToTextTranscript,
      }}
    >
      {props.children}
    </AwsTranscribeContext.Provider>
  );
};

const useAwsTranscribeService = () => React.useContext(AwsTranscribeContext);

export { AwsTranscribeContext, AwsTranscribeProvider, useAwsTranscribeService };
