import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import { TranscriptContext } from "../../../services/TranscriptService";
import { emitSpeakerMappingChanged } from "../../../services/SocketIOService";

const LIST_PAGE_LENGTH = 15;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& .header-with-checkbox": {
      "& .MuiDataGrid-columnHeaderTitleContainer ": {
        paddingLeft: 0,
      },
    },
  },
  container: {
    height: "calc(100vh - 250px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "4px",
  },
  buttonBar: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recordingListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "calc(100vh - 85px)", // 8px from padding
  },
  speakerColorSelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
      borderWidth: "0px",
    },
  },
}));

/**
 * List of available speaker colors.
 */
const colors = [
  {
    label: "Dark Blue",
    value: "#00008B",
  },
  {
    label: "Green",
    value: "#008000",
  },
  {
    label: "Yellow",
    value: "#FFD700",
  },
  {
    label: "Orange",
    value: "#FF8C00",
  },
  {
    label: "Crimson",
    value: "#DC143C",
  },
  {
    label: "PaleVioletRed",
    value: "#DB7093",
  },
  {
    label: "Magenta",
    value: "#FF00FF",
  },
  {
    label: "SteelBlue",
    value: "#4682B4",
  },
  {
    label: "Maroon",
    value: "#800000",
  },
  {
    label: "Silver",
    value: "#C0C0C0",
  },
];

const SpeakerLabelMapper = ({ open, handleClose, onSuccess }) => {
  const classes = useStyles();
  const { data, listEventParticipants, listTranscriptSpeakers, updateTranscriptSpeaker } =
    useContext(TranscriptContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [sortModel, setSortModel] = useState([
    {
      field: "participant",
      sort: "asc",
    },
  ]);
  const [loading, setLoading] = useState();
  const [filteredParticipant, setFilteredParticipants] = useState([]);
  const [mappedTranscriptionData, setMappedTranscriptionData] = useState([]);
  const [mappedParticipantDetails, setMappedParticipantDetails] = useState([]);
  const [currentMappedSpeakers, setCurrentMappedSpeakers] = useState([]);

  React.useEffect(() => {
    if (filteredParticipant && filteredParticipant.length > 0) {
      const participantName = filteredParticipant.map((participant) => ({
        label: participant.contact.firstName + " " + participant.contact.lastName,
        value: participant.contact.firstName + " " + participant.contact.lastName,
      }));
      setMappedParticipantDetails(participantName);
    }
  }, [filteredParticipant]);

  const handleParticipantChange = (e, selectedRow) => {
    console.log({ selectedRow });
    if (mappedTranscriptionData && mappedTranscriptionData.length > 0) {
      const currentSpeaker = selectedRow.speaker;
      const selectedParticipant = e.target.value;
      const currentObjIndex = mappedTranscriptionData.findIndex(
        (data) => data.speaker === currentSpeaker
      );
      const tempMappedTranscriptionData = [...mappedTranscriptionData];
      tempMappedTranscriptionData[currentObjIndex].speakerName = selectedParticipant;
      setMappedTranscriptionData(tempMappedTranscriptionData);
    }
  };

  const handleColorChange = (e, selectedRow) => {
    if (mappedTranscriptionData && mappedTranscriptionData.length > 0) {
      const currentSpeaker = selectedRow.speakerIndex;
      const selectedColor = e.target.value;
      const currentObjIndex = mappedTranscriptionData.findIndex(
        (data) => data.speakerIndex === currentSpeaker
      );
      const tempMappedTranscriptionData = [...mappedTranscriptionData];
      tempMappedTranscriptionData[currentObjIndex].color = selectedColor;
      setMappedTranscriptionData(tempMappedTranscriptionData);
    }
  };

  const fetchTranscriptSpeakers = async () => {
    try {
      setLoading(true);
      const response = await listTranscriptSpeakers(data.transcriptId);
      const uniqueSpeakers = response?.map((speaker) => parseInt(speaker.speakerIndex)).sort();
      const sortedTranscriptSpeakers = response.sort((a, b) => a.speakerIndex - b.speakerIndex);

      if (uniqueSpeakers && uniqueSpeakers.length > 0) {
        const formSpeakerNumToNameMap = uniqueSpeakers.map((speaker, i) => {
          return {
            id: sortedTranscriptSpeakers[i] ? sortedTranscriptSpeakers[i].id : i,
            speakerIndex: sortedTranscriptSpeakers[i]
              ? sortedTranscriptSpeakers[i].speakerIndex
              : speaker,
            speakerName: sortedTranscriptSpeakers[i] ? sortedTranscriptSpeakers[i].speakerName : "",
            color: sortedTranscriptSpeakers[i] ? sortedTranscriptSpeakers[i].color : "",
          };
        });
        setMappedTranscriptionData(formSpeakerNumToNameMap);
        const rowDataToDisplayInDialog = formSpeakerNumToNameMap.map((sData, i) => {
          return {
            id: sData.id,
            speakerIndex: sData.speakerIndex,
            participant: mappedParticipantDetails,
            color: colors,
          };
        });
        setCurrentMappedSpeakers(rowDataToDisplayInDialog);
      }
    } catch (e) {
      console.log("eee", e);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSpeakerToNameMapping = (e) => {
    const transcriptId = data.transcriptId;
    updateTranscriptSpeaker(transcriptId, mappedTranscriptionData);
    handleClose();
    onSuccess({
      severity: "success",
      message: `Speaker mapped to transcript.`,
    });
    emitSpeakerMappingChanged(data.eventId, mappedTranscriptionData);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // if (sortModel?.sortModel?.length > 0) {
        //   const _sortParam = `${sortModel.sortModel[0].field}:${sortModel.sortModel[0].sort}`;
        //   setSortParam(_sortParam);
        // }
        const eventParticipants = await listEventParticipants(
          data.eventId,
          searchQuery,
          pageIndex,
          pageLength
        );
        setFilteredParticipants(eventParticipants.content);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    fetchTranscriptSpeakers();
  }, [open, mappedParticipantDetails.length]);

  const participantsColumns = [
    {
      field: "participant",
      headerName: "Participant",
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => {
        const currentSpeakerDetails = mappedTranscriptionData?.find(
          (speaker) => speaker.speakerIndex === params.row.speakerIndex
        );
        return currentSpeakerDetails.speakerName;
      },
    },
    {
      field: "color",
      headerName: "Color",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const colorDropdown = () => {
          return params.value.map((option) => {
            const currentSpeakerDetails = mappedTranscriptionData?.find(
              (speaker) => speaker.color === option.value
            );
            return (
              <MenuItem
                disabled={!!currentSpeakerDetails}
                style={{ color: option.value }}
                value={option.value}
              >
                &#9724;{option.label}
              </MenuItem>
            );
          });
        };
        const currentSpeakerDetails = mappedTranscriptionData?.find(
          (speaker) => speaker.speakerIndex === params.row.speakerIndex
        );
        return (
          <Box sx={{ minWidth: 120 }} style={{ overflow: "visible", zIndex: 100 }}>
            <FormControl fullWidth>
              <InputLabel id="color-label">Color</InputLabel>
              <Select
                className={classes.speakerColorSelect}
                style={{ color: currentSpeakerDetails.color }}
                id="colorselect"
                value={currentSpeakerDetails.color}
                label="Select"
                onChange={(e) => {
                  handleColorChange(e, params.row);
                }}
              >
                {colorDropdown()}
              </Select>
            </FormControl>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        className={classes.root}
        disableEnforceFocus
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveSpeakerToNameMapping(e);
          }}
        >
          <CRXDialogTitle onClick={handleClose} title="Manage Speaker Labels" />

          <DialogContent>
            <Box className={classes.container}>
              <Box className={classes.recordingListContainer}>
                <DataGrid
                  sx={{ py: 2 }}
                  className={classes.paper}
                  rows={currentMappedSpeakers}
                  columns={participantsColumns}
                  rowHeight={48}
                  loading={loading}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { SpeakerLabelMapper };
