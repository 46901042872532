/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import { getTextTranscripts, getPTFExport } from "./exportUtilities";
import {
  TRANSCRIPTS_DISABLED,
  REALTIME_TRANSCRIPTS_ENABLED,
  VOICETOTEXT_TRANSCRIPTS_ENABLED,
} from "../../../model/Model";
import { TranscriptContext } from "../../../services/TranscriptService";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
  },
}));

const ExportTranscript = ({
  open,
  handleClose,
  transcripts,
  bookmarks,
  speakerMapping,
  headerMetaData,
}) => {
  const classes = useStyles();
  const { data } = React.useContext(TranscriptContext);
  const [exportFileType, setExportFileType] = React.useState("txt");
  const [exportPageLineNumbers, setExportPageLineNumbers] = React.useState(true);
  const [exportBookmarksNotes, setExportBookmarksNotes] = React.useState(false);

  const handleSave = (e) => {
    const transcriptsStr =
      exportFileType === "txt"
        ? getTextTranscripts(
            exportPageLineNumbers,
            exportBookmarksNotes,
            transcripts,
            bookmarks,
            speakerMapping,
            headerMetaData,
            data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED
          )
        : getPTFExport(
            exportPageLineNumbers,
            exportBookmarksNotes,
            transcripts,
            bookmarks,
            speakerMapping,
            headerMetaData,
            data?.event?.transcripts === VOICETOTEXT_TRANSCRIPTS_ENABLED
          );

    const element = document.createElement("a");
    const file = new Blob([transcriptsStr], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = exportFileType === "txt" ? "Transcript.txt" : "Transcript.ptf";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        className={classes.root}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        disableEnforceFocus
      >
        <CRXDialogTitle onClick={handleClose} title="Export Transcript" />

        <DialogContent>
          <Box fontWeight="fontWeightBold" m={1}>
            File Type
          </Box>
          <Box component="div">
            <RadioGroup
              aria-label="export-type"
              name="exportType"
              color="primary"
              row
              defaultValue={exportFileType}
              onChange={(e) => setExportFileType(e.target.value)}
            >
              <FormControlLabel value={"txt"} control={<Radio color="primary" />} label="Text" />
              <FormControlLabel value={"ptf"} control={<Radio color="primary" />} label="PTF" />
            </RadioGroup>
          </Box>
          <Box fontWeight="fontWeightBold" m={1}>
            Include
          </Box>
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) => setExportPageLineNumbers(e.currentTarget.checked)}
                  name="checkboxExportPageLineNumbers"
                  checked={exportPageLineNumbers}
                />
              }
              label="Page / Line Numbers"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) => setExportBookmarksNotes(e.currentTarget.checked)}
                  name="checkboxExportBookmarksNotes"
                  checked={exportBookmarksNotes}
                />
              }
              label="Bookmarks & Notes"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportTranscript;
