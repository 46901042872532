/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/
import { v4 } from "uuid";
import { isEmpty, forEach } from "lodash";

const SPACING = "\t";
let WIDTH = 120;

/**
 * Get the text transcripts.
 * @param {*} hasPageLines
 * @param {*} hasBookmarks
 * @param {*} transcripts
 * @param {*} bookmarkHash
 * @param {*} speakerMapping
 * @param {*} headerMetaData
 * @returns
 */
export const getTextTranscripts = (
  hasPageLines,
  hasBookmarks,
  transcripts,
  bookmarkHash,
  speakerMapping,
  headerMetaData,
  isVoiceToTextEnabled = true
) => {
  // Set width based on isVoiceToTextEnabled
  WIDTH = isVoiceToTextEnabled ? 120 : 80;

  let transcriptsStr = getHeaderSpacing(headerMetaData.caseName) + headerMetaData.caseName + "\n";
  transcriptsStr +=
    getHeaderSpacing(headerMetaData.witnessName) + headerMetaData.witnessName + "\n";
  transcriptsStr +=
    getHeaderSpacing(headerMetaData.dateCreated) + headerMetaData.dateCreated + "\n";
  transcriptsStr += getHeaderSpacing("[Draft Transcript]") + " [Draft Transcript]\n\n";

  // Add the disclaimer text.
  transcriptsStr += getDisclaimerText();

  // Add the header
  // transcriptsStr += getHeaderText(hasPageLines, isVoiceToTextEnabled, hasBookmarks);

  transcriptsStr += "\n";

  // Add the transcript rows
  let curPage = "-1";
  transcripts.forEach((tr) => {
    if (curPage != tr.pageNumber) {
      curPage = tr.pageNumber;
      transcriptsStr += "\n\n\n" + getPageNumberSpacing() + curPage + "\n\n\n";
    }

    const speakerName = getSpeakerName(tr.speaker, speakerMapping);

    //// Display line number.
    transcriptsStr += hasPageLines ? formatLineNumber(tr.lineNumber) + SPACING : "";

    //// Display speaker
    if (isVoiceToTextEnabled) transcriptsStr += speakerName + SPACING + SPACING;

    //// Display
    const transcript = tr.text.replace("\n", "");
    transcriptsStr +=
      tr.text.replace("\n", "") +
      SPACING +
      (transcript.length <= 55 ? SPACING : "") +
      (transcript.length <= 47 ? SPACING : "");

    //// Display time
    if (isVoiceToTextEnabled) transcriptsStr += tr.timeStamp + SPACING + SPACING;

    //// Display bookmark
    transcriptsStr += hasBookmarks ? getBookmarkText(bookmarkHash, tr) + "" + "\n" : "" + "\n";
  });

  return transcriptsStr;
};

/**
 * Get header spacing.
 *
 * @param {*} headerLabel
 * @returns
 */
const getHeaderSpacing = (headerLabel) => {
  const numSpaces = (WIDTH - headerLabel.length) / 2;
  let spaces = "";
  for (var i = 0; i < numSpaces; i++) {
    spaces += " ";
  }

  return spaces;
};

/**
 * Get page number spacing.
 *
 * @returns
 */
const getPageNumberSpacing = () => {
  const numSpaces = WIDTH;
  let spaces = "";
  for (var i = 0; i < numSpaces; i++) {
    spaces += " ";
  }
  return spaces;
};

/**
 * Get disclaimer text for unedited transcripts.
 */
const getDisclaimerText = () => {
  let disclaimerText = "\n\n\n";
  const titleSpacing = getHeaderSpacing("UNEDITED TRANSCRIPTION DISCLAIMER");
  disclaimerText += titleSpacing + "UNEDITED TRANSCRIPTION DISCLAIMER\n";

  const spacing = getHeaderSpacing("   The following transcript of proceedings, or any");
  disclaimerText += spacing + "   The following transcript of proceedings, or any\n";
  disclaimerText += spacing + "portion thereof, is being delivered UNEDITED and\n";
  disclaimerText += spacing + "UNCERTIFIED.\n";
  disclaimerText += spacing + "   The user agrees not to disclose this\n";
  disclaimerText += spacing + "unedited transcription in any form\n";
  disclaimerText += spacing + "(written or electronic) to anyone who has no\n";
  disclaimerText += spacing + "connection to this matter.  This is an unofficial\n";
  disclaimerText += spacing + "transcription which should NOT be relied upon for\n";
  disclaimerText += spacing + "purposes of verbatim citation of testimony.\n";
  disclaimerText += spacing + "   Corrections will be made in the preparation of\n";
  disclaimerText += spacing + "the certified transcription, resulting in\n";
  disclaimerText += spacing + "differences in content, page and line numbers,\n";
  disclaimerText += spacing + "punctuation, and formatting.\n";
  disclaimerText += "\n\n\n";
  return disclaimerText;
};

/**
 * Get header text for transcripts.
 *
 * @returns
 */
const getHeaderText = (hasPageLines, isVoiceToTextEnabled, hasBookmarks) => {
  // Add the header.
  var headerText = "\n";

  //// Display line number.
  // transcriptsStr += hasPageLines ? "Page" + SPACING : "";
  headerText += hasPageLines ? "Line" + SPACING : "";

  //// Display speaker
  if (isVoiceToTextEnabled) headerText += "Speakers" + SPACING + SPACING + SPACING + SPACING;

  //// Display text
  headerText += "Text" + SPACING;

  //// Display time
  if (isVoiceToTextEnabled) headerText += "Time" + SPACING + SPACING;

  //// Display bookmark
  headerText += hasBookmarks ? "Bookmark" + SPACING : "";

  return headerText;
};

/**
 * Get speaker name.
 *
 * @param {*} speakerIndex
 * @param {*} speakerMapping
 * @returns
 */
const getSpeakerName = (speakerIndex, speakerMapping) => {
  let speakerName = speakerMapping.find((m) => m.speakerIndex == speakerIndex)?.speakerName;

  if (speakerName === undefined || speakerName.length === 0)
    speakerName = `Speaker ${speakerIndex}`;
  return speakerName;
};

const formatLineNumber = (lineNumber) => {
  const LINE_WIDTH = 2;
  var formatted = "";
  for (var i = 0; i < LINE_WIDTH - lineNumber.toString().length; i++) {
    formatted += " ";
  }
  formatted += lineNumber;

  return formatted;
};

/**
 *
 * @param {*} note
 * @returns
 */
const spaceAddedNotes = (note) => {
  if (note.includes("\n")) {
    const notes = note.split("\n");
    return notes
      .map((note, i) => {
        if (i < 1) {
          return `${note}`;
        } else {
          return `                                                                                                                                                  ${note}`;
        }
      })
      .join("\n");
  } else {
    return `${note}`;
  }
};

/**
 * Get bookmark text.
 *
 * @param {*} bookmarkHash
 * @param {*} tr
 * @returns
 */
const getBookmarkText = (bookmarkHash, tr) => {
  const bVO = bookmarkHash.find((bookmark) => bookmark.id === tr.id);
  if (bVO === undefined) return "";

  if (isEmpty(bVO.notes)) return "[B]";

  if (!isEmpty(bVO.notes)) return `     [N]${spaceAddedNotes(bVO.notes)}`;
};

/** FORMAT>>
 *  HEAD
 *  CASE_INFO
 *  transcriptInfo
 *  ACTIVE_ISSUES
 *  DELETED_ISSUES
 *  ANNOTATIONS
 *  TEXT
 *  <<FORMAT
 *  **/
/*********************************************
 **********     FIELDS          *************
 ********************************************/
const HEAD = "begin=Head\r\ntype=ptf\r\nversion=1.3\r\nend=Head\r\n";
const CASE_INFO = "begin=CaseInfo\r\npath=\r\nname=\r\nend=CaseInfo\r\n";
const DELETED_ISSUES = "begin=DeletedIssues\r\nend=DeletedIssues\r\n";
let issuesAndNotes = "";

export function getPTFExport(
  hasPageLines,
  hasBookmarks,
  lines,
  bookmarkHash,
  speakerMapping,
  headerMetaData,
  isVoiceToTextEnabled = true
) {
  // console.log("hasPageLines", hasPageLines);
  // console.log("hasBookmarks", hasBookmarks);
  // console.log("lines", lines);
  // console.log("bookmarkHash", bookmarkHash);
  let pageNumbers = [];
  let lineNumbers = [];
  let speakerNames = [];
  let bookmark = "";
  let timeStamps = [];
  let stream = "\r\n";
  let lastPage;
  let quick = "";
  let i;
  let strIssues = "\r\n";
  let issueTypes = [];
  let issueTypesSet = new Set();
  issuesAndNotes = "";

  if (hasBookmarks) {
    for (let key in bookmarkHash) {
      // console.log("bVO XXX", bookmarkHash[key]);
      let bVO = bookmarkHash[key];
      let index = 0;
      if (bVO.label && !issueTypesSet.has(bVO.label)) {
        // console.log("XXX issueType", bVO.label, bVO);
        if (bVO.label === "No Color") continue;
        issueTypes.push(bVO.label);
        issueTypesSet.add(bVO.label);
      }
    }
    issueTypes.push("No Color"); // This should be the last issueType

    issueTypes.forEach((issueType, index) => {
      strIssues += `${index}=${issueType}\r\n`;
    });
  }

  // Go through the lines and find all:
  // page numbers, line numbers, timestamps
  // and the stream, quicks, notes, issues
  let firstBVO;
  let firstBPos;
  for (i = 0; i < lines.length; i++) {
    let lVO = lines[i];
    if (hasPageLines) {
      if (lVO.page != lastPage) {
        lastPage = lVO.page;
        pageNumbers.push(lVO.page);
      }
      lineNumbers.push(lVO.lineNumber);
      if (hasBookmarks) {
        let bVO = bookmarkHash[getIndex(lVO)];
        if (bookmarkHash[i]?.notes) {
          bookmark += `${i}=${bookmarkHash[i]?.notes ?? ""}\r\n`;
        }
        if (bVO && !bVO.notes && bVO.color === 0) {
          quick += `quick=${i} i=0 d= g=${v4().toUpperCase()} u=\r\n`;
          if (firstBVO) {
            rememberNote(
              firstBPos,
              i,
              bookmarkHash[getIndex(lines[i - 1])],
              issueTypes,
              headerMetaData
            );
            firstBVO = null;
          }
        } else if (bVO) {
          if (!firstBVO) {
            firstBVO = bVO;
            firstBPos = i;
          } else if (firstBVO.id != bVO.id) {
            rememberNote(
              firstBPos,
              i,
              bookmarkHash[getIndex(lines[i - 1])],
              issueTypes,
              headerMetaData
            );
            firstBVO = bVO;
            firstBPos = i;
          }
        } else if (firstBVO) {
          rememberNote(
            firstBPos,
            i,
            bookmarkHash[getIndex(lines[i - 1])],
            issueTypes,
            headerMetaData
          );
          firstBVO = null;
        }
      }
    }

    speakerNames.push(getSpeakerName(lVO.speaker, speakerMapping));

    timeStamps.push(lVO.timeStamp);
    stream += `${i}=${lVO.text}\r\n`;
  }

  const getHeaderMetaData = () => {
    let headerMetadata = "begin=CaseDetails";
    headerMetadata += "\r\ncaseName=";
    headerMetadata += headerMetaData.caseName;
    headerMetadata += "\r\nwitnessName=";
    headerMetadata += headerMetaData.witnessName;
    headerMetadata += "\r\ndateCreated=";
    headerMetadata += `${headerMetaData.dateCreated}\r\n`;
    return headerMetadata;
  };

  return (
    HEAD +
    // `\n${headerMetaData.caseName}` +
    CASE_INFO +
    getHeaderMetaData() +
    transcriptInfo(
      speakerNames.toString(),
      pageNumbers.toString(),
      lineNumbers.toString(),
      timeStamps.toString(),
      isVoiceToTextEnabled
    ) +
    getActiveIssues(strIssues) +
    DELETED_ISSUES +
    getAnnotations(quick, bookmark) +
    getText(stream)
  );
}

function getIndex(lineVO) {
  return lineVO.page + "_" + lineVO.lineNumber;
}

function rememberNote(firstBPos, lastBPos, lastBVO, issueTypes) {
  let pos = firstBPos + " 0 " + (lastBPos - 1) + " " + lastBVO.lineVO.text.length + "\r\n";

  // that's pretty lame, but there is no other way for now to check is it a note or an issue with note
  let str =
    "begin=anno\r\nguid=" +
    v4().toUpperCase() +
    "\r\nuser=\r\ndatetime=\r\npos=" +
    pos +
    "issues=" +
    (lastBVO.color === 0 ? "" : "") +
    issueTypes.indexOf(lastBVO.label) +
    "\r\n";

  if (lastBVO.notes) {
    str += "begin=note\r\n";
    let arrNotes = lastBVO.notes.split("\r");
    for (let i = 0; i < arrNotes.length; i++) {
      str += i + "=" + arrNotes[i] + "\r\n";
    }
    str += "end=note\r\n";
  }
  str += "video=-1 -1 -1 -1\r\nend=anno\r\n";
  issuesAndNotes += str;
}

function transcriptInfo(speakerNames, pages, lines, timeStamps, isVoiceToTextEnabled) {
  let tInfo = "begin=TranscriptInfo\r\ndatetime=\r\nname=\r\nguid=";
  tInfo += v4().toUpperCase();
  tInfo += "\r\nfp=";
  tInfo += v4().toUpperCase();

  // Add speaker info if voice-to-text is enabled.
  if (isVoiceToTextEnabled) {
    tInfo += "\r\nspeaker=";
    tInfo += speakerNames;
  }

  tInfo += "\r\ndepolines=0\r\nrestartid=\r\npagenames=";
  tInfo += pages;
  tInfo += "\r\nlinenames=";
  tInfo += lines;
  tInfo += "\r\nlinetimestamps=";
  tInfo += timeStamps;
  tInfo += "\r\nfirstpage=1\r\npagelen=25\r\nend=TranscriptInfo\r\n";

  return tInfo;
}

function getActiveIssues(issues) {
  console.log("getActiveIssues", issues);
  return `begin=ActiveIssues${issues}end=ActiveIssues\r\n`;
}

function getAnnotations(quick, issuesAndNotes) {
  console.log("issuesAndNotes", issuesAndNotes);
  let annotation = "begin=Annotations\r\n";
  // if (quick) {
  //   annotation += quick;
  // }
  if (issuesAndNotes) {
    annotation += issuesAndNotes;
  }
  return `${annotation}end=Annotations\r\n`;
}

function getText(text) {
  return `begin=Text${text}\r\nend=Text\r\n`;
}
