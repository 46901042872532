/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import { TranscriptContext } from "../../../services/TranscriptService";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
  },
}));

const StopTranscriptConfirmation = ({ open, handleStopTranscript, handleClose, onSuccess }) => {
  const classes = useStyles();
  const { data: model } = React.useContext(TranscriptContext);
  const [isStopping, setIsStopping] = React.useState(false);

  const handleStop = async (e) => {
    try {
      setIsStopping(true);
      await handleStopTranscript();
    } finally {
      setIsStopping(false);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        className={classes.root}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableEnforceFocus
      >
        <CRXDialogTitle onClick={handleClose} title="Stop Transcript" />

        <DialogContent>
          <Box m={1}>
            Please confirm that you wish to stop the Transcription Service. The StreamText Event
            <br />
            will be marked complete and you will not be able to restart the Event.
            <br />
            <br />
            Are you sure that you want to stop the current transcript?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleStop}
            loading={isStopping}
          >
            Stop Transcript
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StopTranscriptConfirmation;
