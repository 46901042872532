/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";
import CRXDialogTitle from "../../../common/dialog/CRXDialogTitle";
import { toHex } from "../transcriptUtilities";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(1),
  },
  textField: {
    width: "25ch",
  },
}));

const HighlightColorEditor = ({ colors, open, onClose, onChangeLabel }) => {
  const [currentColors, setCurrentColors] = React.useState();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setCurrentColors(colors);
    setError(null);
  }, []);

  const handleDone = () => {
    onChangeLabel(currentColors);
    onClose();
  };

  const handleChangeLabel = (index, label) => {
    currentColors[index].label = label;
    setCurrentColors(currentColors);
  };

  const handleError = (error) => {
    setError(error);
  };

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg" disableEnforceFocus>
        <CRXDialogTitle onClick={onClose} title="Highlight Color Labeling" />

        <DialogContent>
          {error && <div style={{ color: "#ff0000" }}>{error}</div>}
          <Box
            display="flex"
            component="span"
            style={{ height: 420, width: 525, margin: 10 }}
            justifyContent="center"
          >
            <Box style={{ height: 370, width: 250, margin: 5 }}>
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <HighlightColorInput
                  colors={currentColors}
                  key={index}
                  index={index}
                  onError={handleError}
                  onChangeLabel={handleChangeLabel}
                />
              ))}
            </Box>
            <Box style={{ height: 370, width: 250, margin: 5 }}>
              {[6, 7, 8, 9, 10].map((index) => (
                <HighlightColorInput
                  colors={currentColors}
                  key={index}
                  index={index}
                  onError={handleError}
                  onChangeLabel={handleChangeLabel}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDone()}
            disabled={!isNil(error)}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MAX_LABEL_LENGTH = 24;

/**
 * HighlightColorInput field lets the user edit the color and label
 * for the bookmark highlight.
 *
 * @param {*} param0
 * @returns
 */
const HighlightColorInput = ({ colors, key, index, onChangeLabel, onError }) => {
  const classes = useStyles();
  const [label, setLabel] = React.useState("");
  const [highlightColor, setHighlightColor] = React.useState("");
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setLabel(colors[index].label);
    setHighlightColor(colors[index].color);
  }, []);

  const handleChangeLabel = (index, value) => {
    setLabel(value);
    if (value?.length > MAX_LABEL_LENGTH) {
      setError(true);
      onError(`Label name cannot exceed ${MAX_LABEL_LENGTH} characters.`);
    } else if (value?.length <= 0) {
      setError(true);
      onError(`Label name cannot be empty.`);
    } else {
      setError(false);
      onChangeLabel(index, value);
      onError(null);
    }
  };

  const handleCheckClick = () => {
    handleChangeLabel(index, label);
  };

  return (
    <>
      <FormControl
        key={key}
        style={{ backgroundColor: `${highlightColor}` }}
        className={clsx(classes.margin, classes.textField)}
      >
        <TextField
          type="text"
          value={label}
          onChange={(e) => {
            handleChangeLabel(index, e.currentTarget.value);
          }}
          style={{ backgroundColor: `${toHex(highlightColor)}` }}
          error={error}
          // endAdornment={
          //   <InputAdornment position="end">
          //     <IconButton onClick={handleCheckClick}>
          //         <CheckCircleIcon/>
          //     </IconButton>
          //   </InputAdornment>
          // }
        />
      </FormControl>
    </>
  );
};

export default HighlightColorEditor;
