/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState } from "react";
import { Typography, Tooltip } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { TranscriptContext } from "../../services/TranscriptService";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import EditBookmark from "./bookmarks/EditBookmark";
import { toHex } from "./transcriptUtilities";

const TranscriptActionButton = ({
  row,
  defaultBackgroundColor,
  onSaveBookmark,
  onDeleteBookmark,
}) => {
  const { data } = React.useContext(TranscriptContext);
  const [editBookmarkOpen, setEditBookmarkOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [bookmark, setBookmark] = useState(null);

  React.useEffect(() => {
    const bookmark = data.transcriptBookmarks[row.id];
    if (!isNil(bookmark)) {
      setBookmark(bookmark);
      setBackgroundColor(toHex(bookmark.color));
      row.bookmark = bookmark;
    }
  }, [data.transcriptBookmarks]);

  const handleDeleteBookmark = (row) => {
    if (bookmark) {
      setBookmark({});
      row.bookmark = {};
    }
    setBackgroundColor(defaultBackgroundColor);
    onDeleteBookmark(row);
  };

  const saveBookMark = (transcript) => {
    if (transcript.bookmark) {
      setBackgroundColor(toHex(transcript.bookmark.color));
      setBookmark(transcript.bookmark);
    }
    onSaveBookmark(transcript);
    setEditBookmarkOpen(null);
  };

  return (
    <>
      <div id={row.id}>
        <Typography noWrap>
          {!isEmpty(bookmark) && bookmark.notes !== undefined && bookmark.notes !== "" && (
            <Tooltip title={bookmark.notes}>
              <DescriptionIcon
                color="primary"
                fontSize="small"
                onClick={() => {
                  setEditBookmarkOpen(row.id);
                }}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}

          {!isEmpty(bookmark) && (bookmark.notes === undefined || bookmark.notes === "") && (
            <Tooltip title="No comments.">
              <BookmarkIcon
                color="primary"
                fontSize="small"
                onClick={() => setEditBookmarkOpen(row.id)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}

          {isEmpty(bookmark) && (
            <Tooltip title="Add comments." arrow>
              <AddIcon
                color="primary"
                fontSize="small"
                onClick={() => setEditBookmarkOpen(row.id)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}

          {!isEmpty(bookmark) && (
            <Tooltip title="Delete comments." arrow>
              <DeleteIcon
                color="primary"
                fontSize="small"
                onClick={() => handleDeleteBookmark(row)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
        </Typography>
      </div>
      {/* Dialogs */}
      <EditBookmark
        transcript={row}
        open={editBookmarkOpen}
        onClose={(e) => setEditBookmarkOpen(null)}
        onSaveBookmark={saveBookMark}
      />
    </>
  );
};

export default TranscriptActionButton;
