/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import {
  Button,
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  Snackbar,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import Joi from "joi-browser";
import { isEmpty } from "lodash";
import { AuthContext } from "../../services/AuthService";
import Copyright from "./Copyright";
import {
  validate,
  validateProperty,
  setErrorMessages,
} from "../common/validateUtilities";
import { getParameterByName } from "../common/viewUtilities";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url("/${process.env.REACT_APP_BRAND_ID}/images/login_splash.png") `,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    color: "#71ee33",
  },
}));

export function ResetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { getByActivationCode, resetPasswordByActivationCode } =
    React.useContext(AuthContext);
  const [activationCode, setActivationCode] = React.useState(null);
  const [activationCodeError, setActivationCodeError] = React.useState(false);
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [errors, setErrors] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    history.push("/login");
  };

  React.useEffect(() => {
    // let code = location.state.activationCode;
    let code = getParameterByName("activationCode");
    setActivationCode(code);

    async function fetchData() {
      try {
        await getByActivationCode(code);
      } catch (e) {
        setActivationCodeError(true);
      }
    }
    fetchData();
  }, []);

  // React.useEffect(() => {
  //   if (data.myContact !== undefined) setMyContact(data.myContact);
  // }, [data.myContact]);

  const schema = {
    password: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(56)
      .label("Password"),
    passwordVerify: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(56)
      .label("Verify Password"),
  };

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const password = e.target.password.value;
      const passwordVerify = e.target.passwordVerify.value;

      let errs = validate({ password, passwordVerify }, schema);

      if (errs === null && password !== passwordVerify) {
        if (errs === null) errs = {};

        errs["passwordVerify"] =
          "Password and Verify Password are not the same";
        setErrors(errs);
        return;
      }

      await resetPasswordByActivationCode(password, activationCode);
      setOpenSnackbar(true);
    } catch (ex) {
      console.log(ex);
      if (ex.response) {
        if (ex.response.data.errors != null) {
          setErrorMessages(
            ex.response.data.errors.fields,
            ex.response.data.errors.defaultMessage,
            setErrors
          );
        } else if (ex.response.data.message) {
          setErrorMessages("password", ex.response.data.message, setErrors);
        }
      } else if (ex.message === "Network Error") {
        setErrorMessages(
          "password",
          "There was an issue connecting to the internet.  Please check your connection.",
          setErrors
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Box mt={10} mb={10}>
              <img
                src={`${process.env.REACT_APP_BRAND_ID}/images/logo.png`}
                alt="Logo"
              />
            </Box>
            <Box>
              To change your password, please use a strong password. Your new
              password must following the following rules:
              <ul>
                <li>Must not be the same as current password</li>
                <li>Between 8 and 16 characters long</li>
                <li>Contains one or more capital letters</li>
                <li>Contains a digit</li>
                <li>Contains a special character: ~!@#$%&*()-</li>
              </ul>
            </Box>
            {!activationCodeError && (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  error={
                    errors &&
                    (!isEmpty(errors["password"]) ||
                      !isEmpty(errors["passwordError"]))
                  }
                  helperText={
                    errors && (errors["password"] || errors["passwordError"])
                  }
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.currentTarget.value);
                    validateProperty(schema, e.currentTarget, setErrors);
                  }}
                />
                <TextField
                  error={errors && !isEmpty(errors["passwordVerify"])}
                  helperText={errors && errors["passwordVerify"]}
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="passwordVerify"
                  label="Verify Password"
                  type="password"
                  id="passwordVerify"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.currentTarget.value);
                    validateProperty(schema, e.currentTarget, setErrors);
                  }}
                />
                <Box display="flex" flexDirection="row" width={1} my={4}>
                  <Box display="flex" justifyContent="center" width={1}>
                    {loading && (
                      <CircularProgress
                        size={60}
                        className={classes.progress}
                      />
                    )}
                  </Box>
                  <Box width={300}></Box>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  fullWidth
                  disabled={loading || !isEmpty(errors)}
                >
                  RESET PASSWORD
                </Button>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            )}
            {activationCodeError && (
              <Box>
                This activation code has already been used. Please use the
                Forgot Password link to request a new activation code.
              </Box>
            )}
          </div>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        message="Your password has been updated.   You will be redirected back to the Login Screen."
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Your password has been updated. You will be redirected back to the
          Login Screen.
        </Alert>
      </Snackbar>
    </>
  );
}
